// chart.constants.js

const timePeriodTypes = {
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  CUSTOM: 'custom',
};

const timePeriods = {
  [timePeriodTypes.HOUR]: {
    name: 'Last 60 minutes',
    timeFormat: 'HH:mm',
    time: 1000 * 60 * 60, // 1 hour
    ticks: {
      interval: 60 * 10, // 10 minutes
      count: 6,
      lastTickStartOf: 'minute',
    },
    resolutions: {
      low: 60 * 3, // 3 minutes => 20 points
      high: 60, // = 60 points
    },
  },
  [timePeriodTypes.DAY]: {
    name: 'Last 24 hours',
    timeFormat: 'HH:mm',
    time: 1000 * 60 * 60 * 24, // 1 day
    ticks: {
      interval: 60 * 60 * 2, // 2 hours
      count: 12,
      lastTickStartOf: 'hour',
    },
    resolutions: {
      low: 60 * 60, // 1 hour => 24 points
      high: 60 * 15, // 15 minutes => 96 points
    },
  },
  [timePeriodTypes.WEEK]: {
    name: 'Last 7 days',
    timeFormat: 'D/MM',
    time: 1000 * 60 * 60 * 24 * 7, // 7 days
    ticks: {
      interval: 60 * 60 * 24, // 1 day
      count: 7,
      lastTickStartOf: 'day',
    },
    resolutions: {
      low: 60 * 60 * 6, // 6 hours => 28 points
      high: 60 * 60 * 2, // 2 hours => 84 points
    },
  },
  [timePeriodTypes.MONTH]: {
    name: 'Last 30 days',
    timeFormat: 'D/MM',
    time: 1000 * 60 * 60 * 24 * 30, // 30 days
    ticks: {
      interval: 60 * 60 * 24 * 5, // 5 days
      count: 6,
      lastTickStartOf: 'day',
    },
    resolutions: {
      low: 60 * 60 * 24, // 1 day => 30 points
      high: 60 * 60 * 8, // 8 hours => 90 points
    },
  },
  [timePeriodTypes.CUSTOM]: {
    name: 'Custom time period',
    timeFormat: 'YYYY-MM-DD HH:mm',
    resolutions: {
      low: 30, // 30 points
      high: 100, // 100 points
    },
  }
};

export {
  timePeriodTypes,
  timePeriods,
};
