// image-manager-start.view.js

// Modules
import React from 'react';

// Local components
import {
  StyledEntryBox,
  StyledImageManagerStart,
  StyledStartWrapper,
} from './image-manager.styled';
import {routes} from '../views.routes';

const ImageManagerStart = () => (
  <StyledImageManagerStart>
    <h1>IMAGES</h1>
    <StyledStartWrapper>
      <StyledEntryBox
        to={routes.IMAGE_MANAGER_UPLOAD}>
        UPLOAD
      </StyledEntryBox>
      <StyledEntryBox
        to={routes.IMAGE_MANAGER_EDIT}>
        MANAGE
      </StyledEntryBox>
    </StyledStartWrapper>
  </StyledImageManagerStart>
);

export default ImageManagerStart;
