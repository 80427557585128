// blueprint-create.selectors.js

import _ from 'lodash';
import {createSelector} from 'reselect';
import {map, last, first, split, pipe} from 'lodash/fp';

import {allowedImageNameSpaces} from '../../../constants';
import {
  selectCurrentLocationBlueprintCreator,
  selectAllCurrentLocationBlueprints,
} from '../location.selectors';

const selectAllBlueprintImages = state => state.images[allowedImageNameSpaces.BLUEPRINT];

const createBlueprintImagesSelector = createSelector(
  selectAllBlueprintImages,
  blueprintImages => (
    map(icon => {
      const label = pipe(
        split('/'),
        last,
        split('.'),
        first,
      )(icon);
      return {label, value: icon};
    }, blueprintImages)
  )
);

const selectCreateBlueprintDuplicateNameError = createSelector(
  selectCurrentLocationBlueprintCreator,
  selectAllCurrentLocationBlueprints,
  (blueprintCreator, blueprints) => {
    if (!blueprintCreator.name) {
      return false;
    }
    return !!_.find(blueprints, blueprint => blueprint.name === blueprintCreator.name);
  }
);

export {
  createBlueprintImagesSelector,
  selectCreateBlueprintDuplicateNameError,
}
