// views.routes.js

// a utility for internal routing

const routes = {
  CREATE: '/create',
  IMAGE_MANAGER: '/image-manager',
  IMAGE_MANAGER_UPLOAD: '/image-manager/upload',
  IMAGE_MANAGER_EDIT: '/image-manager/edit',
  LOCATIONS: '/locations',
  LOCATION: '/locations/:locationName',
  BLUEPRINT: '/locations/:locationName/:blueprintName',
  CHART: '/charts/:deviceId',
  LOGIN: '/login',
};

const createLocationUrl = locationName => (
  `${routes.LOCATIONS}/${locationName}`
);

const createBlueprintUrl = (locationName, blueprintName) => (
  `${routes.LOCATIONS}/${locationName}/${blueprintName || ''}`
);

export {
  routes,
  createLocationUrl,
  createBlueprintUrl,
};
