// subscriptions.redux.js

// subscriptions keeps a local state of all devices
// that are being subscribed to actively
// this is kind of sketchy..

import _ from 'lodash';
import assert from 'assert';
import {socket} from '../api';
import {subscriptionStates} from '../constants';

const actionTypes = {
  DB_SUBSCRIPTIONS_REMOVALS: 'DB_SUBSCRIPTIONS_REMOVALS',
  DB_SUBSCRIPTIONS_ADDITIONS: 'DB_SUBSCRIPTIONS_ADDITIONS',
  DB_SUBSCRIPTIONS_UPDATES: 'DB_SUBSCRIPTIONS_UPDATES',
};

const actions = {
  removeSubscriptions: deviceIds => ({
    type: actionTypes.DB_SUBSCRIPTIONS_REMOVALS,
    payload: {deviceIds},
  }),
  addSubscriptions: deviceIds => dispatch => {
    dispatch({
      type: actionTypes.DB_SUBSCRIPTIONS_ADDITIONS,
      payload: {deviceIds},
    });
    socket.subscribe(deviceIds);
  },
  // updateSubscription: (deviceIds, subState) => ({
  //   type: actionTypes.DB_SUBSCRIPTIONS_UPDATES,
  //   payload: {deviceIdsS, subState},
  // }),
  updateSubscription: (deviceIds, subState) => dispatch => (
    dispatch({
      type: actionTypes.DB_SUBSCRIPTIONS_UPDATES,
      payload: {deviceIds, subState},
    })
  ),
};

const defaultState = {};

const reduce = (state = defaultState, action) => {
  switch (action.type) {

    case actionTypes.DB_SUBSCRIPTIONS_REMOVALS: {
      const {deviceIds} = action.payload;
      return _.omit(state, deviceIds);
    }

    case actionTypes.DB_SUBSCRIPTIONS_ADDITIONS: {
      const {deviceIds} = action.payload;
      const subs = _.reduce(deviceIds, (acc, deviceId) => {
        acc[deviceId] = subscriptionStates.SUBSCRIBING;
        return acc;
      }, {});
      return {...state, ...subs};
    }

    case actionTypes.DB_SUBSCRIPTIONS_UPDATES: {
      const {deviceIds, subState} = action.payload;
      assert(_.includes(subscriptionStates, subState), `Invalid subscription state: ${subState}`);
      const targetSubs = _.pick(state, deviceIds);
      const replacementSubs = _.mapValues(targetSubs, () => subState);
      return {...state, ...replacementSubs};
    }

    default: {
      return state;
    }

  }
};

export {
  actions,
  reduce,
};
