// socketio.js

import openSocket from 'socket.io-client';
import assert from 'assert';
import {getToken, getYggioUrl} from './api-utility';

import {
  subscriptionStates, SOCKET_MESSAGE, socketCommandTypes, socketMessageTypes,
} from '../constants';

const state = {
  onDeviceUpdate: null,
  onSubscriptionUpdate: null,
  connected: false,
};

const setDeviceListener = onDeviceUpdate => {
  state.onDeviceUpdate = onDeviceUpdate;
};

const setSubscriptionListener = onSubscriptionUpdate => {
  state.onSubscriptionUpdate = onSubscriptionUpdate;
};

//
// *** Connect the socket & attach listeners ***
//
const socketUrl = getYggioUrl();
const socketOptions = {query: `token=${getToken()}`};
const socket = openSocket(socketUrl, socketOptions);

socket.on('connect', () => {
  state.connected = true;
});

socket.on('disconnect', () => {
  state.connected = false;
});

socket.on('error', message => {
  /* eslint-disable no-console */
  console.error('socketio error: ', message);
  /* eslint-enable no-console */
});

socket.on(socketMessageTypes.DEVICE, device => {
  assert(state.onDeviceUpdate, 'onDeviceUpdate should have been set');
  state.onDeviceUpdate(device);
});

socket.on(socketMessageTypes.SUBSCRIBED, deviceId => {
  assert(state.onSubscriptionUpdate, 'onSubscriptionUpdate should have been set');
  state.onSubscriptionUpdate(deviceId, subscriptionStates.SUBSCRIBED);
});

socket.on(socketMessageTypes.UNAUTHORIZED, deviceId => {
  assert(state.onSubscriptionUpdate, 'onSubscriptionUpdate should have been set');
  state.onSubscriptionUpdate(deviceId, subscriptionStates.UNAUTHORIZED);
});

const subscribe = deviceIds => {
  if (!state.connected) {
    return;
  }
  socket.emit(
    SOCKET_MESSAGE,
    socketCommandTypes.SUBSCRIBE,
    deviceIds
  );
};

export {
  setDeviceListener,
  setSubscriptionListener,
  subscribe,
};
