// locations.redux.js

import _ from 'lodash';
import aggregate from 'promise-aggregate';

import {locations as locationsApi} from '../api';

const actionTypes = {
  DB_LOCATIONS_REPLACE_ALL: 'DB_LOCATIONS_REPLACE_ALL',
  DB_LOCATIONS_REPLACE_ONE: 'DB_LOCATIONS_REPLACE_ONE',
  DB_LOCATIONS_REMOVE_ONE: 'DB_LOCATIONS_REMOVE_ONE',
};

const saveLocationEdits = aggregate(updates => (
  locationsApi.update(updates)
    .catch(() => 0) // just swallow all errors (?)
), {
  minInterval: 200, // all times in ms
  aggInterval: 50,
  maxWait: 2000,
});

const actions = {
  fetchLocations: () => dispatch => (
    locationsApi.fetch()
      .then(locations => dispatch({
        type: actionTypes.DB_LOCATIONS_REPLACE_ALL,
        payload: {locations},
      }))
  ),
  createLocation: locationData => dispatch => (
    locationsApi.create(locationData)
      .then(location => dispatch({
        type: actionTypes.DB_LOCATIONS_REPLACE_ONE,
        payload: {location},
      }))
  ),
  updateLocation: updates => dispatch => (
    // runs aggregated promise !!!!
    saveLocationEdits(updates)
      .then(location => {
        // not every update is run (replacement rules can apply if desired)
        if (location) {
          dispatch({
            type: actionTypes.DB_LOCATIONS_REPLACE_ONE,
            payload: {location},
          });
        }
      })
  ),
  deleteLocation: locationId => dispatch => (
    locationsApi.remove(locationId)
      .then(() => dispatch({
        type: actionTypes.DB_LOCATIONS_REMOVE_ONE,
        payload: {locationId},
      }))
  ),
};

const defaultState = {};

const reduce = (state = defaultState, action) => {
  switch (action.type) {

    case actionTypes.DB_LOCATIONS_REPLACE_ALL: {
      const {locations} = action.payload;
      return _.reduce(locations, (acc, location) => {
        acc[location._id] = location;
        return acc;
      }, {});
    }

    case actionTypes.DB_LOCATIONS_REPLACE_ONE: {
      const {location} = action.payload;
      return {...state, [location._id]: location};
    }

    case actionTypes.DB_LOCATIONS_REMOVE_ONE: {
      const {locationId} = action.payload;
      return _.omit(state, locationId);
    }

    default: {
      return state;
    }
  }
};

export {
  actionTypes,
  actions,
  reduce,
};
