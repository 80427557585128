// request.js

import axios from 'axios';
import _ from 'lodash';
import {toast} from 'react-toastify';
import {getToken, getYggioUrl} from './api-utility';
import {resourceTypes} from '../constants';

const errorHandler = err => {
  const message = _.get(err, 'message');
  const data = _.get(err, 'response.data');
  if (message && data) {
    toast.error(`${err.message}: ${err.response.data}`);
  } else if (message) {
    toast.error(`${err.message}`);
  } else {
    toast.error(err || 'no message specified');
  }
  return Promise.reject(err);
};



const request = async ({method, url, data, params}) => {
  const API_PREFIX = url === resourceTypes.AUTH_LOCAL ? '' : '/api';
  return (
    axios({
      timeout: 6000,
      method,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
      url: `${getYggioUrl() + API_PREFIX + url}`,
      data,
      params,
    })
      .then(res => res.data)
      .catch(errorHandler)
  );
}

export default request;
