// image-manager-list.view.js

// Modules
import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';

// Local components
import {
  StyledImage,
  StyledImageListItems,
  StyledImageListSection,
  StyledSlider,
  SliderPrevArrow,
  SliderNextArrow,
} from './image-manager.styled';
import {extractNameFromImageUrl} from './image-manager.utils';
import {getFullImageUrl} from '../views.utils';

// Actions
import {actions as viewActions} from './image-manager.redux';

const settings = {
  infinite: false,
  speed: 800,
  slidesToShow: 7,
  slidesToScroll: 1,
  nextArrow: <SliderNextArrow/>,
  prevArrow: <SliderPrevArrow/>,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        swipeToSlide: true
      }
    },
  ]
};

const ImageListSection = props => (
  <StyledImageListSection>
    {!_.isEmpty(props.images[props.imageType]) && <h6>Click on image to view/edit</h6>}
    <StyledImageListItems>
      {_.isEmpty(props.images[props.imageType])
        ? <span>No images available</span>
        : (
          <RenderImages
            images={props.images}
            imageType={props.imageType}
            editImageName={props.editImageName}
            selectImageToManage={props.selectImageToManage}
          />
        )
      }
    </StyledImageListItems>
  </StyledImageListSection>
);

const RenderImages = ({images, imageType, editImageName, selectImageToManage}) => (
  <StyledSlider {...settings}>
    {_.map(images[imageType], image => (
      <StyledImage
        onClick={() => {
          const imageName = extractNameFromImageUrl(image);
          editImageName(imageName);
          selectImageToManage(image);
        }}
        key={image}
        src={getFullImageUrl(image)}
        alt={image}
      />
    ))}
  </StyledSlider>
);

const mapStateToProps = state => ({
  images: state.images,
  imageType: state.views.imageManager.imageType,
});

const mapDispatchToProps = dispatch => ({
  selectImageToManage: image => dispatch(viewActions.selectImageToManage(image)),
  editImageName: imageName => dispatch(viewActions.editImageName(imageName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageListSection);
