// create.view.js

// Modules
import React from 'react';

// Local components
import LocationPicker from './create-map.view';
import {ContentWrapper} from '../views.styled';
import {
  MapRow,
} from './create.styled';
import CreationMenu from './create-menu.view';

const Create = () => (
  <>
    <CreationMenu/>
    <ContentWrapper>
      <MapRow>
        <LocationPicker/>
      </MapRow>
    </ContentWrapper>
  </>
);

export default Create;
