import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import ConfirmationDialog from './confirmation-dialog.view';
import {actions as viewActions} from './views.redux';
import {actions as locationMenuActions} from './location.view/location-menu.view/location-menu.redux';
import {actions as locationActions} from './location.view/location.redux';
import {selectBlueprintWithDialog} from './views.selectors';
import {selectCurrentLocation} from './location.view/location.selectors';

const BlueprintDialog = props => (
  <ConfirmationDialog
    isOpen={props.isOpen}
    onCancel={props.closeDialog}
    onConfirm={() => {
      props.deleteBlueprint(props.currentLocation, props.blueprintId);
      props.setView(null);
      props.closeDialog();
    }}
    header={'Delete blueprint'}
    message={'Are you sure you want to delete this blueprint?'}
  />
);

const mapStateToProps = state => ({
  isOpen: !!selectBlueprintWithDialog(state),
  blueprintId: selectBlueprintWithDialog(state),
  currentLocation: selectCurrentLocation(state),
});

const mapDispatchToProps = dispatch => ({
  closeDialog: () => dispatch(viewActions.setDialogState(null)),
  deleteBlueprint: (currentLocation, blueprintId) => dispatch(locationMenuActions.deleteBlueprint(currentLocation, blueprintId)),
  setView: bool => dispatch(locationActions.setView(bool)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BlueprintDialog));
