// locations-map.styled.js

// Modules
import React from 'react';
import styled from 'styled-components';
import ReactDOMServer from 'react-dom/server';
import Leaflet from 'leaflet';
import {Map, Tooltip} from 'react-leaflet';

// Local components
import {colors, NAVBAR_HEIGHT} from '../../views.constants';
import {Button} from '../../views.styled';

const StyledMap = styled(Map)`
  filter: ${props => (props.isInPlacementMode ? 'grayscale(50%)' : 'none')};

  & {
    top: ${NAVBAR_HEIGHT};
    height: 100%;
    width: 78%;
    margin-left: 22%;
  }

  @media screen and (max-width: 1024px) {
   & {
      width: 100%;
      margin: 0;
    }
  }

  & .leaflet-popup-content {
    margin: 0;
    padding: 0;
    width: 199px;
  }

  & .leaflet-popup-content-wrapper {
    margin: 0;
    padding: 0;
  }

  & .leaflet-marker-icon {
    border-radius: 5px;
  }
`;

const MapItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
  flex-direction: column;
  height:150px;
  width: 200px;
  margin: 0;
  padding: 0;
`;

const MapItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  background: ${colors.RACING_GREEN};
  color: ${colors.WHITE};
  height: 50%;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const MapItemFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: ${colors.WHITE};
  height: 50%;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const MapButton = styled(Button)`
  color: ${colors.GREY_LIGHT};
  background: ${colors.LIGHT_GREEN};
  height: 30px;
  width: 100%;
  border-radius: 5px;
  transition: background 0.2s;

  &:hover {
    background: ${colors.DARK_GREEN};
    transition: background 0.2s;
  }
`;

const PlacementModeHeader = styled.h1`
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 999;
  position: absolute;
  top: 2%;
  font-weight: 1000;
`;

const MarkerIcon = styled.div`
  background: ${({color}) => color};
  width: 100%;
  height: 100%;
  padding: 4px;
  border-radius: 5px;
  border: 2px solid ${colors.DARK_GREY};
  transition: background 0.2s;
  background-size: 80%;

  img {
    height: 100%;
    width: 100%;
  }
`;

const MarkerClusterIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({color}) => color};
  border-radius: 4px;
  width: 40px;
  height: 40px;
  padding: 4px;
  border: 2px solid ${colors.DARK_GREY};
  transition: background 0.2s;
  background-size: 80%;
`;

const StyledContextMenu = styled.div`
  z-index: 1000;
  position: absolute;
  left: ${({left}) => left + 'px'};
  top: ${({top}) => top + 'px'};
  width: 150px;
  height: 30px;
  background: ${colors.GREY_LIGHT};
  border: 2px solid ${colors.GREY};
  border-radius: 5px;
`;

const StyledContextMenuButton = styled.div`
  font-size: 0.8em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: background 0.2s;
  background: ${colors.GREY_LIGHT};
  border-radius: 5px;

  &:hover {
    transition: background 0.2s;
    background: ${colors.WHITE};
  }
`;

// This seems like the best place for these location icon thingies
const createIcon = (iconUrl, color, iconSize) => {
  const html = ReactDOMServer.renderToString(
    <MarkerIcon color={color}>
      <img
        src={iconUrl}
        alt="Map marker"
      />
    </MarkerIcon>
  );
  const halfSize = iconSize / 2;

  return Leaflet.divIcon({
    color,
    iconSize: [iconSize, iconSize],
    iconAnchor: [halfSize, halfSize],
    shadowAnchor: [4, 2],
    popupAnchor: [0, -(halfSize + 5)],
    html
  });
};
// and the leaflet location icon export
const getLocationIcon = (iconUrl, color = colors.GREEN) => (
  createIcon(iconUrl, color, 35)
);

const StyledTooltip = styled(Tooltip)`
  background: transparent;
  border: none;
  box-shadow: none;

  &::before {
    border: none;
  }
`;


export {
  StyledMap,
  MapItem,
  MapItemHeader,
  MapItemFooter,
  MapButton,
  getLocationIcon,
  StyledContextMenu,
  PlacementModeHeader,
  StyledTooltip,
  StyledContextMenuButton,
  MarkerClusterIcon,
};
