// locations.redux.js

const actionTypes = {
  LOCATIONS_TOGGLE_LIST: 'LOCATIONS_TOGGLE_LIST',
  LOCATIONS_SET_PLACEMENT_MODE: 'LOCATION_SET_PLACEMENT_MODE',
};

// ////
// Rest of the actions
// ////

const actions = {
  toggleLocationsMenu: () => ({
    type: actionTypes.LOCATIONS_TOGGLE_LIST,
  }),
  setLocationPlacementMode: isInPlacementMode => ({
    type: actionTypes.LOCATIONS_SET_PLACEMENT_MODE,
    payload: {isInPlacementMode},
  }),
};

const defaultState = {
  isLocationsMenuOpen: true,
  isInPlacementMode: false,
};

const reduce = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.LOCATIONS_TOGGLE_LIST: {
      return {...state, isLocationsMenuOpen: !state.isLocationsMenuOpen};
    }
    case actionTypes.LOCATIONS_SET_PLACEMENT_MODE: {
      const {isInPlacementMode} = action.payload;
      return {...state, isInPlacementMode};
    }
    default: {
      return state;
    }
  }
};

export {
  actionTypes,
  actions,
  reduce,
};
