// location-blueprint-sprite.view.js

// modules
import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';

// local components
import {BlueprintSprite} from './location-blueprint.styled';

// actions & selectors
import {actions} from '../../views.redux';
import {
  selectCurrentBlueprint,
  selectCurrentBlueprintImageUrl,
  selectCurrentBlueprintImageSize,
} from '../location.selectors';
import {
  selectCurrentBlueprintGeometry,
} from './location-blueprint.selectors';


const BlueprintSpriteWrapper = props => (
  <BlueprintSprite
    src={props.blueprintImageUrl}
    alt={_.get(props.blueprint, 'name')}
    geometry={props.blueprintGeometry}
    parentDimensions={props.backgroundDimensions}
    imageSize={props.blueprintImageSize}
    width={_.get(props.blueprint, 'width')}
    height={_.get(props.blueprint, 'height')}
    onLoad={(imageSrc, size) => {
      if (!_.isEqual(size, props.blueprintImageSize)) {
        props.reportImageSize(imageSrc, size);
      }
    }}
  />
);


const mapStateToProps = state => ({
  blueprintId: state.views.common.currentBlueprintId,
  backgroundDimensions: state.views.locationBlueprint.backgroundDimensions,
  blueprint: selectCurrentBlueprint(state),
  blueprintImageUrl: selectCurrentBlueprintImageUrl(state),
  blueprintGeometry: selectCurrentBlueprintGeometry(state),
  blueprintImageSize: selectCurrentBlueprintImageSize(state),
});

const mapDispatchToProps = dispatch => ({
  reportImageSize: (imageSrc, size) => {
    dispatch(actions.reportImageSize(imageSrc, size));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BlueprintSpriteWrapper);
