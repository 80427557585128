import {toast} from 'react-toastify';

const config = {
  yggio: {
    url: process.env.REACT_APP_YGGIO_REST_API_URL || 'http://localhost:9000',
    controlPanelUrl: process.env.REACT_APP_YGGIO_CONTROL_PANEL_URL || 'http://localhost:9002'
  },
  toaster: {
    position: toast.POSITION.TOP_RIGHT,
  },
  domain: process.env.REACT_APP_DOMAIN || 'local.yggio',
};


export default config;
