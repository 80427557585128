import request from './request';
import {resourceTypes} from '../constants';

const login = user => request({
  method: 'post',
  url: `${resourceTypes.AUTH_LOCAL}`,
  data: user,
});

export {
  login,
};
