import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from 'recharts';
import {
  ToolTipContent,
  CustomizedLabel,
  ChartContainer,
} from './chart.styled';
import {timePeriods} from './chart.constants';

const getMeasurementUnit = selectedMeasurement => _.get(selectedMeasurement, 'unit', '');
const isReversed = selectedMeasurement => _.get(selectedMeasurement, 'reversed', false);

const Chart = props => (
  <ChartContainer>
    <ResponsiveContainer width="90%" height={700}>
      <AreaChart
        data={props.deviceStats}
        margin={{top: 40, right: 80, left: 20, bottom: 30}}
      >
        <XAxis
          dataKey="unixTimestamp"
          height={80}
          type="number"
          tickFormatter={unixTime => moment.unix(unixTime).format(timePeriods[props.selectedTimePeriod].timeFormat)}
          ticks={props.chartTicks}
          domain={props.chartDomain}
        />
        <YAxis reversed={isReversed(props.selectedMeasurement)} label={<CustomizedLabel text={getMeasurementUnit(props.selectedMeasurement)} />} />
        <CartesianGrid strokeDasharray="2 4" />
        <Tooltip content={<ToolTipContent />} filterNull={false} />
        <defs>
          <linearGradient id="myColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#0088FE" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#0088FE" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          type={props.selectedResolution === 'full' ? "stepBefore" : "monotone"}
          dataKey="value"
          unit={getMeasurementUnit(props.selectedMeasurement)}
          stroke="#0088FE"
          fill="url(#myColor)"
          strokeWidth="2"
          dot={{strokeWidth: 2, r: 3, fill: 'white', fillOpacity: 1}}
          connectNulls
        />
      </AreaChart>
    </ResponsiveContainer>
  </ChartContainer>
);

export default Chart;
