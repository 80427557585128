// create-map.view.js

// Modules
import React from 'react';
import {connect} from 'react-redux';
import Leaflet from 'leaflet';
import {TileLayer, Marker} from 'react-leaflet';

// Local components
import {
  LEAFLET_ATTRIBUTION,
  LEAFLET_URL,
  DEFAULT_LOCATION_ICON,
} from '../views.constants';
import {actions as viewActions} from './create.redux';
import {StyledMap} from './create.styled';

const DEFAULT_POSITION = {lat: 55, lng: 13};
const SIZE = 56;
const markerIcon = Leaflet.icon({
  iconUrl: DEFAULT_LOCATION_ICON,
  className: 'creator-marker',
  iconSize: [SIZE, SIZE],
  iconAnchor: [SIZE, SIZE],
  shadowAnchor: [4, 2],
});

const LocationPicker = props => (
  <StyledMap
    center={DEFAULT_POSITION}
    zoom={3}
    minZoom={3}
    onViewportChanged={props.updateViewPort}
    maxBounds={[
      [-90, -180],
      [90, 180]
    ]}
    onClick={({latlng}) => (
      props.modifyLocationForm({
        ...props.location,
        ...latlng,
      })
    )}
  >
    <TileLayer
      noWrap
      attribution={LEAFLET_ATTRIBUTION}
      url={LEAFLET_URL}
    />
    {props.hasValidCoords && (
      <Marker
        position={props.location}
        icon={markerIcon}
      />
    )}

  </StyledMap>

);

const hasValidCoordsSelector = state => {
  const {isValidLocation} = state.views.create;
  return isValidLocation.lat && isValidLocation.lng;
};

const mapStateToProps = state => ({
  location: state.views.create.location,
  hasValidCoords: hasValidCoordsSelector(state),
  viewPort: state.views.create.viewPort,
});

const mapDispatchToProps = dispatch => ({
  updateViewPort: viewPort => dispatch(viewActions.updateViewPort(viewPort)),
  modifyLocationForm: location => dispatch(viewActions.modifyLocationForm(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationPicker);
