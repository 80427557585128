// location-editor.js

// (pure) utility functions without side-effects
// that create modified copies of supplied location

import assert from 'assert';
import _ from 'lodash';

const LOCATION_SIMPLE_EDIT_PROPS = ['name', 'description', 'lat', 'lng', 'icon'];
const BLUEPRINT_SIMPLE_EDIT_PROPS = ['name', 'image'];
const DEVICE_ITEM_SIMPLE_EDIT_PROPS = ['left', 'top', 'name', 'locationItemControl', 'valueDisplay'];

//
// Internal utility functions
//

const getBlueprintClone = (location, blueprintId) => {
  if (blueprintId === location.defaultLayer._id) {
    return _.cloneDeep(location.defaultLayer);
  }
  const bpIndex = _.findIndex(location.layers, {_id: blueprintId});
  assert(bpIndex > -1, 'We should always find a blueprint');
  return _.cloneDeep(location.layers[bpIndex]);
};

const cloneBlueprintWithDeviceItem = (location, deviceItemId) => {
  const blueprints = _.concat(location.defaultLayer, location.layers || []);
  const blueprint = _.find(blueprints, bp => _.find(bp.items, {_id: deviceItemId}));
  assert(blueprint, 'We should always find a blueprint');
  return getBlueprintClone(location, blueprint._id);
};

const replaceBlueprint = (location, blueprint) => {
  if (blueprint._id === location.defaultLayer._id) {
    return {...location, defaultLayer: blueprint};
  }
  const bpIndex = _.findIndex(location.layers, {_id: blueprint._id});
  assert(bpIndex > -1, 'We should always find a blueprint');
  const layers = location.layers.slice();
  layers[bpIndex] = blueprint;
  return {...location, layers};
};

//
// location level
//

const editLocationDetails = (location, edits) => ({
  ...location,
  ..._.pick(edits, LOCATION_SIMPLE_EDIT_PROPS),
});

const addLocationBlueprint = (location, blueprint) => {
  const layers = _.get(location, 'layers', []);
  return {
    ...location,
    layers: _.concat(layers, blueprint),
  };
};

const deleteLocationBlueprint = (location, blueprintId) => {
  const copy = {
    ...location,
    layers: _.get(location, 'layers', []).slice(),
  };
  assert(_.size(copy.layers), 'There must always be one layer left after blueprint removal');
  if (blueprintId === location.defaultLayer._id) {
    copy.defaultLayer = copy.layers.shift();
    return copy;
  }
  const bpIndex = _.findIndex(copy.layers, {_id: blueprintId});
  assert(bpIndex > -1, 'We should always find a blueprint');
  copy.layers.splice(bpIndex, 1);
  return copy;
};

//
// blueprint level
//

const editBlueprintDetails = (location, blueprintId, edits) => {
  const blueprint = {
    ...getBlueprintClone(location, blueprintId),
    ..._.pick(edits, BLUEPRINT_SIMPLE_EDIT_PROPS),
  };
  return replaceBlueprint(location, blueprint);
};

const addBlueprintDeviceSelection = (location, blueprintId, deviceSelection) => {
  const deviceItem = {
    deviceId: deviceSelection.value,
    type: deviceSelection.type?.value || 'default',
    size: deviceSelection.size?.value || 'default',
  };
  const blueprint = getBlueprintClone(location, blueprintId);
  blueprint.items.push(deviceItem);
  return replaceBlueprint(location, blueprint);
};

const deleteBlueprintDeviceItem = (location, deviceItemId) => {
  const blueprint = cloneBlueprintWithDeviceItem(location, deviceItemId);
  const itemIndex = _.findIndex(blueprint.items, {_id: deviceItemId});
  assert(itemIndex > -1, 'We should always find an item');
  blueprint.items.splice(itemIndex, 1);
  return replaceBlueprint(location, blueprint);
};

//
// device level
//

const editDeviceItemDetails = (location, deviceItemId, edits) => {
  const blueprint = cloneBlueprintWithDeviceItem(location, deviceItemId);
  const itemIndex = _.findIndex(blueprint.items, {_id: deviceItemId});
  assert(itemIndex > -1, 'We should always find an item');
  blueprint.items[itemIndex] = {
    ...blueprint.items[itemIndex],
    ..._.pick(edits, DEVICE_ITEM_SIMPLE_EDIT_PROPS),
  };
  return replaceBlueprint(location, blueprint);
};

export {
  LOCATION_SIMPLE_EDIT_PROPS,
  BLUEPRINT_SIMPLE_EDIT_PROPS,
  DEVICE_ITEM_SIMPLE_EDIT_PROPS,
  // location level
  editLocationDetails,
  addLocationBlueprint,
  deleteLocationBlueprint,
  // blueprint level
  editBlueprintDetails,
  addBlueprintDeviceSelection,
  deleteBlueprintDeviceItem,
  // device level
  editDeviceItemDetails,
};
