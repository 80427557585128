import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';

import {
  GridWrapper,
  NoDataContainer,
  ChartHeader,
} from './chart.styled';
import {ContentWrapper} from '../views.styled';
import Chart from './chart.view';
import SettingsMenu from './chart-settings.view';
import {actions} from '../../state/device-statistics.redux';
import {
  selectDeviceStatistics,
  selectChartTicks,
  selectNoDataMessage,
  selectChartDomain,
} from './chart.selectors';
import {timePeriods, timePeriodTypes} from './chart.constants';

const ChartWrapper = props => {

  // Get device from database
  useEffect(() => {
    props.getDevice(props.match.params.deviceId);
  }, []);

  // Get device statistics fields from database
  useEffect(() => {
    props.fetchDeviceStatisticsFields(props.match.params.deviceId);
  }, []);

  // Get device statistics from database
  useEffect(() => {

    const customTimePeriodSelected = props.selectedTimePeriod === timePeriodTypes.CUSTOM;
    const selectedTimePeriod = timePeriods[props.selectedTimePeriod];

    if (customTimePeriodSelected &&
      (props.customTimePeriodAppliedFromTime === '' ||
      props.customTimePeriodAppliedToTime === '')) {
      return;
    }

    const endTime = customTimePeriodSelected
      ? moment(props.customTimePeriodAppliedToTime).valueOf()
      : Date.now();

    const startTime = customTimePeriodSelected
      ? moment(props.customTimePeriodAppliedFromTime).valueOf()
      : endTime - selectedTimePeriod.time;

    let distance = 0;
    if (props.selectedResolution !== 'full') {
      if (customTimePeriodSelected) {
        const numPoints = selectedTimePeriod.resolutions[props.selectedResolution];
        const diff = moment(props.customTimePeriodAppliedToTime).unix()-moment(props.customTimePeriodAppliedFromTime).unix();
        distance = Math.round(diff/numPoints);
      } else {
        distance = selectedTimePeriod.resolutions[props.selectedResolution];
      }
    }

    props.fetchDeviceStatistics(
      props.match.params.deviceId,
      props.selectedMeasurement.type,
      startTime,
      endTime,
      distance
    );

  }, [
    props.selectedMeasurement,
    props.selectedTimePeriod,
    props.selectedResolution,
    props.customTimePeriodAppliedFromTime,
    props.customTimePeriodAppliedToTime,
  ]);

  return (
    <ContentWrapper>
      <GridWrapper>
        <ChartHeader>
          {props.device &&
            <div>
              <p>Device:</p>
              <h6>{props.device.name}</h6>
            </div>
          }
        </ChartHeader>
        {props.noDataMessage !== ''
          ? <NoDataContainer message={props.noDataMessage} />
          : <Chart
              deviceStats={props.deviceStats}
              selectedMeasurement={props.selectedMeasurement}
              selectedTimePeriod={props.selectedTimePeriod}
              selectedResolution={props.selectedResolution}
              chartTicks={props.chartTicks}
              chartDomain={props.chartDomain}
            />
        }
        <SettingsMenu statistics={props.statistics} />
      </GridWrapper>
    </ContentWrapper>
  );
};

const mapStateToProps = state => ({
  device: state.statistics.device,
  deviceStats: selectDeviceStatistics(state),
  selectedMeasurement: state.views.chart.selectedMeasurement,
  selectedTimePeriod: state.views.chart.selectedTimePeriod,
  selectedResolution: state.views.chart.selectedResolution,
  customTimePeriodAppliedFromTime: state.views.chart.customTimePeriodAppliedFromTime,
  customTimePeriodAppliedToTime: state.views.chart.customTimePeriodAppliedToTime,
  noDataMessage: selectNoDataMessage(state),
  chartTicks: selectChartTicks(state),
  chartDomain: selectChartDomain(state),
});

const mapDispatchToProps = dispatch => ({
  fetchDeviceStatistics: (deviceId, measurement, start, end, distance) => (
    dispatch(actions.fetchDeviceStatistics(deviceId, measurement, start, end, distance))
  ),
  fetchDeviceStatisticsFields: deviceId => (
    dispatch(actions.fetchDeviceStatisticsFields(deviceId))
  ),
  getDevice: deviceId => (
    dispatch(actions.getDevice(deviceId))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartWrapper);
