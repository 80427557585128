// views-menu-buttons.styled.js

// Modules
import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Icon} from 'react-icons-kit';

// Icons
import {plus as addIcon} from 'react-icons-kit/fa/plus';
import {check as saveIcon} from 'react-icons-kit/fa/check';
import {expand as expandIcon} from 'react-icons-kit/fa/expand';
import {compress as compressIcon} from 'react-icons-kit/fa/compress';
import {upload as uploadIcon} from 'react-icons-kit/fa/upload';
import {spinner as spinnerIcon} from 'react-icons-kit/fa/spinner';
import {pencil as editIcon} from 'react-icons-kit/fa/pencil';
import {chevronRight as selectIcon} from 'react-icons-kit/fa/chevronRight';
import {ban as banIcon} from 'react-icons-kit/fa/ban';
import {lineChart as chartIcon} from 'react-icons-kit/fa/lineChart';
import {ic_gps_fixed as crosshairsIcon} from 'react-icons-kit/md/ic_gps_fixed';
import {ic_delete as deleteIcon} from 'react-icons-kit/md/ic_delete'

// Local
import {colors} from './views.constants';
import {Button} from './views.styled';

const DEFAULT_BUTTON_WIDTH = 30;
const DEFAULT_ICON_SIZE = 22;
const DEFAULT_SMALL_ICON_SIZE = 16;
const RESPONSIVE_BUTTON_SIZE = 50;

const SpinningIcon = styled(Icon)`
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
  @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
  @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
`;

const OptionButtonStyled = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px;
  padding: 0;
  line-height: 16px;
  border-radius: 5px;
  width: ${`${DEFAULT_BUTTON_WIDTH}px`};
  height: ${`${DEFAULT_BUTTON_WIDTH}px`};
  border: ${({borderSize, borderStyle, borderColor}) => (
  `${borderSize || '1px'} ${borderStyle || 'solid'} ${borderColor || colors.GREY_ALT}`
)};
  color: ${props => (props.isDisabled ? '#333' : (props.color || colors.GREY_LIGHT))};
  background: ${props => (props.isDisabled ? '#CCC' : props.background)};
  transition: color 0.2s, background 0.2s;

  &:hover {
    color: ${props => (props.isDisabled ? '#333' : colors.WHITE)};
    background: ${props => (props.isDisabled ? '#CCC' : props.hoverColor)};
    transition: color 0.2s, background 0.2s;
  }
  &:focus {
    outline:0;
  }

  @media screen and (max-width: 1024px) {
    width: ${`${RESPONSIVE_BUTTON_SIZE}px`};
    height: ${`${RESPONSIVE_BUTTON_SIZE}px`};
  }
`;

const UploadFileButtonStyled = styled.label`
  margin: 3px;
  border-radius: 5px;
  width: ${`${DEFAULT_BUTTON_WIDTH}px`};
  height: ${`${DEFAULT_BUTTON_WIDTH}px`};
  color: ${colors.GREY_DARK};
  background: lightgreen;
  transition: background 0.2s, color 0.2s;

  &:hover {
    color: ${colors.BLACK};
    background: #4cff41;
    transition: background 0.2s, color 0.2s;
  }

  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  input[type="file"] {
    display: none;
  }

`;

const UploadFileButton = props => (
  <UploadFileButtonStyled>
    <input
      type="file"
      onChange={evt => {
        const file = evt.target.files[0];
        props.onChange(file);
      }}
    />
    {!props.isUploading
      ? <Icon size={DEFAULT_ICON_SIZE} icon={uploadIcon} />
      : <SpinningIcon size={DEFAULT_ICON_SIZE} icon={spinnerIcon} />
    }
  </UploadFileButtonStyled>
);

const OptionButton = props => (
  <OptionButtonStyled
    {..._.omit(props, 'onClick')}
    onClick={evt => {
      const preventDefault = _.get(props, 'preventDefault', true);
      if (preventDefault) {
        evt.preventDefault();
      }
      if (props.onClick && !props.isDisabled) {
        props.onClick(evt);
      }
    }}
  >
    {props.children}
  </OptionButtonStyled>
);

// just to have a manifest of which props apply
OptionButton.propTypes = {
  preventDefault: PropTypes.bool,
  background: PropTypes.string,
  hoverColor: PropTypes.string,
  onClick: PropTypes.func,
};

const AddButton = props => (
  <OptionButton
    isDisabled={props.disabled}
    background={props.background || colors.TRANSPARENT}
    hoverColor={colors.GREY_DARK}
    onClick={props.onClick}
  >
    <Icon size={DEFAULT_ICON_SIZE} icon={addIcon} />
  </OptionButton>
);

const EditButton = props => (
  <OptionButton
    isDisabled={props.disabled}
    background={colors.TRANSPARENT}
    hoverColor={colors.GREY_DARK}
    onClick={props.onClick}
  >
    <Icon size={DEFAULT_SMALL_ICON_SIZE} icon={editIcon} />
  </OptionButton>
);

const CancelButton = props => (
  <OptionButton
    isDisabled={props.disabled}
    background={colors.TRANSPARENT}
    hoverColor={colors.GREY_DARK}
    onClick={props.onClick}
  >
    <Icon size={DEFAULT_ICON_SIZE} icon={banIcon} />
  </OptionButton>
);

const DeleteButton = props => (
  <OptionButton
    isDisabled={props.disabled}
    background={colors.TRANSPARENT}
    hoverColor={colors.GREY_DARK}
    onClick={props.onClick}
  >
    <Icon size={17} icon={deleteIcon} />
  </OptionButton>
);

const SaveButton = props => (
  <OptionButton
    isDisabled={props.disabled}
    background={colors.TRANSPARENT}
    hoverColor={colors.GREY_DARK}
    onClick={props.onClick}
  >
    <Icon size={DEFAULT_ICON_SIZE} icon={saveIcon} />
  </OptionButton>
);

const CompressButton = props => (
  <OptionButton
    isDisabled={props.disabled}
    background={colors.TRANSPARENT}
    hoverColor={colors.GREY_DARK}
    onClick={props.onClick}
  >
    <Icon size={DEFAULT_SMALL_ICON_SIZE} icon={compressIcon} />
  </OptionButton>
);

const ExpandButton = props => (
  <OptionButton
    isDisabled={props.disabled}
    background={colors.TRANSPARENT}
    hoverColor={colors.GREY_DARK}
    onClick={props.onClick}
  >
    <Icon size={DEFAULT_SMALL_ICON_SIZE} icon={expandIcon} />
  </OptionButton>
);

const MapPointerButton = props => (
  <OptionButton
    isDisabled={props.disabled}
    background={colors.TRANSPARENT}
    hoverColor={colors.GREY_DARK}
    onClick={props.onClick}
    preventDefault={false}
  >
    <Icon size={DEFAULT_SMALL_ICON_SIZE} icon={crosshairsIcon} />
  </OptionButton>
);

const UploadButton = props => (
  <OptionButton
    isDisabled={props.disabled}
    background={'lightgreen'}
    hoverColor={colors.GREY_DARK}
    onClick={props.onClick}
  >
    <Icon size={DEFAULT_ICON_SIZE} icon={uploadIcon} />
  </OptionButton>
);

const LoadingButton = props => (
  <OptionButton
    isDisabled={props.disabled}
    background={'#eee'}
    hoverColor={colors.GREY_DARK}
    onClick={props.onClick}
  >
    <SpinningIcon size={DEFAULT_ICON_SIZE} icon={spinnerIcon} />
  </OptionButton>
);

const SelectButton = props => (
  <OptionButton
    isDisabled={props.disabled}
    background={'#eee'}
    hoverColor={colors.GREY_DARK}
    onClick={props.onClick}
    title={'Select'}
  >
    <Icon size={DEFAULT_ICON_SIZE} icon={selectIcon} />
  </OptionButton>
);


const ChartButton = props => (
  <OptionButton
    isDisabled={props.disabled}
    background={colors.TRANSPARENT}
    hoverColor={colors.GREY_DARK}
    onClick={props.onClick}
    title={'Statistics'}
  >
    <Icon size={DEFAULT_ICON_SIZE} icon={chartIcon} />
  </OptionButton>
);

const Loader = () => (
  <SpinningIcon size={40} icon={spinnerIcon} />
);

export {
  AddButton,
  EditButton,
  DeleteButton,
  CancelButton,
  SaveButton,
  ExpandButton,
  CompressButton,
  UploadButton,
  LoadingButton,
  SelectButton,
  UploadFileButton,
  ChartButton,
  MapPointerButton,
  Loader,
};
