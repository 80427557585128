import _ from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import {ListGroup} from 'reactstrap';
import {Icon} from 'react-icons-kit';
import {CSVLink} from 'react-csv';
import moment from 'moment';

import {timePeriods, timePeriodTypes} from './chart.constants';
import {
  selectChoosableMeasurements,
  selectCustomTimePeriodFromFeedback,
  selectCustomTimePeriodToFeedback,
} from './chart.selectors';
import {
  SettingsMenuContainer,
  SettingsMenuListGroupContainer,
  SettingsMenuListGroupHeader,
  StyledListGroupItem,
  CustomTimePeriodFromField,
  CustomTimePeriodFeedback,
  CustomTimePeriodToField,
  CustomTimePeriodFieldLabel,
  CustomTimePeriodInput,
  CustomTimePeriodButton,
} from './chart.styled';

import {actions as viewActions} from './chart.redux';

const SettingsMenu = props => (
  <SettingsMenuContainer>
    <MeasurementMenu
      measurements={props.measurements}
      selectMeasurement={props.selectMeasurement}
      selectedMeasurement={props.selectedMeasurement}
    />
    <TimePeriodsMenu
      selectTimePeriod={props.selectTimePeriod}
      selectedTimePeriod={props.selectedTimePeriod}
    />
    {props.selectedTimePeriod === timePeriodTypes.CUSTOM &&
      <CustomTimePeriodMenu
        customTimePeriodFromTime={props.customTimePeriodFromTime}
        customTimePeriodAppliedFromTime={props.customTimePeriodAppliedFromTime}
        setCustomTimePeriodFromTime={props.setCustomTimePeriodFromTime}
        setCustomTimePeriodAppliedFromTime={props.setCustomTimePeriodAppliedFromTime}
        customTimePeriodToTime={props.customTimePeriodToTime}
        customTimePeriodAppliedToTime={props.customTimePeriodAppliedToTime}
        setCustomTimePeriodToTime={props.setCustomTimePeriodToTime}
        setCustomTimePeriodAppliedToTime={props.setCustomTimePeriodAppliedToTime}
        customTimePeriodFromFeedback={props.customTimePeriodFromFeedback}
        customTimePeriodToFeedback={props.customTimePeriodToFeedback}
      />
    }
    <ResolutionMenu
      selectedTimePeriod={props.selectedTimePeriod}
      selectResolution={props.selectResolution}
      selectedResolution={props.selectedResolution}
    />
    {!_.isEmpty(props.deviceStats) &&
      <CSVLink data={props.deviceStats} filename={'sensor-data.csv'}>Download as CSV</CSVLink>
    }
  </SettingsMenuContainer>
);

const MeasurementMenu = ({measurements, selectMeasurement, selectedMeasurement}) => (
  <SettingsMenuListGroupContainer>
    <SettingsMenuListGroupHeader>MEASUREMENT</SettingsMenuListGroupHeader>
    <ListGroup>
      {_.map(measurements, measurement => (
        <StyledListGroupItem
          key={measurement.type}
          tag="a"
          action
          onClick={() => selectMeasurement(measurement)}
          active={measurement.type === selectedMeasurement.type}
        >
          {!!measurement.icon &&
          <Icon icon={measurement.icon} className="icon" size={20} />
          }
          {measurement.name}
        </StyledListGroupItem>
      ))}
    </ListGroup>
  </SettingsMenuListGroupContainer>
);

const TimePeriodsMenu = ({selectTimePeriod, selectedTimePeriod}) => (
  <SettingsMenuListGroupContainer>
    <SettingsMenuListGroupHeader>TIME PERIOD</SettingsMenuListGroupHeader>
    <ListGroup>
      {_.map(timePeriods, (value, key) => (
        <StyledListGroupItem
          key={key}
          tag="a"
          action
          onClick={() => selectTimePeriod(key)}
          active={key === selectedTimePeriod}
        >
          {value.name}
        </StyledListGroupItem>
      ))}
    </ListGroup>
  </SettingsMenuListGroupContainer>
);

const applyCustomTimePeriod = props => {
  if (applyButtonIsDisabled(props)) return;
  props.setCustomTimePeriodAppliedFromTime(props.customTimePeriodFromTime);
  props.setCustomTimePeriodAppliedToTime(props.customTimePeriodToTime);
};

const applyButtonIsDisabled = props => (
  !props.customTimePeriodFromTime ||
  !props.customTimePeriodToTime ||
  !!props.customTimePeriodFromFeedback ||
  !!props.customTimePeriodToFeedback ||
  (props.customTimePeriodFromTime === props.customTimePeriodAppliedFromTime &&
  props.customTimePeriodToTime === props.customTimePeriodAppliedToTime)
);

const CustomTimePeriodMenu = props => {
  React.useEffect(() => {
    const {
      customTimePeriodFromTime,
      customTimePeriodToTime,
    } = props;
    if (customTimePeriodFromTime === '' && customTimePeriodFromTime === ''){
      const oneDayAgo = moment((moment().unix() - (60 * 60 * 24)) * 1000).format('YYYY-MM-DD HH:mm');
      const now = moment().format('YYYY-MM-DD HH:mm');
      props.setCustomTimePeriodFromTime(oneDayAgo);
      props.setCustomTimePeriodToTime(now);
    }
  });
  return (
    <SettingsMenuListGroupContainer>
      <SettingsMenuListGroupHeader>CUSTOM TIME PERIOD</SettingsMenuListGroupHeader>
      <CustomTimePeriodFromField>
        <CustomTimePeriodFieldLabel>From:</CustomTimePeriodFieldLabel>
        <CustomTimePeriodInput
          placeholder="yyyy-mm-dd hh:mm"
          value={props.customTimePeriodFromTime}
          onChange={({target: {value}}) => props.setCustomTimePeriodFromTime(value)}
        />
        <CustomTimePeriodFeedback>{props.customTimePeriodFromFeedback}</CustomTimePeriodFeedback>
      </CustomTimePeriodFromField>
      <CustomTimePeriodToField>
        <CustomTimePeriodFieldLabel>To:</CustomTimePeriodFieldLabel>
        <CustomTimePeriodInput
          placeholder="yyyy-mm-dd hh:mm"
          value={props.customTimePeriodToTime}
          onChange={({target: {value}}) => props.setCustomTimePeriodToTime(value)}
        />
        <CustomTimePeriodFeedback>{props.customTimePeriodToFeedback}</CustomTimePeriodFeedback>
      </CustomTimePeriodToField>
      <CustomTimePeriodButton
        onClick={() => applyCustomTimePeriod(props)}
        disabled={applyButtonIsDisabled(props)}
      >
        Apply
      </CustomTimePeriodButton>
    </SettingsMenuListGroupContainer>
  );
};

const ResolutionMenu = ({selectedTimePeriod, selectedResolution, selectResolution}) => (
  <SettingsMenuListGroupContainer>
    <SettingsMenuListGroupHeader>RESOLUTION</SettingsMenuListGroupHeader>
    <ListGroup>
      {_.map(timePeriods[selectedTimePeriod].resolutions, (resolution, key) => (
        <StyledListGroupItem
          key={key}
          tag="a"
          action
          onClick={() => selectResolution(key)}
          active={key === selectedResolution}
        >
          {_.capitalize(key)}
        </StyledListGroupItem>
      ))}
      <StyledListGroupItem
        tag="a"
        action
        onClick={() => selectResolution('full')}
        active={selectedResolution === 'full'}
      >
        Full
      </StyledListGroupItem>
    </ListGroup>
  </SettingsMenuListGroupContainer>
);

const mapStateToProps = state => ({
  measurements: selectChoosableMeasurements(state),
  selectedMeasurement: state.views.chart.selectedMeasurement,
  selectedTimePeriod: state.views.chart.selectedTimePeriod,
  selectedResolution: state.views.chart.selectedResolution,
  customTimePeriodFromTime: state.views.chart.customTimePeriodFromTime,
  customTimePeriodAppliedFromTime: state.views.chart.customTimePeriodAppliedFromTime,
  customTimePeriodFromFeedback: selectCustomTimePeriodFromFeedback(state),
  customTimePeriodToFeedback: selectCustomTimePeriodToFeedback(state),
  customTimePeriodToTime: state.views.chart.customTimePeriodToTime,
  customTimePeriodAppliedToTime: state.views.chart.customTimePeriodAppliedToTime,
  deviceStats: state.statistics.deviceStats,
});

const mapDispatchToProps = dispatch => ({
  selectMeasurement: measurement => dispatch(viewActions.selectMeasurement(measurement)),
  selectTimePeriod: timePeriod => dispatch(viewActions.selectTimePeriod(timePeriod)),
  selectResolution: resolution => dispatch(viewActions.selectResolution(resolution)),
  setCustomTimePeriodFromTime: time => dispatch(viewActions.setCustomTimePeriodFromTime(time)),
  setCustomTimePeriodToTime: time => dispatch(viewActions.setCustomTimePeriodToTime(time)),
  setCustomTimePeriodAppliedFromTime: time => dispatch(viewActions.setCustomTimePeriodAppliedFromTime(time)),
  setCustomTimePeriodAppliedToTime: time => dispatch(viewActions.setCustomTimePeriodAppliedToTime(time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsMenu);
