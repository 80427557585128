// location.redux.js

import {isUndefined} from 'lodash';

const actionTypes = {
  LOCATION_TOGGLE_MENU: 'LOCATION_TOGGLE_MENU',
  LOCATION_SET_DEVICE_PLACEMENT_MODE: 'LOCATION_SET_DEVICE_PLACEMENT_MODE',
  LOCATION_SET_VIEW: 'LOCATION_SET_VIEW',
};

const actions = {
  toggleLocationMenu: bool => ({
    type: actionTypes.LOCATION_TOGGLE_MENU,
    payload: {bool},
  }),
  setDevicePlacementMode: isInDevicePlacementMode => ({
    type: actionTypes.LOCATION_SET_DEVICE_PLACEMENT_MODE,
    payload: {isInDevicePlacementMode},
  }),
  setView: currentView => ({
    type: actionTypes.LOCATION_SET_VIEW,
    payload: {currentView},
  })
};

const defaultState = {
  showsLocationMenu: true,
  isInDevicePlacementMode: false,
  deviceIdToRemove: null,
  currentView: null,
};

const reduce = (state = defaultState, action) => {
  switch (action.type) {

    case actionTypes.LOCATION_TOGGLE_MENU: {
      const {bool} = action.payload;
      const showsLocationMenu = isUndefined(bool) ? !state.showsLocationMenu : bool;
      return {...state, showsLocationMenu};
    }
    case actionTypes.LOCATION_SET_DEVICE_PLACEMENT_MODE: {
      const {isInDevicePlacementMode} = action.payload;
      return {...state, isInDevicePlacementMode};
    }

    case actionTypes.LOCATION_SET_VIEW: {
      const {currentView} = action.payload;
      return {...state, currentView};
    }

    default:
      return state;
  }
};

export {
  actionTypes,
  actions,
  reduce,
};
