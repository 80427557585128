// inputs

import React from 'react';
import ReactSelect from'react-select';
import styled from 'styled-components';
import {colors} from '../views/views.constants';

const Label = styled.label`
  display: flex;
  align-items: center;
  width: 100px;
`;

const StyledInputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  outline: none;
  border: 1px solid ${colors.GREY};
  border-radius: 3px;
  padding: 5px;
  margin: 5px;
  height: 40px;
  color: ${({color}) => color || colors.GREY_DARK};
  background: ${({background}) => background || colors.GREY_LIGHT} !important;

  /*
    ##Device = Desktops
    ##Screen = 1281px to higher resolution desktops
  */
  @media (min-width: 1481px) {
    width: ${props => {
      if (props.maxWidth) return '100%';
      if (props.width) return `${props.width}px`;
      return '400px';
    }}
  }

  @media (min-width: 1281px) and (max-width: 1480px) {
    width: 350px;
  }

  /*
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  @media (min-width: 1025px) and (max-width: 1280px) {
    width: 200px;
  }

  @media (max-width: 900px) {
    width: ${props => (props.maxWidth ? '100%' : '200px')};
  }
`;

const TextInput = props => (
  <StyledInputContainer>
    <Label>{props.label}</Label>
    <StyledInput
      maxWidth={props.maxWidth}
      maxLength={props.maxLength}
      width={props.width}
      min={props.min}
      max={props.max}
      color={props.color}
      background={props.background}
      type={props.type || 'text'}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
    />
  </StyledInputContainer>
);

const StyledSelect = styled(ReactSelect)`
  height: 40px;
  border: 1px solid ${colors.GREY};
  border-radius: 3px;
  margin: 5px;
  z-index: 50;

  div {
    border: none;
    color: ${({color}) => color || colors.GREY_DARK}
    background: ${({background}) => background || colors.GREY_LIGHT} !important;
  }

  /*
    ##Device = Desktops
    ##Screen = 1281px to higher resolution desktops
  */
  @media (min-width: 1481px) {
    width: ${props => props.width || '400'}px;
  }

  @media (min-width: 1281px) and (max-width: 1480px) {
    width: 350px;
  }

  /*
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  @media (min-width: 1025px) and (max-width: 1280px) {
    width: 200px;
  }

  @media (max-width: 1024px) {
    width: 200px;
  }

  @media (max-width: 500px) {
    width: 140px;
  }
`;

const SelectInput = props => (
  <StyledInputContainer>
    <Label>{props.label}</Label>
    <StyledSelect
      width={props.width}
      placeholder={props.placeholder}
      value={props.value}
      background={props.background}
      options={props.options}
      onFocus={props.onFocus}
      onChange={props.onChange}
    />
  </StyledInputContainer>
);

const StyledTextAreaInput = styled.textarea`
  resize: none;
  outline: none;
  border: 1px solid ${colors.GREY};
  border-radius: 3px;
  padding: 5px;
  margin: 5px;
  height: 200px;
  color: ${({color}) => color || colors.GREY_DARK};
  background: ${({background}) => background || colors.GREY_LIGHT} !important;

  /*
    ##Device = Desktops
    ##Screen = 1281px to higher resolution desktops
  */
  @media (min-width: 1481px) {
    width: ${props => {
      if (props.maxWidth) return '100%';
      if (props.width) return `${props.width}px`;
      return '400px';
    }}
  }
  @media (min-width: 1281px) and (max-width: 1480px) {
    width: 350px;
  }

  /*
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  @media (min-width: 1025px) and (max-width: 1280px) {
    width: 200px;
  }

  @media (max-width: 900px) {
    width: ${props => (props.maxWidth ? '100%' : '200px')};
  }
`;

const TextAreaInput = props => (
  <StyledInputContainer>
    <Label>{props.label}</Label>
    <StyledTextAreaInput
      maxLength={props.maxLength}
      maxWidth={props.maxWidth}
      width={props.width}
      min={props.min}
      max={props.max}
      color={props.color}
      background={props.background}
      type={props.type || 'text'}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
    />
  </StyledInputContainer>
);

export {
  TextInput,
  SelectInput,
  TextAreaInput,
}

