import React from 'react';
import {InputLabel, InputPrepender, InputValidator, InputWrapper, StyledInput} from './styled';
import {Icon} from 'react-icons-kit';
import {check as checkIcon} from 'react-icons-kit/fa/check';

const Input = (
  {
    onChange,
    type,
    value,
    placeholder,
    disabled,
    icon,
    valid,
  }
) => {
  const [focused, setFocused] = React.useState(false);

  const renderInputLabel = () => (
    <InputLabel
      focused={focused}
      hasValue={value.length}
    >
      {placeholder}
    </InputLabel>
  );

  const renderInputPrepender = () => (
    <InputPrepender>
      <Icon icon={icon} size={25} />
    </InputPrepender>

  );

  const renderInputField = () => (
    <StyledInput
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      disabled={disabled}
      type={type}
      onChange={onChange}
      value={value}
    />
  );

  const renderInputValidator = () => (
    <InputValidator valid={valid}>
      <Icon icon={checkIcon} size={20} />
    </InputValidator>
  );

  const renderInput = () => (
    <InputWrapper>
      {renderInputLabel()}
      {renderInputPrepender()}
      {renderInputField()}
      {renderInputValidator()}
    </InputWrapper>
  );

  return renderInput();
};

export default Input;
