// location-edit.redux

import {pick, omit} from 'lodash';
import {editLocationDetails, LOCATION_SIMPLE_EDIT_PROPS} from '../../../state/location-editor.utility';
import {actions as locationsActions} from '../../../state/locations.redux';

const actionTypes = {
  LOCATION_EDITS_BEGIN: 'LOCATION_EDITS_BEGIN',
  LOCATION_EDITS_UPDATE: 'LOCATION_EDITS_UPDATE',
  LOCATION_EDITS_SAVE: 'LOCATION_EDITS_SAVE',
};

const cancelEditing = locationId => ({
  type: actionTypes.LOCATION_EDITS_SAVE,
  payload: {locationId},
});

const actions = {
  beginEditing: location => ({
    type: actionTypes.LOCATION_EDITS_BEGIN,
    payload: {location},
  }),
  updateLocationEdits: (locationId, locationEdits) => ({
    type: actionTypes.LOCATION_EDITS_UPDATE,
    payload: {locationId, locationEdits},
  }),
  saveLocationEdits: (location, edits) => dispatch => {
    const modified = editLocationDetails(location, edits);
    return dispatch(locationsActions.updateLocation(modified))

      .then(() => dispatch(cancelEditing(location._id)));
  },
};

const defaultState = {
  edits: {},
};

const reduce = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.LOCATION_EDITS_BEGIN: {
      const {location} = action.payload;
      const edits = {
        ...state.edits,
        [location._id]: pick(location, LOCATION_SIMPLE_EDIT_PROPS),
      };
      return {...state, edits};
    }

    case actionTypes.LOCATION_EDITS_UPDATE: {
      const {locationId, locationEdits} = action.payload;
      const edits = {
        ...state.edits,
        [locationId]: {
          ...state.edits[locationId],
          ...locationEdits,
        }
      };
      return {...state, edits};
    }

    case actionTypes.LOCATION_EDITS_SAVE: {
      const {locationId} = action.payload;
      return {...state, edits: omit(state.edits, locationId)};
    }

    default:
      return state;
  }
};

export {
  actions,
  reduce,
}
