// views.redux.js

import {actions as imageActions} from '../state/images.redux';

const actionTypes = {
  LOCATIONS_SET_DIALOG_STATE: 'LOCATIONS_SET_DIALOG_STATE',
  VIEWS_SET_CURRENT_LOCATION_ID: 'VIEWS_SET_CURRENT_LOCATION_ID',
  VIEWS_SET_CURRENT_BLUEPRINT_ID: 'VIEWS_SET_CURRENT_BLUEPRINT_ID',
  VIEWS_SET_CURRENT_DEVICE_ID: 'VIEWS_SET_CURRENT_DEVICE_ID',
  VIEWS_SET_BLUEPRINT_UPLOADING: 'VIEWS_SET_BLUEPRINT_UPLOADING',
  VIEWS_REPORT_IMAGE_SIZE: 'VIEWS_REPORT_IMAGE_SIZE',
  VIEWS_SET_IMAGE_UPLOADING: 'VIEWS_SET_IMAGE_UPLOADING',
  VIEWS_TOGGLE_MENU: 'VIEWS_TOGGLE_MENU',
};

const actions = {
  setDialogState: locationId => ({
    type: actionTypes.LOCATIONS_SET_DIALOG_STATE,
    payload: {dialogState: locationId},
  }),
  uploadBlueprintImage: imageFile => dispatch => {
    dispatch({
      type: actionTypes.VIEWS_SET_BLUEPRINT_UPLOADING,
      payload: {isBlueprintUploading: true},
    });
    // do the actual uploads
    return dispatch(imageActions.uploadBlueprintImage(imageFile))
      .then(image => {
        dispatch({
          type: actionTypes.VIEWS_SET_BLUEPRINT_UPLOADING,
          payload: {isBlueprintUploading: false},
        });
        return image;
      })
      .catch(() => dispatch({
        type: actionTypes.VIEWS_SET_BLUEPRINT_UPLOADING,
        payload: {isBlueprintUploading: false},
      }));
  },
  uploadImage: (imageFile, nameSpace) => dispatch => {
    dispatch({
      type: actionTypes.VIEWS_SET_IMAGE_UPLOADING,
      payload: {isImageUploading: true},
    });
    // do the actual uploads
    return dispatch(imageActions.uploadImage(imageFile, nameSpace))
      .then(image => {
        dispatch({
          type: actionTypes.VIEWS_SET_IMAGE_UPLOADING,
          payload: {isImageUploading: false},
        });
        return image;
      })
      .catch(() => dispatch({
        type: actionTypes.VIEWS_SET_IMAGE_UPLOADING,
        payload: {isImageUploading: false},
      }));
  },
  // setCurrentLocationId SHOULD ONLY BE USED FROM location.view/index.js !!
  setCurrentLocationId: locationId => ({
    type: actionTypes.VIEWS_SET_CURRENT_LOCATION_ID,
    payload: {locationId},
  }),
  // setCurrentBlueprintId SHOULD ONLY BE USED FROM location.view/index.js !!
  setCurrentBlueprintId: blueprintId => ({
    type: actionTypes.VIEWS_SET_CURRENT_BLUEPRINT_ID,
    payload: {blueprintId},
  }),
  setCurrentDeviceId: currentDeviceId => ({
    type: actionTypes.VIEWS_SET_CURRENT_DEVICE_ID,
    payload: {currentDeviceId},
  }),
  // a place to store intrinsic image sizes
  reportImageSize: (imageSrc, size) => ({
    type: actionTypes.VIEWS_REPORT_IMAGE_SIZE,
    payload: {
      imageSrc,
      size,
    },
  }),
  toggleMenu: () => ({
    type: actionTypes.VIEWS_TOGGLE_MENU,
  })
};

const defaultState = {
  dialogState: null,
  currentBlueprintId: null,
  currentLocationId: null,
  currentDeviceId: null,
  isBlueprintUploading: false,
  isImageUploading: false,
  imageSizes: {},
  showMenu: true,
};

const reduce = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.LOCATIONS_SET_DIALOG_STATE: {
      const {dialogState} = action.payload;
      return {...state, dialogState};
    }
    case actionTypes.VIEWS_SET_CURRENT_LOCATION_ID: {
      const {locationId} = action.payload;
      return {...state, currentLocationId: locationId || null};
    }
    case actionTypes.VIEWS_SET_CURRENT_BLUEPRINT_ID: {
      const {blueprintId} = action.payload;
      return {...state, currentBlueprintId: blueprintId || null};
    }
    case actionTypes.VIEWS_SET_CURRENT_DEVICE_ID: {
        const {currentDeviceId} = action.payload;
        return {...state, currentDeviceId}
    }
    case actionTypes.VIEWS_SET_BLUEPRINT_UPLOADING: {
      const {isBlueprintUploading} = action.payload;
      return {...state, isBlueprintUploading};
    }
    case actionTypes.VIEWS_REPORT_IMAGE_SIZE: {
      const {imageSrc, size} = action.payload;
      return {
        ...state,
        imageSizes: {
          ...state.imageSizes,
          [imageSrc]: {...size},
        },
      };
    }
    case actionTypes.VIEWS_TOGGLE_MENU: {
      return {...state, showMenu: !state.showMenu};
    }
    default:
      return state;
  }
};

export {
  actions,
  reduce,
};
