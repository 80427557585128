// location-menu.selectors.js

import _ from 'lodash';
import {createSelector} from 'reselect';
import {allowedImageNameSpaces} from '../../../constants';
import {selectFilteredLocations} from '../../locations.view/locations.selectors';
import {selectAllCurrentLocationBlueprints} from '../location.selectors';

const selectBlueprintPage = state => state.views.locationMenu.blueprintPage;
const selectBlueprintPageSize = state => state.views.locationMenu.blueprintPageSize;

const selectPaginatedBlueprints = createSelector(
  (state, props) => selectAllCurrentLocationBlueprints(state, props),
  selectBlueprintPage,
  selectBlueprintPageSize,
  (blueprints, page, pageSize) => {
    const sortedBlueprints = _.sortBy(blueprints, 'name');
    const startIndex = page * pageSize;
    const endIndex = (page + 1) * pageSize;
    return _.slice(sortedBlueprints, startIndex, endIndex);
  },
);

const selectTotalNumBlueprints = createSelector(
  (state, props) => selectAllCurrentLocationBlueprints(state, props),
  blueprints => (_.size(blueprints)),
);

const selectAllBlueprintEdits = state => state.views.locationMenu.blueprintEdits;
const selectAllBlueprintShowsDevices = state => state.views.locationMenu.blueprintShowsDevices;

const selectAllDeviceItemEdits = state => state.views.locationMenu.deviceItemEdits;
const selectAllDeviceItemIsExpanded = state => state.views.locationMenu.expandedDevices;

const selectBlueprintCreatorIsExpanded = state => (
  state.views.locationMenu.blueprintCreator.isExpanded
);
const selectCurrentLocationId = state => state.views.common.currentLocationId;

const selectAllDeviceIcons = state => state.images[allowedImageNameSpaces.DEVICE_ICON];

const createSelectorSimpleBlueprintEdits = () => createSelector(
  (state, props) => props.blueprintId,
  selectAllBlueprintEdits,
  (blueprintId, blueprintEdits) => blueprintEdits[blueprintId] || '',
);

const createSelectorBlueprintShowsDevices = () => createSelector(
  (state, props) => props.blueprintId,
  selectAllBlueprintShowsDevices,
  (blueprintId, isExpanded) => isExpanded[blueprintId] || false,
);

const createSelectorSimpleDeviceItemEdits = () => createSelector(
  (state, props) => _.get(props, 'deviceItem._id'),
  selectAllDeviceItemEdits,
  (deviceItemId, itemEdits) => itemEdits[deviceItemId] || null,
);

const createSelectorDeviceItemIsExpanded = () => createSelector(
  (state, props) => _.get(props, 'deviceItem._id'),
  selectAllDeviceItemIsExpanded,
  (deviceItemId, isExpanded) => {
    const deviceItemIndex = _.findIndex(isExpanded, id => id === deviceItemId);
    return isExpanded[deviceItemIndex] || false
  },
);

const selectCurrentBlueprintCreatorIsExpanded = createSelector(
  selectBlueprintCreatorIsExpanded,
  selectCurrentLocationId,
  (isExpanded, locationId) => _.includes(isExpanded, locationId)
);

// FIXME: This is similar to the selector for location icons
// FIXME: and should be extracted to a generic reusable method
const createSelectorDeviceIcons = () => createSelector(
  selectAllDeviceIcons,
  deviceIcons => (
    _.map(deviceIcons, icon => {
      const split = _.last(_.split(icon, '/'));
      const label = _.first(_.split(split, '.'));
      return {label, value: icon};
    })
  )
);

export {
  createSelectorSimpleBlueprintEdits,
  createSelectorBlueprintShowsDevices,
  createSelectorSimpleDeviceItemEdits,
  createSelectorDeviceItemIsExpanded,
  selectCurrentBlueprintCreatorIsExpanded,
  createSelectorDeviceIcons,
  selectPaginatedBlueprints,
  selectTotalNumBlueprints,
};
