// constants/index.js

const resourceTypes = {
  AUTH_LOCAL: '/auth/local',
};

// this should really come from yggio-image-downloader
const allowedImageNameSpaces = {
  BLUEPRINT: 'blueprints',
  LOCATION_ICON: 'location_icons',
  DEVICE_ICON: 'device_icons',
};

// this should really come from yggio-core-constants
const subscriptionStates = {
  SUBSCRIBED: 'SUBSCRIBED',
  SUBSCRIBING: 'SUBSCRIBING',
  UNAUTHORIZED: 'UNAUTHORIZED',
};

const SOCKET_MESSAGE = 'message';

const socketCommandTypes = {
  SUBSCRIBE: 'listen',
  UNSUBSCRIBE: 'unsubscribe',
};

const socketMessageTypes = {
  UNAUTHORIZED: 'unauthorized',
  SUBSCRIBED: 'subscribed',
  UNSUBSCRIBED: 'unsubscribed',
  DEVICE: 'message',
};

const keyCodes = {
  ESCAPE: 27,
};

const INPUT_NAME_MAX_LENGTH = 20;
const INPUT_DESC_MAX_LENGTH = 200;
const INPUT_COORDS_MAX_LENGTH = 3;

export {
  resourceTypes,
  allowedImageNameSpaces,
  subscriptionStates,
  SOCKET_MESSAGE,
  socketCommandTypes,
  socketMessageTypes,
  INPUT_NAME_MAX_LENGTH,
  INPUT_DESC_MAX_LENGTH,
  INPUT_COORDS_MAX_LENGTH,
  keyCodes,
};
