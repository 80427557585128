// device-status

import _ from 'lodash';
import moment from 'moment';
import {colors} from '../views/views.constants';

const connectorMap = {
  'thingpark-connector': 'Actility Thingpark',
  'lora-app-server-connector': 'LoRa App Server'
};

export default {
  'icpe-wmbus': (obj, iotnodes) => {
    const status = {
      text: []
    };
    const gw = _.find(iotnodes, ['_id', obj.connector]);
    if (!_.get(gw, 'connected')) {
      status.status = 1;
      status.text.push('Gateway offline');
      status.color = colors.YELLOW;
    } else if (obj.connected) {
      status.text.push('OK!');
      status.status = 0;
      status.color = colors.GREEN;
    }
    return status;
  },
  'zwave-device': (obj, iotnodes) => {
    const {vid, pid} = obj;
    const status = {text: []};
    if (obj.connector) {
      if (vid && pid) {
        const level = _.get(obj, 'nodeInfo.commandClasses.0x0080.battery.level');
        if (level) {
          const levelText = level === '255' ? 'LOW' : level + '%';
          status.text.push('Battery: ' + levelText);
          status.status = 0;
          switch (true) {
            case (level <= 10):
              status.color = colors.RED;
              status.status = 1;
              break;
            case (level < 30 && level > 10):
              status.color = colors.YELLOW;
              status.status = 2;
              break;
            case (level <= 100 && level >= 30):
              status.color = colors.GREEN;
              status.status = 0;
              break;
            case (level === '255'):
              status.color = colors.RED;
              break;
            default: break;
          }

        } else {
          status.text.push('Included');
          status.status = 0;
          status.color = colors.GREEN;
        }
      } else {
        status.text.push('Improperly included');
        status.status = 2;
        status.color = colors.RED;
      }
      const gw = _.find(iotnodes, ['_id', obj.connector]);
      if (!_.get(gw, 'connected')) {
        status.status = 1;
        status.text.push('Gateway offline');
        status.color = colors.YELLOW;
      }
    } else {
      status.status = 2;
      status.text.push('Unknown gateway');
      status.color = colors.RED;
    }
    return status;
  },
  'zwave-gateway': obj => {
    if (obj.connected) {
      const status = {text: []};
      if (_.get(obj, 'downlinkQueue')) {
        status.color = colors.GREEN;
        status.text.push('Gateway online');
        status.status = 0;
      } else {
        status.color = colors.YELLOW;
        status.text.push('Gateway has not been claimed by an integration');
        status.status = 1;
      }
      return status;
    }
    return {
      text: ['Gateway offline'],
      status: 1,
      color: colors.YELLOW
    };
  },
  'lora-node': obj => {
    const integration = connectorMap[_.get(obj, 'connector.nodeType')] || 'unknown integration';
    const status = {
      color: colors.GREEN,
      text: [`Connected to ${integration}`],
      status: 0
    };

    if (obj.value && obj.value.rxInfo && obj.value.rxInfo.every(v => (v.rssi + v.loRaSNR) <= -125)) {
      status.color = colors.YELLOW;
      status.text.push('Bad signal');
      status.status = 2;
    } else if (obj.value && obj.value.rxInfo && obj.value.rxInfo.every(v => (v.rssi + v.loRaSNR) >= -110)) {
      status.color = colors.GREEN;
      status.text.push('Good signal');
      status.status = 0;
    }
    return status;
  },
  'fiware-weather-observed': obj => ({
    color: colors.GREEN,
    text: ['LoRa-device stored on server'],
    status: 0
  }),
  'simple-lora-node': obj => {
    const status = {
      color: colors.GREEN,
      text: ['LoRa-device stored on server'],
      status: 0
    };

    if (_.get(obj, 'translatedFrom')) {
      status.color = colors.GREEN;
      status.text.push(['Translated Node']);
      status.status = 0;
    }

    const digital2 = _.get(obj, 'value.digital2');
    if (_.eq(digital2, 0)) {
      status.class = 'text-success';
      status.text.push(['Water level OK']);
      status.status = 0;
    }

    if (_.eq(digital2, 1)) {
      status.class = 'text-danger';
      status.text.push(['Water level critical']);
      status.status = 0;
    }

    const occupancy = _.get(obj, 'value.occupancy');
    if (_.eq(occupancy, 0)) {
      status.color = colors.GREEN;
      status.text.push(['empty']);
      status.status = 0;
    }

    if (_.eq(occupancy, 1)) {
      status.color = colors.YELLOW;
      status.text.push(['occupied']);
      status.status = 1;
    }

    if (_.eq(occupancy, 2)) {
      status.color = colors.RED;
      status.text.push(['occupied']);
      status.status = 2;
    }

    if (_.lte(occupancy, 75)) {
      status.color = colors.GREEN;
      status.text.push(['OK']);
      status.status = 0;
    }

    const presence = _.get(obj, 'value.presence.value');
    if (presence) {
      status.color = colors.RED;
      status.text.push(['occupied']);
      status.status = 2;
    }

    const floodValue = _.get(obj, 'value.flood.value');
    if (_.gt(floodValue, 75)) {
      status.color = colors.RED;
      status.text.push(['flooded']);
      status.status = 2;
    }

    return status;
  },
  vapix: obj => ({
    color: colors.GREEN,
    text: ['Axis camera Ok'],
    status: 0
  }),
  'astro-clock': obj => {
    if (moment().diff(obj.updatedAt, 'hours') > 24) {
      return {
        text: ['Not working'],
        status: 1,
        color: colors.RED
      };
    }
    return {
      color: colors.GREEN,
      text: ['Astro clock OK'],
      status: 0
    };
  },
  'nibe-system': obj => ({
    // var statuses = ['ONLINE', 'PENDING', 'OFFLINE'];
    text: [obj.connectionStatus],
    status: obj.connectionStatus === 'ONLINE' ? 0 : 1,
    color: obj.connectionStatus === 'ONLINE' ? '' : (obj.connectionStatus === 'PENDING' ? colors.YELLOW : colors.RED)
  }),
  'nibe-node': obj => ({
    color: colors.GREEN,
    text: ['See system node'],
    status: 0
  }),
  'enum-state': obj => ({
    color: colors.GREEN,
    text: ['State OK'],
    status: 0
  }),
  'feedback-controller': obj => ({
    color: colors.GREEN,
    text: ['Feedback Controller OK'],
    status: 0
  }),
  'generic': obj => ({
    color: colors.GREEN,
    text: ['Generic-node'],
    status: 0
  })
};
