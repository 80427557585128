// locations-map-selectors.js

import {createSelector} from 'reselect';
import {
  sum,
  map,
  concat,
  filter,
  mean,
  includes,
  compose,
  flatten,
} from 'lodash/fp';

import {
  get,
  isFinite,
} from 'lodash';

import {LUND_COORDS, colors} from '../../views.constants';

//
// ///// map selectors
//

const selectLocationsCentroid = createSelector(
  state => state.locations,
  locations => {
    const list = map(loc => loc, locations); // to array
    const numCoords = list.length;
    if (!numCoords) {
      return LUND_COORDS;
    }
    const lat = sum(map(c => c.lat, list)) / numCoords;
    const lng = sum(map(c => c.lng, list)) / numCoords;
    return {lat, lng};
  },
);

const selectLocationsMapCurrentZoom = createSelector(
  state => state.views.locationsMap.viewPort,
  viewPort => (viewPort ? viewPort.zoom : null)
);

const selectLocationAverageTemp = createSelector(
  (state, props) => props.locationId,
  state => state.locations,
  state => state.devices,
  (locationId, locations, devices) => {
    const location = locations[locationId];
    const mergeLayers = location => concat(location.defaultLayer, location.layers);
    const setDevices = ({deviceId}) => devices[deviceId];
    const temperature = node => {
      if (isFinite(get(node, 'value.temperature.value'))) {
        return get(node, 'value.temperature.value');
      } else if(isFinite(get(node, 'value.temperature'))) {
        return get(node, 'value.temperature');
      } else if(isFinite(get(node, 'value.averageTemperature.value'))) {
        return get(node, 'value.averageTemperature.value')
      }
      return undefined;
    };
    return compose(
      mean,
      map(temperature),
      filter(temperature),
      map(setDevices),
      flatten,
      map('items'),
      mergeLayers,
    )(location);
  }
);

const selectLocationStatusColor = () => createSelector(
  (state, props) => props.locationId,
  state => state.locations,
  state => state.devices,
  (locationId, locations, devices) => {
    const location = locations[locationId];
    const mergeLayers = location => concat(location.defaultLayer, location.layers);
    const setDevices = ({deviceId}) => devices[deviceId];

    const statusColors = compose(
      map('status.color'),
      map(setDevices),
      flatten,
      map('items'),
      mergeLayers,
    )(location);

    if (includes(colors.RED, statusColors)) return {[locationId]: colors.RED};
    if (includes(colors.YELLOW, statusColors)) return {[locationId]: colors.YELLOW};
    return {[locationId]: colors.GREEN};
  }
);

//
// ///// map marker related selectors
// ///// These get created one per marker instance
//

export {
  selectLocationsCentroid,
  selectLocationsMapCurrentZoom,
  selectLocationAverageTemp,
  selectLocationStatusColor,
};
