// location-menu.redux.js

// Modules
import _ from 'lodash';

import {
  BLUEPRINT_SIMPLE_EDIT_PROPS,
  DEVICE_ITEM_SIMPLE_EDIT_PROPS,
  // location level
  addLocationBlueprint,
  // blueprint level
  deleteLocationBlueprint,
  editBlueprintDetails,
  addBlueprintDeviceSelection,
  deleteBlueprintDeviceItem,
  // device level
  editDeviceItemDetails,
} from '../../../state/location-editor.utility';
import {actions as locationsActions} from '../../../state/locations.redux';

const actionTypes = {

  BLUEPRINT_SET_PAGE: 'BLUEPRINT_SET_PAGE',
  // creating a new blueprint
  BLUEPRINT_CREATOR_OPEN: 'BLUEPRINT_CREATOR_OPEN',
  BLUEPRINT_CREATOR_CLOSE: 'BLUEPRINT_CREATEOR_CLOSE',
  BLUEPRINT_CREATOR_UPDATE: 'BLUEPRINT_CREATOR_UPDATE',

  // show device section for a blueprint
  BLUEPRINT_SHOW_BLUEPRINT_DEVICES: 'BLUEPRINT_SHOW_BLUEPRINT_DEVICES',

  // show & handle menu edits to simple blueprint details (saved continuously)
  BLUEPRINT_EDITS_BEGIN: 'BLUEPRINT_EDITS_BEGIN',
  BLUEPRINT_EDITS_END: 'BLUEPRINT_EDITS_END',
  BLUEPRINT_EDITS_UPDATE: 'BLUEPRINT_EDITS_UPDATE',
  // adding a device to a blueprint requires confirmation, so is kept
  // segregated from BLUEPRINT_EDITS, but IS cleared by BLUEPRINT_EDITS_END
  BLUEPRINT_DEVICE_ADDER_UPDATE: 'BLUEPRINT_DEVICE_ADDER_UPDATE',
  BLUEPRINT_DEVICE_FILTER: 'BLUEPRINT_DEVICE_FILTER',

  // show and handle menu edits to simple deviceItem details (saved continuously)
  DEVICE_ITEM_EDITS_BEGIN: 'DEVICE_ITEM_EDITS_BEGIN',
  DEVICE_ITEM_EDITS_END: 'DEVICE_ITEM_EDITS_END',
  DEVICE_ITEM_EDITS_UPDATE: 'DEVICE_ITEM_EDITS_UPDATE',
  DEVICE_EXPAND: 'DEVICE_EXPAND',
  DEVICE_COMPRESS: 'DEVICE_COMPRESS',
};

const actions = {
  setPage: blueprintPage => ({
    type: actionTypes.BLUEPRINT_SET_PAGE,
    payload: {blueprintPage},
  }),
  // create blueprint
  openBlueprintCreator: locationId => ({
    type: actionTypes.BLUEPRINT_CREATOR_OPEN,
    payload: {locationId},
  }),
  cancelBlueprintCreator: locationId => ({
    type: actionTypes.BLUEPRINT_CREATOR_CLOSE,
    payload: {locationId},
  }),
  saveBlueprintCreator: (location, blueprint) => dispatch => {
    // save and then close
    const modifiedLocation = addLocationBlueprint(location, blueprint);
    dispatch(locationsActions.updateLocation(modifiedLocation));
    // close right away
    return dispatch({
      type: actionTypes.BLUEPRINT_CREATOR_CLOSE,
      payload: {locationId: location._id},
    });
  },
  updateBlueprintCreator: (locationId, updates) => ({
    type: actionTypes.BLUEPRINT_CREATOR_UPDATE,
    payload: {locationId, updates},
  }),

  // delete blueprint
  deleteBlueprint: (location, blueprintId) => dispatch => {
    const modifiedLocation = deleteLocationBlueprint(location, blueprintId);
    dispatch(locationsActions.updateLocation(modifiedLocation));
  },

  // shows blueprint devices section
  setBlueprintShowsDevices: (blueprintId, showsDevices) => ({
    type: actionTypes.BLUEPRINT_SHOW_BLUEPRINT_DEVICES,
    payload: {blueprintId, showsDevices},
  }),

  // blueprint edits section
  beginBlueprintEdits: blueprint => ({
    type: actionTypes.BLUEPRINT_EDITS_BEGIN,
    payload: {blueprint},
  }),
  updateBlueprintEdits: (location, blueprintId, details) => dispatch => {
    const blueprintEdits = _.pick(details, BLUEPRINT_SIMPLE_EDIT_PROPS);
    // store the local stuff
    dispatch({
      type: actionTypes.BLUEPRINT_EDITS_UPDATE,
      payload: {blueprintId, blueprintEdits},
    });
    // and save the location edits with a fire and forget -- gets saved continuously
    const modifiedLocation = editBlueprintDetails(location, blueprintId, blueprintEdits);
    return dispatch(locationsActions.updateLocation(modifiedLocation));
  },
  cancelBlueprintEdits: blueprintId => ({
    type: actionTypes.BLUEPRINT_EDITS_END,
    payload: {blueprintId},
  }),

  // the device adder section
  updateBlueprintDeviceAdder: (blueprintId, deviceSelection) => ({
    type: actionTypes.BLUEPRINT_DEVICE_ADDER_UPDATE,
    payload: {blueprintId, deviceSelection},
  }),
  saveBlueprintDeviceAdder: (location, blueprintId, deviceSelection) => dispatch => {
    const modifiedLocation = addBlueprintDeviceSelection(location, blueprintId, deviceSelection);

    dispatch(locationsActions.updateLocation(modifiedLocation));
    dispatch({
      type: actionTypes.BLUEPRINT_DEVICE_ADDER_UPDATE,
      payload: {blueprintId, deviceSelection: null},
    });
  },
  cancelBlueprintDeviceAdder: blueprintId => ({
    type: actionTypes.BLUEPRINT_DEVICE_ADDER_UPDATE,
    payload: {blueprintId, deviceSelection: null},
  }),

  // remove a device-item from a blueprint
  deleteBlueprintDeviceItem: (location, deviceItemId) => dispatch => {
    const modifiedLocation = deleteBlueprintDeviceItem(location, deviceItemId);
    dispatch(locationsActions.updateLocation(modifiedLocation));
  },
  filterBlueprintDevices: blueprintDeviceFilter => ({
    type: actionTypes.BLUEPRINT_DEVICE_FILTER,
    payload: {blueprintDeviceFilter}
  }),

  // device edits section
  beginDeviceItemEdits: deviceItem => ({
    type: actionTypes.DEVICE_ITEM_EDITS_BEGIN,
    payload: {deviceItem},
  }),
  updateDeviceItemEdits: (location, deviceItemId, details) => dispatch => {
    const deviceItemEdits = _.pick(details, DEVICE_ITEM_SIMPLE_EDIT_PROPS);
    // store the local stuff
    dispatch({
      type: actionTypes.DEVICE_ITEM_EDITS_UPDATE,
      payload: {deviceItemId, deviceItemEdits},
    });
    // and save the location edits with a fire and forget -- gets saved continuously
    const modifiedLocation = editDeviceItemDetails(location, deviceItemId, deviceItemEdits);
    return dispatch(locationsActions.updateLocation(modifiedLocation));
  },
  cancelDeviceItemEdits: deviceItemId => ({
    type: actionTypes.DEVICE_ITEM_EDITS_END,
    payload: {deviceItemId},
  }),
  expandDevice: deviceId => ({
    type: actionTypes.DEVICE_EXPAND,
    payload: {deviceId},
  }),
  compressDevice: deviceId => ({
    type: actionTypes.DEVICE_COMPRESS,
    payload: {deviceId},
  }),
};

const defaultState = {
  blueprintPage: 0,
  blueprintPageSize: 8,
  // blueprint creator cache (key = locationId)
  blueprintCreators: {},
  // blueprint shows devices cache (key = blueprintId)
  blueprintShowsDevices: {},
  // blueprint edits cache (key = blueprintId)
  blueprintEdits: {},
  // device creator cache (key = blueprintId)
  blueprintDeviceAdders: {},
  blueprintDeviceFilter: '',

  // yet to be implemented fully
  deviceItemEdits: {},
  deviceItemIsExpanded: {},
  expandedDevices: [],
};

const reduce = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.BLUEPRINT_SET_PAGE: {
      const {blueprintPage} = action.payload;
      return {...state, blueprintPage};
    }
    // blueprint creator
    case actionTypes.BLUEPRINT_CREATOR_OPEN: {
      const {locationId} = action.payload;
      return {
        ...state,
        blueprintCreators: {
          ...state.blueprintCreators,
          [locationId]: {},
        },
      };
    }
    case actionTypes.BLUEPRINT_CREATOR_CLOSE: {
      const {locationId} = action.payload;
      return {
        ...state,
        blueprintCreators: _.omit(state.blueprintCreators, locationId),
      };
    }
    case actionTypes.BLUEPRINT_CREATOR_UPDATE: {
      const {locationId, updates} = action.payload;
      return {
        ...state,
        blueprintCreators: {
          ...state.blueprintCreators,
          [locationId]: {
            ...state.blueprintCreators[locationId],
            ...updates,
          },
        },
      };
    }

    // show blueprint devices
    case actionTypes.BLUEPRINT_SHOW_BLUEPRINT_DEVICES: {
      const {blueprintId, showsDevices} = action.payload;
      if (showsDevices) {
        return {
          ...state,
          blueprintShowsDevices: {
            ...state.blueprintShowsDevices,
            [blueprintId]: showsDevices,
          },
        };
      }
      return {
        ...state,
        blueprintShowsDevices: _.omit(state.blueprintShowsDevices, blueprintId),
      };
    }


    // blueprint menu edits
    case actionTypes.BLUEPRINT_EDITS_BEGIN: {
      const {blueprint} = action.payload;
      const blueprintEdits = _.pick(blueprint, BLUEPRINT_SIMPLE_EDIT_PROPS);
      return {
        ...state,
        blueprintEdits: {
          ...state.blueprintEdits,
          [blueprint._id]: {
            ...state.blueprintEdits[blueprint._id],
            ...blueprintEdits,
          },
        },
      };
    }
    case actionTypes.BLUEPRINT_EDITS_END: {
      const {blueprintId} = action.payload;
      return {
        ...state,
        blueprintEdits: _.omit(state.blueprintEdits, blueprintId),
      };
    }
    case actionTypes.BLUEPRINT_EDITS_UPDATE: {
      const {blueprintId, blueprintEdits} = action.payload;
      return {
        ...state,
        blueprintEdits: {
          ...state.blueprintEdits,
          [blueprintId]: {
            ...state.blueprintEdits[blueprintId],
            ...blueprintEdits,
          },
        },
      };
    }

    // blueprint device adder section
    case actionTypes.BLUEPRINT_DEVICE_ADDER_UPDATE: {
      const {blueprintId, deviceSelection} = action.payload;
      if (!deviceSelection) {
        return {
          ...state,
          blueprintDeviceAdders: _.omit(state.blueprintDeviceAdders, blueprintId),
        };
      }
      return {
        ...state,
        blueprintDeviceAdders: {
          ...state.blueprintDeviceAdders,
          [blueprintId]: deviceSelection,
        },
      };
    }

    case actionTypes.BLUEPRINT_DEVICE_FILTER: {
      const {blueprintDeviceFilter} = action.payload;
      return {...state, blueprintDeviceFilter};
    }

    // deviceItem menu edits
    case actionTypes.DEVICE_ITEM_EDITS_BEGIN: {
      const {deviceItem} = action.payload;
      const deviceItemEdits = _.pick(deviceItem, DEVICE_ITEM_SIMPLE_EDIT_PROPS);
      return {
        ...state,
        deviceItemEdits: {
          ...state.deviceItemEdits,
          [deviceItem._id]: {
            ...state.blueprintEdits[deviceItem._id],
            ...deviceItemEdits,
          },
        },
      };
    }
    case actionTypes.DEVICE_ITEM_EDITS_END: {
      const {deviceItemId} = action.payload;
      return {
        ...state,
        deviceItemEdits: _.omit(state.deviceItemEdits, deviceItemId),
      };
    }
    case actionTypes.DEVICE_ITEM_EDITS_UPDATE: {
      const {deviceItemId, deviceItemEdits} = action.payload;
      return {
        ...state,
        deviceItemEdits: {
          ...state.deviceItemEdits,
          [deviceItemId]: {
            ...state.deviceItemEdits[deviceItemId],
            ...deviceItemEdits,
          },
        },
      };
    }

    case actionTypes.DEVICE_EXPAND: {
      const {deviceId} = action.payload;
      const expandedDevices = _.concat(state.expandedDevices, deviceId);
      return {
        ...state,
        expandedDevices,
      }
    }

    case actionTypes.DEVICE_COMPRESS: {
      const {deviceId} = action.payload;
      const expandedDevices = _.without(state.expandedDevices, deviceId);
      return {
        ...state,
        expandedDevices,
      }
    }

    // and that's all
    default: {
      return state;
    }
  }
};

export {
  actionTypes,
  actions,
  reduce,
};
