// location-menu-blueprint-section.view.js

// Modules
import {get, map, isEqual, truncate} from 'lodash';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import React from 'react';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import {Collapse} from 'reactstrap';

// local components
import DeviceItem from './device-item.view';
import {LOCATION_VIEWS, colors} from '../../views.constants';

import {
  ItemField,
  MenuSection,
  MenuSectionDescription,
  MenuSectionItem,
  MenuSectionLabel,
  MenuSubsectionInput,
  MenuSubsectionItem,
  MenuSubsectionSelect,
} from '../../views-menu.styled';
import {
  AddButton,
  CompressButton,
  EditButton,
  ExpandButton,
} from '../../views-menu-buttons.styled';
import {FlexWrapper} from '../../views.styled';
import {StyledLocationInfo, StyledLocationSubInfo} from '../../locations.view/locations.styled';
import {sizes, types} from './device-static-data';

// selectors and actions
import {
  createSelectorBlueprintAddableDevices,
  createSelectorBlueprintAddableDeviceSelection,
  createSelectorBlueprintNavLink,
  createSelectorCurrentLocationBlueprintFromId,
  selectAllFilteredLocationDevices,
  selectCurrentLocation,
} from '../location.selectors';
import {
  createSelectorBlueprintShowsDevices,
  createSelectorSimpleBlueprintEdits,
} from './location-menu.selectors';
import {actions as menuActions, actions as viewActions} from './location-menu.redux';
import {actions as locationActions} from '../location.redux';
import {actions as blueprintEditorActions} from '../blueprint-edit.view/blueprint-edit.redux';
import {actions as commonActions} from '../../views.redux';
import {ColFlexWrapper, DeviceSettingsContainer} from './location-menu.styled';
import {StyledSelectersWrapper} from '../location-blueprint.view/location-blueprint.styled';

const BlueprintSection = props => {
  const renderAddDeviceButton = () => props.blueprintDeviceSelection
    ? <AddButton
      onClick={() => {
        props.saveBlueprintDeviceAdder(
          props.currentLocation,
          props.blueprintId,
          props.blueprintDeviceSelection,
        );
        toast.success('Device added to blueprint');
      }}/>
    : <AddButton disabled/>;

  const renderDeviceAdderInput = () => (
    <MenuSubsectionSelect
      style={{margin: '0 0 5px 0'}}
      placeholder="Add device"
      value={props.blueprintDeviceSelection}
      options={props.addableDevices}
      onFocus={props.fetchDevices}
      onChange={deviceSelection => {
        props.updateBlueprintDeviceAdder(
          props.blueprintId,
          deviceSelection,
        );
      }}
    />
  );

  const renderDeviceAdder = () => (
    <StyledSelectersWrapper>
      {renderDeviceAdderInput()}
      {renderAddDeviceButton()}
    </StyledSelectersWrapper>
  );

  const renderDeviceTypeInput = () => (
    <MenuSubsectionSelect
      style={{margin: '0 0 5px 0'}}
      placeholder="Select type"
      value={props.blueprintDeviceSelection.type || 'default'}
      options={types}
      onChange={type => {
        props.updateBlueprintDeviceAdder(
          props.blueprintId,
          {...props.blueprintDeviceSelection, type},
        );
      }}
    />
  );

  const renderDeviceSizeInput = () => (
    <MenuSubsectionSelect
      placeholder="Select size"
      value={props.blueprintDeviceSelection.size || 'small'}
      options={sizes}
      onChange={size => {
        props.updateBlueprintDeviceAdder(
          props.blueprintId,
          {...props.blueprintDeviceSelection, size},
        );
      }}
    />
  );

  const renderDeviceSubsection = () => (
    <MenuSubsectionItem height={props.blueprintDeviceSelection && 180}>
      <ItemField style={{width: '100%'}}>
        <ColFlexWrapper>
          {renderDeviceAdder()}
          {props.blueprintDeviceSelection && (
            <DeviceSettingsContainer>
              {renderDeviceTypeInput()}
              {renderDeviceSizeInput()}
            </DeviceSettingsContainer>
          )}
        </ColFlexWrapper>
      </ItemField>
    </MenuSubsectionItem>
  );

  const renderDeviceFilterBox = () => (
    <MenuSubsectionItem>
      <MenuSubsectionInput
        width={'100%'}
        color={colors.GREY_DARK}
        placeholder="Filter devices..."
        value={props.blueprintDeviceFilter}
        onChange={evt => {
          props.filterBlueprintDevices(evt.target.value)
        }}
      />
    </MenuSubsectionItem>
  );

  const renderDevicesLabel = () => (
    <MenuSectionDescription
      description={'Devices'}
    />
  );

  const renderNumberOfDevices = () => !get(props.blueprint, 'items.length') && (
    <MenuSectionItem>
      <MenuSectionLabel label={'(no devices)'}/>
      <ItemField/>
    </MenuSectionItem>
  );

  const renderDevices = () => map(props.devices, deviceItem => (
    <DeviceItem key={deviceItem._id} deviceItem={deviceItem}/>
  ));

  const renderDevicesSubsection = () => (
    <Collapse isOpen={props.blueprintShowsDevices}>
      {renderDeviceSubsection()}
      {renderDeviceFilterBox()}
      {renderDevicesLabel()}
      {renderNumberOfDevices()}
      {renderDevices()}
    </Collapse>
  );

  const renderBlueprintSubsectionHeader = () => (
    <ItemField title={props.blueprint && props.blueprint.name && props.blueprint.name.length > 20 ? props.blueprint.name : null}>
      <StyledLocationInfo>
        {truncate(get(props.blueprint, 'name', 'Your Blueprint'), {length: 20})}
        <StyledLocationSubInfo>{props.blueprint.items.length} devices</StyledLocationSubInfo>
      </StyledLocationInfo>
    </ItemField>
  );

  const renderBlueprintButtonsSection = () => (
    <FlexWrapper>
      <EditButton onClick={evt => {
        evt.stopPropagation();
        props.history.push(props.blueprintNavLink);
        props.beginBlueprintEdits(props.blueprint);
        props.setView(LOCATION_VIEWS.blueprintEditor);
        props.toggleMenu();
      }}/>
      {props.blueprintShowsDevices
        ? <CompressButton onClick={() => {
          props.setBlueprintShowsDevices(props.blueprintId, false);
        }}/>
        : <ExpandButton onClick={() => {
          props.setBlueprintShowsDevices(props.blueprintId, true);
        }}/>
      }
    </FlexWrapper>
  );

  const renderBlueprintSubsection = () => (
    <MenuSectionItem isCurrentBlueprint={props.isCurrentBlueprint}>
      {renderBlueprintSubsectionHeader()}
      {renderBlueprintButtonsSection()}
    </MenuSectionItem>
  );

  const renderBlueprintSection = () => (
    <MenuSection
      style={{cursor: 'pointer'}}
      onClick={() => {
        props.setView(null);
        props.history.push(props.blueprintNavLink);
      }}
    >
      {renderBlueprintSubsection()}
      {renderDevicesSubsection()}
    </MenuSection>
  );

  return renderBlueprintSection();
};

// just documenting the fact that propTypes should be sent in
BlueprintSection.propTypes = {
  history: PropTypes.object.isRequired,
  blueprintId: PropTypes.string,
};

const makeMapStateToProps = () => {

  const selectSimpleBlueprintEdits = createSelectorSimpleBlueprintEdits();
  const selectBlueprintAddableDeviceSelection = createSelectorBlueprintAddableDeviceSelection();
  const selectBlueprintAddableDevices = createSelectorBlueprintAddableDevices();
  const selectBlueprint = createSelectorCurrentLocationBlueprintFromId();
  const selectBlueprintShowsDevices = createSelectorBlueprintShowsDevices();
  const selectBlueprintNavLink = createSelectorBlueprintNavLink();
  const selectIsCurrentBlueprint = createSelector(
    (state, props) => props.blueprintId,
    state => state.views.common.currentBlueprintId,
    (blueprintId, currentBlueprintId) => {
      return isEqual(blueprintId, currentBlueprintId);
    },
  );
  return (state, props) => ({
    currentLocation: selectCurrentLocation(state),
    blueprintEdits: selectSimpleBlueprintEdits(state, props),
    blueprintDeviceSelection: selectBlueprintAddableDeviceSelection(state, props),
    addableDevices: selectBlueprintAddableDevices(state, props),
    blueprint: selectBlueprint(state, props),
    devices: selectAllFilteredLocationDevices(state, props),
    isCurrentBlueprint: selectIsCurrentBlueprint(state, props),
    blueprintShowsDevices: selectBlueprintShowsDevices(state, props),
    blueprintNavLink: selectBlueprintNavLink(state, props),
    blueprintDeviceFilter: state.views.locationMenu.blueprintDeviceFilter,
  });
};

const mapDispatchToProps = dispatch => ({
  filterBlueprintDevices: filter => dispatch(viewActions.filterBlueprintDevices(filter)),
  toggleMenu: () => dispatch(commonActions.toggleMenu()),
  setBlueprintShowsDevices: (blueprintId, isExpanded) => (
    dispatch(viewActions.setBlueprintShowsDevices(blueprintId, isExpanded))
  ),
  beginBlueprintEdits: blueprint => dispatch(blueprintEditorActions.beginBlueprintEdits(blueprint)),
  setView: bool => dispatch(locationActions.setView(bool)),
  updateBlueprintDeviceAdder: (blueprintId, deviceSelection) => {
    dispatch(menuActions.updateBlueprintDeviceAdder(blueprintId, deviceSelection));
  },
  saveBlueprintDeviceAdder: (location, blueprintId, deviceSelection) => {
    dispatch(menuActions.saveBlueprintDeviceAdder(
      location,
      blueprintId,
      deviceSelection,
    ));
  },
  cancelBlueprintDeviceAdder: blueprintId => {
    dispatch(menuActions.cancelBlueprintDeviceAdder(blueprintId));
  },

});

export default connect(makeMapStateToProps, mapDispatchToProps)(BlueprintSection);
