// location-blueprint.js

// modules
import React from 'react';
import {connect} from 'react-redux';
import {isEqual, map, pick} from 'lodash';

// local components
import {
  unscaleCenteredCoord,
  getPannedGeometry,
  getZoomedGeometry,
} from './location-blueprint.utility';
import BlueprintSprite from './blueprint-sprite.view';
import DeviceItemSprite from './device-item-sprite.view';
import {BlueprintBackground, StyledDeviceRemovalDropZone} from './location-blueprint.styled';
import DevicePopup from './device-item-popup.view';

// actions & selectors
import {actions as blueprintActions} from './location-blueprint.redux';
import {
  selectCurrentLocation,
  selectCurrentBlueprintPlacedDeviceItems,
  selectAllCurrentLocationDevicesValues,
  selectAllCurrentLocationBlueprints,
} from '../location.selectors';
import {
  selectCurrentBlueprintGeometry,
} from './location-blueprint.selectors';
import {actions as menuActions} from '../location-menu.view/location-menu.redux';
import {actions as locationActions} from '../location.redux';
import {actions as viewsActions} from '../../views.redux';


const LocationBlueprint = props => (
  <BlueprintBackground
    boundingRect={props.backgroundDimensions}
    onResize={props.resizeBackground}
    onMouseUp={() => {
      // only stop moving the device item if we have one
      if (props.movingDeviceItem) {
        props.endMovingDeviceItem(props.movingDeviceItem._id);
      }
    }}
    onPan={panDetails => {
      if (!props.movingDeviceItem) {
        // pan the blueprint
        const coordinate = pick(panDetails, ['x', 'y']);
        const geometry = getPannedGeometry(coordinate, props.blueprintGeometry);
        props.setBlueprintGeometry(props.currentBlueprintId, geometry);
      } else {
        // or the movingDeviceItem
        const centeredPosition = pick(panDetails, ['top', 'left']);
        const geometry = props.blueprintGeometry;
        const unzoomedCoord = unscaleCenteredCoord(
          centeredPosition,
          geometry,
        );
        props.updateMovingDeviceItem(
          props.location,
          props.movingDeviceItem._id,
          {left: unzoomedCoord.x, top: unzoomedCoord.y},
          unzoomedCoord,
        );
      }
    }}
    onZoom={zoomDiff => {
      const geometry = getZoomedGeometry(zoomDiff, props.blueprintGeometry);
      props.setBlueprintGeometry(props.currentBlueprintId, geometry);
    }}
    scale={props.blueprintGeometry.scale}
  >

    <BlueprintSprite />
    {map(props.placedDeviceItems, deviceItem => (
      <div key={deviceItem._id}>
        <DeviceItemSprite
          key={deviceItem._id}
          deviceItem={deviceItem}
          values={props.currentLocationDevices[deviceItem.deviceId]}
        />
        {isEqual(props.popupDeviceId, deviceItem.deviceId) &&
          <DevicePopup
            parentDimensions={props.backgroundDimensions}
            geometry={props.blueprintGeometry}
            deviceItem={deviceItem}
            expandDevice={props.expandDevice}
            currentBlueprintId={props.currentBlueprintId}
            setBlueprintShowsDevices={props.setBlueprintShowsDevices}
            toggleLocationMenu={props.toggleLocationMenu}
            values={props.currentLocationDevices[props.popupDeviceId]}
            closeDevicePopup={props.closeDevicePopup}
            showValueDisplay={props.showValueDisplay}
            currentLocation={props.location}
          />}
      </div>
    ))}
    {props.isInPlacementMode &&
      <StyledDeviceRemovalDropZone
        deviceItemGrabbed={!!props.movingDeviceItem}
        onMouseUp={() => {
          props.deleteBlueprintDeviceItem(
            props.location,
            props.movingDeviceItem._id
          );
        }}
      >
        DROP DEVICE HERE TO REMOVE FROM BLUEPRINT
      </StyledDeviceRemovalDropZone>
    }
  </BlueprintBackground>
);


//
// state stuff
//

const mapStateToProps = (state, props) => ({
  isInPlacementMode: state.views.location.isInDevicePlacementMode,
  currentBlueprintId: state.views.common.currentBlueprintId,
  backgroundDimensions: state.views.locationBlueprint.backgroundDimensions,
  movingDeviceItem: state.views.locationBlueprint.movingDeviceItem,
  location: selectCurrentLocation(state),
  blueprintGeometry: selectCurrentBlueprintGeometry(state),
  // placedDeviceItems --> merges in movingDeviceItem if possible
  placedDeviceItems: selectCurrentBlueprintPlacedDeviceItems(state),
  popupDeviceId: state.views.locationBlueprint.popupDeviceId,
  currentLocationDevices: selectAllCurrentLocationDevicesValues(state),
  layers: selectAllCurrentLocationBlueprints(state),
});

const mapDispatchToProps = dispatch => ({
  closeDevicePopup: () => dispatch(blueprintActions.closeDevicePopup()),
  resizeBackground: rect => {
    dispatch(blueprintActions.resizeBackground(rect));
  },
  setBlueprintGeometry: (blueprintId, geometry) => {
    dispatch(blueprintActions.setBlueprintGeometry(blueprintId, geometry));
  },
  updateMovingDeviceItem: (location, deviceItemId, updates) => {
    dispatch(blueprintActions.updateMovingDeviceItem(location, deviceItemId, updates));
  },
  endMovingDeviceItem: deviceItemId => {
    dispatch(blueprintActions.endMovingDeviceItem(deviceItemId));
  },
  showValueDisplay: (location, deviceItemId, measurement) => {
    dispatch(blueprintActions.showValueDisplay(location, deviceItemId, {valueDisplay: measurement}));
  },
  expandDevice: deviceId => {
    dispatch(menuActions.expandDevice(deviceId));
  },
  toggleLocationMenu: bool => (
    dispatch(locationActions.toggleLocationMenu(bool))
  ),
  setBlueprintShowsDevices: (id, bool) => (
    dispatch(menuActions.setBlueprintShowsDevices(id, bool))
  ),
  setCurrentBlueprintId: blueprintId => dispatch(viewsActions.setCurrentBlueprintId(blueprintId)),
  deleteBlueprintDeviceItem: (location, deviceItemId) => {
    dispatch(menuActions.deleteBlueprintDeviceItem(location, deviceItemId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationBlueprint);
