// locations.view.js

// Modules
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import {Marker, Popup} from 'react-leaflet';
import {Link} from 'react-router-dom';
import {get, isEqual} from 'lodash/fp';
import {truncate} from 'lodash';

// local components
import {
  MapItem,
  MapItemHeader,
  MapItemFooter,
  MapButton,
  getLocationIcon,
  StyledTooltip,
} from './locations-map.styled';
import {getLocationIconUrl} from '../../views.utils';

// actions & selectors
import {
  createSelectorLocation,
  createSelectorTotalNumDevicesInLocation,
} from '../locations.selectors';
import {selectLocationAverageTemp, selectLocationStatusColor} from './locations-map.selectors';
import {createLocationUrl} from '../../views.routes';
import {actions as viewActions} from './locations-map.redux';
import {actions as locationsActions} from '../locations.redux';

// Add a layer of logic to enable external control of marker popup
// using leaflet element instance methods
const LocationMarkerWrapper = props => {
  const matchesPopup = isEqual(props.location._id, props.popupLocationId);
  const ref = React.createRef();
  useEffect(() => {
    if (!!ref.current.leafletElement) {
      const marker = ref.current.leafletElement;

      if (!!marker.dragging) {
        if (props.isInPlacementMode) {
          marker.dragging.enable();
        } else {
          marker.dragging.disable();
        }
      }

      const popupIsOpen = marker.isPopupOpen();
      // check if we should open popup
      if (matchesPopup && !popupIsOpen) {
        marker.openPopup();
      }
      // or close
      if (!matchesPopup && popupIsOpen) {
        marker.closePopup();
      }
    }
  });

  return (
    <LocationMarker
      ref={ref}
      {...props}
    />
  );
};

const LocationMarker = React.forwardRef((props, ref) => (
  <Marker
    ref={ref}
    key={props.location._id}
    position={props.movingMarker || props.location}
    icon={props.locationIcon}
    onPopupOpen={() => {
      props.setPopupLocationId(props.location._id);
    }}
    onPopupClose={() => {
      if (isEqual(props.popupLocationId, props.location._id)) {
        props.setPopupLocationId(null);
      }
    }}
    onDragStart={() => {
      props.selectMovingMarker(props.location);
    }}
    onDrag={evt => {
      props.moveMovingMarker(props.location, evt.latlng);
    }}
    onDragEnd={() => {
      const latlng = {lat: props.movingMarker.lat, lng: props.movingMarker.lng};
      props.cancelMovingMarker(props.location, latlng);
      // Temp. hack to solve marker movement bug
      props.setLocationPlacementMode(false);
      props.setLocationPlacementMode(true);
    }}
  >
    <LocationPopup
      location={props.location}
      totalNumDevices={props.totalNumDevices}
    />
    <StyledTooltip
      direction='right'
      offset={[15, 0]}
      permanent
    >
      {!!props.locationAverageTemp && props.locationAverageTemp.toFixed(1) + '℃'}
    </StyledTooltip>
  </Marker>
));

const LocationPopup = ({location, totalNumDevices}) => (
  <Popup>
    <MapItem>
      <MapItemHeader title={location.name.length > 20 ? location.name : null}>
        <b>{truncate(location.name, {length: 20})}</b>
        {`Devices: ${totalNumDevices}`}
      </MapItemHeader>
      <MapItemFooter>
        <Link to={createLocationUrl(location.name)} >
          <MapButton>
            {'Go to location'}
          </MapButton>
        </Link>
      </MapItemFooter>
    </MapItem>
  </Popup>
);

//
// state & redux
//

// account for the props passed in
LocationMarkerWrapper.propsTypes = {
  locationId: PropTypes.string,
};

const createSelectorMatchingMovingMarker = () => createSelector(
  (state, props) => props.locationId,
  state => state.views.locationsMap.movingMarker,
  (locationId, movingMarker) => {
    const movingMarkerId = get('locationId', movingMarker);
    if (isEqual(movingMarkerId, locationId)) {
      return movingMarker;
    }
    return null;
  }
);

const createSelectorLocationIcon = () => createSelector(
  createSelectorLocation(),
  selectLocationStatusColor(),
  (location, color) => {
    const locationColor = color ? color[location._id] : void(0);
    const iconUrl = getLocationIconUrl(location.icon);
    return getLocationIcon(iconUrl, locationColor);
  },
);

const makeMapStateToProps = () => {
  const selectLocation = createSelectorLocation();
  const selectTotalNumDevicesInLocation = createSelectorTotalNumDevicesInLocation();
  const selectMatchingMovingMarker = createSelectorMatchingMovingMarker();
  const selectLocationIcon = createSelectorLocationIcon();
  const selectStatusColor = selectLocationStatusColor();
  return (state, props) => ({
    location: selectLocation(state, props),
    locationIcon: selectLocationIcon(state, props),
    locationAverageTemp: selectLocationAverageTemp(state, props),
    locationStatusColor: selectStatusColor(state, props),
    totalNumDevices: selectTotalNumDevicesInLocation(state, props),
    movingMarker: selectMatchingMovingMarker(state, props),
    popupLocationId: state.views.locationsMap.popupLocationId,
    isInPlacementMode: state.views.locations.isInPlacementMode,
  });
};

const mapDispatchToProps = dispatch => ({
  selectMovingMarker: location => {
    dispatch(viewActions.selectMovingMarker(location));
  },
  cancelMovingMarker: (location, latlng) => {
    dispatch(viewActions.cancelMovingMarker(location, latlng));
  },
  moveMovingMarker: (location, latlng) => {
    dispatch(viewActions.moveMovingMarker(location, latlng));
  },
  setPopupLocationId: locationId => {
    dispatch(viewActions.setPopupLocationId(locationId));
  },
  setLocationPlacementMode: bool => dispatch(locationsActions.setLocationPlacementMode(bool)),
});

// /////
// /// Export
// /////

export default connect(makeMapStateToProps, mapDispatchToProps)(LocationMarkerWrapper);
