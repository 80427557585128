import _ from 'lodash';
import {createSelector} from 'reselect';
import moment from 'moment';

import {timePeriods, timePeriodTypes} from './chart.constants';
import {knownMeasurements} from '../views.constants';

// Choose the amount of decimals shown in the tooltip
const getNumDecimals = deviceStats => {
  let maxValue = -Infinity;
  _.forEach(deviceStats, device => {
    if (_.isNull(device.value)) return;
    if (Math.abs(device.value) > maxValue) maxValue = Math.abs(device.value);
  });
  if (maxValue === 0) return 2;
  // ex: ..., 1000 => 0, 100 => 0, 10 => 1, 1 => 2, 0.1 => 3, 0.01 => 4, ...
  return Math.max(0, 2 - Math.floor(Math.log10(Math.abs(maxValue))));
};

const selectDeviceStatistics = createSelector(
  state => state.statistics.deviceStats,
  deviceStats => {
    const numDecimals = getNumDecimals(deviceStats);
    const roundMultiplier = 10 ** numDecimals;
    return _.map(deviceStats, point => {
      const roundedValue = Math.round(point.value * roundMultiplier) / roundMultiplier;
      return {
        ...point,
        unixTimestamp: moment(point.time).unix(),
        formattedTime: moment(point.time).format('YYYY-MM-DD HH:mm:ss'),
        roundedValue: roundedValue.toFixed(numDecimals),
      };
    });
  }
);

const selectChartTicks = createSelector(
  state => state.views.chart.selectedTimePeriod,
  selectedTimePeriodType => {
    if (selectedTimePeriodType === timePeriodTypes.CUSTOM) {
      return null;
    }
    const now = moment().startOf(timePeriods[selectedTimePeriodType].ticks.lastTickStartOf).unix();
    return _.times(timePeriods[selectedTimePeriodType].ticks.count, n => {
      return now - ((timePeriods[selectedTimePeriodType].ticks.interval) * (timePeriods[selectedTimePeriodType].ticks.count - n - 1));
    });
  }
);

const selectChoosableMeasurements = createSelector(
  state => _.get(state, 'statistics.deviceStatsFields', []),
  measurementKeys => {
    return _.reduce(measurementKeys, (acc, val) => {
      const measurement = _.has(knownMeasurements, val) ? knownMeasurements[val] : {};
      return [...acc, {type: val, name: val, ...measurement}]
    }, []);
  }
);

const getTimeValidationFeedback = time => {
  if (!time) return '';
  if (moment(time.trim(), "YYYY-MM-DD HH:mm", true).isValid()) {
    return '';
  }
  return 'Invalid. Use yyyy-mm-dd hh:mm format.';
};

const selectCustomTimePeriodFromFeedback = createSelector(
  state => state.views.chart.customTimePeriodFromTime,
  time => getTimeValidationFeedback(time)
);

const selectCustomTimePeriodToFeedback = createSelector(
  state => state.views.chart.customTimePeriodToTime,
  state => state.views.chart.customTimePeriodFromTime,
  (toTime, fromTime) => {
    const toTimeFeedback = getTimeValidationFeedback(toTime);
    const fromTimeFeedback = getTimeValidationFeedback(fromTime);
    if (!toTimeFeedback && !!toTime) {
      if (!fromTimeFeedback && !!fromTime) {
        if (moment(toTime).isSameOrBefore(fromTime)) {
          return 'Must be greater than From';
        }
      }
      return '';
    }
    return toTimeFeedback;
  }
);

const selectNoDataMessage = createSelector(
  state => state.statistics.deviceStats,
  state => state.views.chart.selectedTimePeriod,
  state => state.views.chart.customTimePeriodAppliedFromTime,
  state => state.views.chart.customTimePeriodAppliedToTime,
  (deviceStats, selectedTimePeriod, customTimePeriodAppliedFromTime, customTimePeriodAppliedToTime) => {
    const noSetTime = selectedTimePeriod === timePeriodTypes.CUSTOM &&
    (!customTimePeriodAppliedFromTime || !customTimePeriodAppliedToTime);
    if (noSetTime) return 'Please specify the custom time period';
    if (!_.size(deviceStats)) return 'No data for selected time period';
    return '';
  }
);

const selectChartDomain = createSelector(
  state => state.views.chart.selectedTimePeriod,
  state => state.views.chart.customTimePeriodAppliedFromTime,
  state => state.views.chart.customTimePeriodAppliedToTime,
  (selectedTimePeriod, customTimePeriodAppliedFromTime, customTimePeriodAppliedToTime) => {
    if (selectedTimePeriod === timePeriodTypes.CUSTOM) {
      const fromUnix = moment(customTimePeriodAppliedFromTime, 'YYYY-MM-D HH:mm:ss').unix();
      const toUnix = moment(customTimePeriodAppliedToTime, 'YYYY-MM-D HH:mm:ss').unix();
      return [fromUnix, toUnix];
    }
    return [moment().unix()-timePeriods[selectedTimePeriod].time/1000, moment().unix()];
  }
);

export {
  selectDeviceStatistics,
  selectChartTicks,
  selectChoosableMeasurements,
  selectCustomTimePeriodFromFeedback,
  selectCustomTimePeriodToFeedback,
  selectNoDataMessage,
  selectChartDomain,
};
