// locations-map.redux.js

import _ from 'lodash';
import {actions as locationsActions} from '../../../state/locations.redux';
import {
  LOCATION_SIMPLE_EDIT_PROPS,
  editLocationDetails,
} from '../../../state/location-editor.utility';

const actionTypes = {
  LOCATIONS_UPDATE_VIEWPORT: 'LOCATIONS_UPDATE_VIEWPORT',
  LOCATIONS_SET_POPUP_ID: 'LOCATIONS_SET_POPUP_ID',
  MAP_MARKER_SELECT: 'MAP_MARKER_SELECT',
  MAP_MARKER_CANCEL: 'MAP_MARKER_CANCEL',
  MAP_MARKER_MOVE: 'MAP_MARKER_MOVE',
  MAP_INITIAL_ZOOM_SET: 'MAP_INITIAL_ZOOM_SET',
  SET_CONTEXT_MENU: 'SET_CONTEXT_MENU',
};


// ////
// Rest of the actions
// ////
const actions = {
  updateViewPort: viewPort => ({
    type: actionTypes.LOCATIONS_UPDATE_VIEWPORT,
    payload: {viewPort},
  }),
  setPopupLocationId: popupLocationId => ({
    type: actionTypes.LOCATIONS_SET_POPUP_ID,
    payload: {popupLocationId},
  }),
  setInitialZoom: initialZoom => ({
    type: actionTypes.MAP_INITIAL_ZOOM_SET,
    payload: {initialZoom},
  }),
  // map marker movement
  selectMovingMarker: location => ({
    type: actionTypes.MAP_MARKER_SELECT,
    payload: {
      locationId: location._id,
      latlng: _.pick(location, LOCATION_SIMPLE_EDIT_PROPS),
    },
  }),
  cancelMovingMarker: (location, latlng) => dispatch => {
    const modified = editLocationDetails(location, latlng);
    return dispatch(locationsActions.updateLocation(modified));
  },
  moveMovingMarker: (location, latlng) => ({
    type: actionTypes.MAP_MARKER_MOVE,
    payload: {
      locationId: location._id,
      latlng,
    }
  }),
  setContextMenu: contextMenu => ({
    type: actionTypes.SET_CONTEXT_MENU,
    payload: {contextMenu},
  }),
};

const defaultState = {
  popupLocationId: null,
  movingMarker: null,
  viewPort: null,
  initialZoom: null,
  contextMenu: null,
};

const reduce = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.LOCATIONS_UPDATE_VIEWPORT: {
      const {viewPort} = action.payload;
      return {...state, viewPort: {...viewPort}};
    }
    case actionTypes.LOCATIONS_SET_POPUP_ID: {
      const {popupLocationId} = action.payload;
      return {...state, popupLocationId};
    }

    case actionTypes.MAP_INITIAL_ZOOM_SET: {
      const {initialZoom} = action.payload;
      return {...state, initialZoom};
    }
    //
    //  Map marker
    //
    case actionTypes.MAP_MARKER_SELECT: {
      const {locationId, latlng} = action.payload;
      const movingMarker = {
        locationId,
        ...latlng,
      };
      return {...state, movingMarker};
    }

    case actionTypes.MAP_MARKER_MOVE: {
      const {locationId, latlng} = action.payload;
      const movingMarker = {
        locationId,
        ...latlng,
      }
      return {...state, movingMarker};
    }

    case actionTypes.MAP_MARKER_CANCEL: {
      return {...state, movingMarker: null};
    }

    case actionTypes.SET_CONTEXT_MENU: {
      const {contextMenu} = action.payload;
      return {...state, contextMenu};
    }
    //
    // and finally the default
    //
    default: {
      return state;
    }
  }
};

export {
  actionTypes,
  actions,
  reduce,
};
