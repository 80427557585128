// location-edit.styled.js

import styled from 'styled-components';
import {colors, NAVBAR_HEIGHT} from '../../views.constants';

const StyledLocationEditView = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  z-index: 9999;
  margin-top: ${NAVBAR_HEIGHT};
  height: calc(100% - ${NAVBAR_HEIGHT});
  width: 100%;
  background: ${colors.GREY_LIGHT_ALT}
  color: ${colors.GREY_DARK};
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 60%;
  
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const StyledIconContainer = styled.div`
  display: flex;
  width: 60%;
  align-items: center;
`;

const StyledIcon = styled.img`
  width: 40px;
  height: 40px;
`;

export {
  StyledLocationEditView,
  StyledButtonsContainer,
  StyledIconContainer,
  StyledIcon,
}
