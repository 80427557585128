// locations-list.js

// Modules
import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import _ from 'lodash';
import Pagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import 'rc-pagination/assets/index.css';

// local components
import LocationsItem from './locations-item.view';
import {
  Menu,
  MenuHeader,
  MenuSection,
  MenuSubsectionInput,
} from '../../views-menu.styled';
import {colors} from '../../views.constants';
import {GreyButton} from '../../views-buttons';
import {routes} from '../../views.routes';

// actions & selectors
import {actions as locationsActions} from '../locations.redux';
import {actions as viewActions} from './locations-menu.redux';
import {
  selectPage,
  selectPageSize,
  selectEdits,
  selectTotalNumLocations,
  selectPaginatedLocations
} from './locations-menu.selectors';
import {selectLocationDevices} from '../locations.selectors';

const LocationsList = props => (
  <Menu isOpen={props.showMenu} >
    <MenuHeader>
      <label>{'Locations'}</label>

    </MenuHeader>

    <GreyButton
      onClick={() => props.history.push(routes.CREATE)}
      margin={'30px 5px 5px 5px'}
      width={'120px'}
      data-cy="new-location-btn"
    >
      + New location
    </GreyButton>

    <MenuSection>
      <div>
        <MenuSubsectionInput
          maxWidth
          width={'100%'}
          value={props.locationsFilterText}
          background={colors.GREY_DARK}
          onChange={({target: {value}}) => {
            props.setPage(0);
            props.setLocationsFilterText(value);
          }}
          placeholder={'Filter by name...'}
          data-cy="lm-location-filter"
        />
      </div>

      {_.map(props.locations, location => (
        <React.Fragment key={location._id}>
          <LocationsItem
            locationId={location._id}
            isEditing={!!props.edits[location._id]}
          />

        </React.Fragment>
      ))}

      <Pagination
        onChange={page => props.setPage(page - 1)}
        current={props.page + 1}
        pageSize={props.pageSize}
        hideOnSinglePage={false}
        total={props.totalNumLocations}
        style={{
          marginTop: 8,
          marginBottom: 4,
        }}
        locale={localeInfo}
      />

    </MenuSection>
  </Menu>
);

// ///
//
// / Redux
//
// ///

const mapStateToProps = state => ({
  showMenu: state.views.common.showMenu,
  edits: selectEdits(state),
  page: selectPage(state),
  pageSize: selectPageSize(state),
  totalNumLocations: selectTotalNumLocations(state),
  locations: selectPaginatedLocations(state),
  locationsFilterText: state.views.locationsMenu.locationsFilterText,
  devices: selectLocationDevices(state),
});

const mapDispatchToProps = dispatch => ({
  setPage: page => dispatch(viewActions.setPage(page)),
  toggleLocationsMenu: () => dispatch(locationsActions.toggleLocationsMenu()),
  setLocationsFilterText: filterText => dispatch(viewActions.setLocationsFilterText(filterText)),
});

// /////
//
// /// Export
//
// /////

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationsList));
