// device-item-sprite.view.js

// modules
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import {find, some, toString} from 'lodash';

// local stuff
import {DeviceFiller, DeviceItemSprite} from './location-blueprint.styled';

// actions & selectors
import {actions} from '../../views.redux';
import {actions as blueprintActions} from './location-blueprint.redux';
import {selectCurrentBlueprintGeometry} from './location-blueprint.selectors';
import {selectCurrentLocation} from '../location.selectors';
import {actions as menuActions} from '../location-menu.view/location-menu.redux';

const DeviceItemSpriteWrapper = props => {
  // delete device from blueprint if it doesnt not exist
  useEffect(() => {
    if (!props.devices.fetching && props.deviceItem.deviceId) {
      const deviceExists = some(props.devices, ['_id', props.deviceItem.deviceId]);
      if (!deviceExists) {
        props.deleteBlueprintDeviceItem(
          props.currentLocation,
          props.deviceItem._id
        );
      }
    }
  });

  const getValueDisplayText = () => {
    if (!props.deviceItem.valueDisplay) {
      return '';
    }
    const displayValue = find(props.values, ['type', props.deviceItem.valueDisplay]);
    if (displayValue) {
      const val = toString(displayValue.value);
      return displayValue.unit ? `${val} ${displayValue.unit}` : val;
    }
    return '';
  };

  const level = find(props.values, val => val.type === 'level');
  const levelValue = level && Math.ceil(level?.value * 100) + '%';

  const renderLevelValue = () => props.deviceItem.type === 'silo' && <div style={{zIndex: '1000'}}>{levelValue}</div>;

  const renderDeviceFiller = () => (
    <DeviceFiller
      values={props.values}
      deviceItem={props.deviceItem}
    />
  );

  const renderDeviceItemSprite = () => (
    <DeviceItemSprite
      deviceItem={props.deviceItem}
      values={props.values}
      valueDisplayText={getValueDisplayText(props)}
      parentDimensions={props.backgroundDimensions}
      geometry={props.blueprintGeometry}
      isMovable={props.isInDevicePlacementMode}
      isGrabbed={props.movingDeviceItem}
      onMouseDown={() => {
        if (props.isInDevicePlacementMode) {
          props.beginMovingDeviceItem(props.deviceItem);
        }
      }}
      onTouchStart={() => {
        if (props.isInDevicePlacementMode) {
          props.beginMovingDeviceItem(props.deviceItem);
        }
      }}
      onClick={() => {
        if (!props.isInDevicePlacementMode) {
          props.openDevicePopup(props.deviceItem.deviceId);
        }
      }}
    >
      {renderLevelValue()}
      {renderDeviceFiller()}
    </DeviceItemSprite>
  );

  return renderDeviceItemSprite();
};

//
// state
//

// gets passed in from above
DeviceItemSpriteWrapper.propTypes = {
  deviceItem: PropTypes.object,
  values: PropTypes.array,
};

// and a local selector -- it is sooo specific..
const selectMoverMatches = createSelector(
  (state, props) => props.deviceItem,
  state => state.views.locationBlueprint.movingDeviceItem || {},
  (deviceItem, movingItem) => deviceItem._id === movingItem._id,
);

const mapStateToProps = (state, props) => ({
  devices: state.devices,
  currentLocation: selectCurrentLocation(state),
  blueprintId: state.views.common.currentBlueprintId,
  backgroundDimensions: state.views.locationBlueprint.backgroundDimensions,
  movingDeviceItem: state.views.locationBlueprint.movingDeviceItem,
  isInDevicePlacementMode: state.views.location.isInDevicePlacementMode,
  moverMatches: selectMoverMatches(state, props),
  blueprintGeometry: selectCurrentBlueprintGeometry(state),
});

const mapDispatchToProps = dispatch => ({
  openDevicePopup: deviceId => dispatch(blueprintActions.openDevicePopup(deviceId)),
  reportImageSize: (imageSrc, size) => dispatch(actions.reportImageSize(imageSrc, size)),
  beginMovingDeviceItem: deviceItemId => (
    dispatch(blueprintActions.beginMovingDeviceItem(deviceItemId))
  ),
  endMovingDeviceItem: deviceItemId => dispatch(blueprintActions.endMovingDeviceItem(deviceItemId)),
  deleteBlueprintDeviceItem: (location, deviceItemId) => {
    dispatch(menuActions.deleteBlueprintDeviceItem(location, deviceItemId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceItemSpriteWrapper);
