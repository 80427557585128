// app.js

import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {eq} from 'lodash/fp';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import {ModalProvider} from 'styled-react-modal';
import {ToastContainer} from 'react-toastify';

// local views
import {SessionChecker} from '../components';
import Location from './location.view';
import NavBar from './navbar.view';
import Create from './create.view';
import ImageManager from './image-manager.view';
import LocationsDialog from './locations-dialog.view';
import BlueprintDialog from './blueprints-dialog.view';
import Locations from './locations.view';
import Login from './login.view';
import Chart from './chart.view';
import {Wrapper} from './views.styled';

// actions & selectors
import {actions as sessionActions} from '../state/session.redux';
import {routes} from './views.routes';
import config from '../config';
import {actions as locationsActions} from '../state/locations.redux';
import {actions as deviceActions} from '../state/devices.redux';

const Views = props => {
  useEffect(() => {
    if (!eq(window.location.pathname, routes.LOGIN)) {
      props.fetchLocations();
    }
  });

  useEffect(() => {
    if (!eq(window.location.pathname, routes.LOGIN)) {
      props.fetchDevices();
    }
  });

  return (
    <Wrapper>
      <Router>
        <>
          <ToastContainer position={config.toaster.position}/>
          <ModalProvider>
            <LocationsDialog/>
            <BlueprintDialog/>
            <Switch>
              <Route exact path="/">
                <Redirect to={routes.LOCATIONS}/>
              </Route>
              <Route exact path={routes.LOGIN} component={Login}/>
              <SessionChecker
                session={props.session}
                setSession={props.setSession}
              >
                <NavBar/>
                <Route exact path={routes.CREATE} component={Create}/>
                <Route path={routes.IMAGE_MANAGER} component={ImageManager}/>
                <Route exact path={routes.LOCATIONS} component={Locations}/>
                <Route exact path={routes.LOCATION} component={Location}/>
                <Route exact path={routes.BLUEPRINT} component={Location}/>
                <Route exact path={routes.CHART} component={Chart}/>
              </SessionChecker>
            </Switch>
          </ModalProvider>
        </>
      </Router>
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  session: state.session,
});

const mapDispatchToProps = dispatch => ({
  setSession: session => {
    dispatch(sessionActions.setSession(session));
  },
  fetchLocations: () => dispatch(locationsActions.fetchLocations()),
  fetchDevices: () => dispatch(deviceActions.fetchDevices()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Views);
