// location-buttons-layer.view.js

// Modules
import styled from 'styled-components';
import {LocationContainerLayer} from '../location.styled';

const BlueprintButtonsLayer = styled(LocationContainerLayer)`
  display: flex;
  pointer-events: none;
`;

export {
  BlueprintButtonsLayer,
};
