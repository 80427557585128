// location-blueprint.redux.js

import _ from 'lodash';
import delay from 'delay';

import {
  DEVICE_ITEM_SIMPLE_EDIT_PROPS,
  editDeviceItemDetails,
} from '../../../state/location-editor.utility';
import {actions as locationActions} from '../../../state/locations.redux';

const actionTypes = {
  // size listener effectively
  XYZ_BLUEPRINT_RESIZE_BG: 'XYZ_BLUEPRINT_RESIZE_BG',
  // geometry
  XYZ_BLUEPRINT_SET_GEOMETRY: 'XYZ_BLUEPRINT_SET_GEOMETRY',
  XYZ_BLUEPRINT_RESET_GEOMETRY: 'XYZ_BLUEPRINT_RESET_GEOMETRY',
  // moving device
  XYZ_BLUEPRINT_BEGIN_MOVING_DEVICE: 'XYZ_BLUEPRINT_BEGIN_MOVING_DEVICE',
  XYZ_BLUEPRINT_END_MOVING_DEVICE: 'XYZ_BLUEPRINT_END_MOVING_DEVICE',
  XYZ_BLUEPRINT_UPDATE_MOVING_DEVICE: 'XYZ_BLUEPRINT_UPDATE_MOVING_DEVICE',
  DEVICE_OPEN_POPUP: 'DEVICE_OPEN_POPUP',
  DEVICE_CLOSE_POPUP: 'DEVICE_CLOSE_POPUP',
  SHOW_VALUE_DISPLAY: 'SHOW_VALUE_DISPLAY',
};

const actions = {
  // should be moved up?
  resizeBackground: rect => ({
    type: actionTypes.XYZ_BLUEPRINT_RESIZE_BG,
    payload: {rect},
  }),
  // in general use
  setBlueprintGeometry: (blueprintId, geometry) => ({
    type: actionTypes.XYZ_BLUEPRINT_SET_GEOMETRY,
    payload: {
      blueprintId,
      geometry: _.pick(geometry, 'x', 'y', 'scale'),
    },
  }),
  // used by blueprint reset button
  resetBlueprintGeometry: blueprintId => ({
    type: actionTypes.XYZ_BLUEPRINT_RESET_GEOMETRY,
    payload: {blueprintId},
  }),
  // moving deviceItem stuff
  beginMovingDeviceItem: deviceItem => ({
    type: actionTypes.XYZ_BLUEPRINT_BEGIN_MOVING_DEVICE,
    payload: {deviceItem},
  }),
  endMovingDeviceItem: deviceItemId => dispatch => (
    // run a dirty little wait, in case position is being saved
    delay(100)
      .then(() => dispatch({
        type: actionTypes.XYZ_BLUEPRINT_END_MOVING_DEVICE,
        payload: {deviceItemId},
      }))
  ),
  updateMovingDeviceItem: (location, deviceItemId, updates) => dispatch => {
    dispatch({
      type: actionTypes.XYZ_BLUEPRINT_UPDATE_MOVING_DEVICE,
      payload: {updates},
    });
    const modifiedLocation = editDeviceItemDetails(location, deviceItemId, updates);
    return dispatch(locationActions.updateLocation(modifiedLocation));
  },
  showValueDisplay: (location, deviceItemId, updates) => dispatch => {
    const modifiedLocation = editDeviceItemDetails(location, deviceItemId, updates);
    return dispatch(locationActions.updateLocation(modifiedLocation));
  },
  openDevicePopup: popupDeviceId => ({
    type: actionTypes.DEVICE_OPEN_POPUP,
    payload: {popupDeviceId},
  }),
  closeDevicePopup: () => ({
    type: actionTypes.DEVICE_CLOSE_POPUP,
  }),
};

const defaultState = {
  // backgroundDimensions: the actual dimensions of the physical background area
  backgroundDimensions: {width: 0, height: 0, left: 0, top: 0},
  // blueprintGeometries: keep track of pan & zoom info for each blueprint
  blueprintGeometries: {},
  // moving deviceItem (There can be only one. seriously.)
  movingDeviceItem: null,
  popupDeviceId: null,
};

const reduce = (state = defaultState, action) => {
  switch (action.type) {

    case actionTypes.XYZ_BLUEPRINT_RESIZE_BG: {
      const {rect} = action.payload;
      return {
        ...state,
        backgroundDimensions: _.pick(rect, ['width', 'height', 'top', 'left']),
      };
    }

    case actionTypes.XYZ_BLUEPRINT_SET_GEOMETRY: {
      const {blueprintId, geometry} = action.payload;
      const nextState = {
        ...state,
        blueprintGeometries: {
          ...state.blueprintGeometries,
          [blueprintId]: {
            ..._.get(state.blueprintGeometries, blueprintId, {}),
            ...geometry,
          },
        },
      };
      return nextState;
    }

    case actionTypes.XYZ_BLUEPRINT_RESET_GEOMETRY: {
      const {blueprintId} = action.payload;
      const nextState = {
        ...state,
        blueprintGeometries: _.omit(state.blueprintGeometries, [blueprintId]),
      };
      return nextState;
    }

    case actionTypes.XYZ_BLUEPRINT_BEGIN_MOVING_DEVICE: {
      const {deviceItem} = action.payload;
      const nextState = {
        ...state,
        movingDeviceItem: deviceItem,
      };
      return nextState;
    }

    case actionTypes.XYZ_BLUEPRINT_END_MOVING_DEVICE: {
      const {deviceItemId} = action.payload;
      const currId = _.get(state.movingDeviceItem, '_id');
      if (currId !== deviceItemId) {
        return state;
      }
      return {...state, movingDeviceItem: null};
    }

    case actionTypes.XYZ_BLUEPRINT_UPDATE_MOVING_DEVICE: {
      const {updates} = action.payload;
      if (!state.movingDeviceItem) {
        return state;
      }
      return {
        ...state,
        movingDeviceItem: {
          ...state.movingDeviceItem,
          ..._.pick(updates, DEVICE_ITEM_SIMPLE_EDIT_PROPS),
        },
      };
    }

    case actionTypes.DEVICE_OPEN_POPUP: {
      const {popupDeviceId} = action.payload;
      return {...state, popupDeviceId};
    }

    case actionTypes.DEVICE_CLOSE_POPUP: {
      return {...state, popupDeviceId: null};
    }

    default:
      return state;
  }
};

export {
  actions,
  reduce,
};
