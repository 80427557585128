// image-manager-section.view

// Modules
import React from 'react';
import {connect} from 'react-redux';
import {Icon} from 'react-icons-kit';
import {arrowCircleOUp as arrowUpIcon} from 'react-icons-kit/fa/arrowCircleOUp';
import _ from 'lodash';

// local components
import {
  StyledEditImageRow,
  StyledEditLabel,
  StyledImageManager,
  StyledImageToManage,
  StyledInput,
} from './image-manager.styled';
import {
  extractFullNameFromImageUrl,
  extractNameFromImageUrl,
} from './image-manager.utils';
import {CancelButton, DeleteButton, SaveButton} from '../views-buttons';
import {getFullImageUrl} from '../views.utils';

// Actions and selectors
import {actions as viewActions} from './image-manager.redux';

const ImageManagerSection = props => (
  <StyledImageManager>
    {props.imageToManage && (
      <React.Fragment>
        <RenderStyledInput
          imageName={props.imageName}
          editImageName={props.editImageName}
        />

        <StyledImageToManage
          src={getFullImageUrl(props.imageToManage)}
          alt={props.imageToManage}
        />

        <RenderStyledButtonsRow {...props} />
      </React.Fragment>
    )}
  </StyledImageManager>
);

const RenderStyledInput = ({imageName, editImageName}) => (
  <React.Fragment>
    <StyledEditLabel htmlFor="filename">CHANGE FILENAME</StyledEditLabel>
    <StyledEditImageRow>
      <StyledInput
        id="filename"
        value={imageName}
        placeholder={'Name of image...'}
        type="text"
        onChange={evt => {
          editImageName(evt.target.value);
        }}
      />
    </StyledEditImageRow>
  </React.Fragment>
);

const RenderStyledButtonsRow = props => (
  <StyledEditImageRow>
    <SaveButton
      title={'Save changes of this image'}
      width={200}
      onClick={() => {
        const imageNameToSlice = extractNameFromImageUrl(props.imageToManage);
        const oldImageName = extractFullNameFromImageUrl(props.imageToManage);
        const newImageName = _.replace(oldImageName, imageNameToSlice, props.imageName);
        props.saveEditedImageName(oldImageName, newImageName, props.imageType);
      }}
    />
    <CancelButton
      width={200}
      title={'Cancel editing'}
      onClick={() => props.selectImageToManage(null)}
    />
    <DeleteButton
      width={200}
      title={'Delete this image'}
      onClick={() => props.toggleDialog()}
    />
  </StyledEditImageRow>
);

const mapStateToProps = state => ({
  imageType: state.views.imageManager.imageType,
  imageName: state.views.imageManager.imageName,
  imageToManage: state.views.imageManager.imageToManage,
});

const mapDispatchToProps = dispatch => ({
  toggleDialog: () => dispatch(viewActions.toggleDialogState()),
  selectImageToManage: image => dispatch(viewActions.selectImageToManage(image)),
  editImageName: imageName => dispatch(viewActions.editImageName(imageName)),
  saveEditedImageName: (oldImageName, newImageName, nameSpace) => (
    dispatch(viewActions.saveEditedImageName(oldImageName, newImageName, nameSpace))
  ),
  deleteImage: (image, nameSpace) => dispatch(viewActions.deleteImage(image, nameSpace)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageManagerSection);
