// location-selector.js

import {createSelector} from 'reselect';
import _ from 'lodash';
import {compose, filter, keyBy, mapValues} from 'lodash/fp';

import {createBlueprintUrl} from '../views.routes';
import {selectChoosableBlueprintImages} from '../views.selectors';
import {getBlueprintImageUrl} from '../views.utils';
import {knownMeasurements} from '../views.constants';
import {selectPaginatedBlueprints} from './location-menu.view/location-menu.selectors';

// state
const selectAllLocations = state => state.locations;
const selectAllDevices = state => state.devices;
// common
const selectCurrentLocationId = state => state.views.common.currentLocationId;
const selectCurrentBlueprintId = state => state.views.common.currentBlueprintId;
const selectCurrentDeviceId = state => state.views.common.currentDeviceId;
const selectAllImageSizes = state => state.views.common.imageSizes;
// location
const selectAllBlueprintEdits = state => state.views.blueprintEditor.blueprintEdits;
const selectIsInDevicePlacementMode = state => state.views.location.isInDevicePlacementMode;
// locationMenu
const selectAllBlueprintCreators = state => state.views.blueprintCreate.blueprintCreators;
const selectAllBlueprintDeviceAdders = state => state.views.locationMenu.blueprintDeviceAdders;
// locationBlueprint
const selectMovingDeviceItem = state => state.views.locationBlueprint.movingDeviceItem;
// device
const selectAllDeviceEdits = state => state.views.deviceEditor.deviceEdits;
// devicePlacementLayer
const selectAllDevicePlacementInProcess = state => (
  state.views.devicePlacementLayer.devicePlacementInProcess
);


// derive the current location specified by currentLocationId
const selectCurrentLocation = createSelector(
  selectCurrentLocationId,
  selectAllLocations,
  (locationId, locations) => locations[locationId] || null,
);

// gather all location layers (incl. defaultLayer)
const selectAllCurrentLocationBlueprints = createSelector(
  selectCurrentLocation,
  location => (
    // location can be null
    _.concat(
      _.get(location, 'defaultLayer', []),
      _.get(location, 'layers', []),
    )
  )
);

const selectNextIndex = createSelector(
  selectAllCurrentLocationBlueprints,
  selectCurrentBlueprintId,
  (layers, blueprintId) => {
    const sortedBlueprints = _.sortBy(layers, 'name');
    const nextIndex = _.findIndex(sortedBlueprints, (layer) => _.isEqual(layer._id, blueprintId)) + 1;
    return sortedBlueprints[nextIndex];
  }
);

const selectPrevIndex = createSelector(
  selectAllCurrentLocationBlueprints,
  selectCurrentBlueprintId,
  (layers, blueprintId) => {
    const sortedBlueprints = _.sortBy(layers, 'name');
    const prevIndex = _.findIndex(sortedBlueprints, (layer) => _.isEqual(layer._id, blueprintId)) - 1;
    return sortedBlueprints[prevIndex];
  }
);

// derive the blueprintCreator for the currentLocation
const selectCurrentLocationBlueprintCreator = createSelector(
  selectCurrentLocationId,
  selectAllBlueprintCreators,
  (locationId, blueprintCreators) => blueprintCreators[locationId] || null,
);

const selectCurrentLocationBlueprintEditor = () => createSelector(
  selectCurrentBlueprintId,
  selectAllBlueprintEdits,
  (blueprintId, blueprintEdits) => blueprintEdits[blueprintId] || '',
);

const selectCurrentLocationDeviceEditor = () => createSelector(
  selectCurrentDeviceId,
  selectAllDeviceEdits,
  (deviceId, deviceEdits) => deviceEdits[deviceId] || ''
)

const selectCurrentBlueprint = createSelector(
  selectCurrentBlueprintId,
  selectAllCurrentLocationBlueprints,
  selectAllDevices,
  (blueprintId, blueprints, devices) => {
    const index = _.findIndex(blueprints, {_id: blueprintId});
    _.each(_.get(blueprints[index], 'items'), item => {
      item.name = _.get(devices[item.deviceId], 'name');
      item.status = _.get(devices[item.deviceId], 'status');
    });
    return blueprints[index] || null;
  },
);

const selectCurrentBlueprintImageUrl = createSelector(
  selectCurrentBlueprint,
  blueprint => {
    const url = getBlueprintImageUrl(_.get(blueprint, 'image'));
    return url;
  },
);

const selectCurrentBlueprintImageSize = createSelector(
  selectCurrentBlueprintImageUrl,
  selectAllImageSizes,
  (imageUrl, imageSizes) => imageSizes[imageUrl] || null,
);

const selectCurrentBlueprintUnplacedDeviceItems = createSelector(
  selectCurrentBlueprint,
  selectAllDevicePlacementInProcess,
  (blueprint, placementsInProcess) => {
    const deviceItems = _.get(blueprint, 'items', []);
    const unplacedDeviceItems = _.filter(deviceItems, item => (
      !_.isNumber(item.left) || !_.isNumber(item.top)
    ));
    // try to return
    if (!_.size(placementsInProcess)) {
      return unplacedDeviceItems;
    }
    // but filter out items that are in the process of being placed if needed
    const filtered = _.compact(_.map(unplacedDeviceItems, item => {
      const placement = _.find(placementsInProcess, {_id: item._id});
      return !placement ? item : null;
    }));
    return filtered;
  },
);

const selectCurrentBlueprintPlacedDeviceItems = createSelector(
  selectCurrentBlueprint,
  selectMovingDeviceItem,
  selectAllDevicePlacementInProcess,
  state => state.views.locationMenu.blueprintDeviceFilter,
  (blueprint, movingDeviceItem, placementsInProcess, filter) => {
    let deviceItems = _.get(blueprint, 'items', []).slice();
    // edgecase: account for items being placed & account for mover as well
    if (_.size(placementsInProcess)) {
      deviceItems = _.map(deviceItems, item => {
        const placement = _.find(placementsInProcess, {_id: item._id});
        return !placement ? item : placement;
      });
    }
    // primary: filter out all items without coords
    deviceItems = _.filter(deviceItems, item => (
      _.isNumber(item.left) && _.isNumber(item.top)
    ));
    // and account for the moving item
    if (movingDeviceItem) {
      deviceItems = _.map(deviceItems, item => {
        const isMatch = item._id === _.get(movingDeviceItem, '_id');
        return !isMatch ? item : movingDeviceItem;
      });
    }
    const devices = deviceItems.slice();
    return _.filter(devices, device => _.includes(_.toLower(device.name), _.toLower(filter)));
  }
);

const selectCurrentBlueprintIsPlacingNewDeviceItems = createSelector(
  selectIsInDevicePlacementMode,
  selectCurrentBlueprintUnplacedDeviceItems,
  (isInDevicePlacementMode, unplacedItems) => (
    !!isInDevicePlacementMode && !!unplacedItems.length
  ),
);

// collect all items for the location
const selectAllCurrentLocationDeviceItems = createSelector(
  selectAllCurrentLocationBlueprints,
  blueprints => {
    const itemsLists = _.map(blueprints, 'items');
    return _.flatten(itemsLists);
  }
);

// derive all IDS for devices which belong to the current location
const selectAllCurrentLocationDeviceIds = createSelector(
  selectAllCurrentLocationDeviceItems,
  items => {
    const deviceIds = _.map(items, 'deviceId');
    return _.compact(_.uniq(deviceIds));
  }
);

// derive all devices which belong to the current location
const selectAllCurrentLocationDevices = createSelector(
  selectAllDevices,
  selectAllCurrentLocationDeviceIds,
  (devices, deviceIds) => _.pick(devices, deviceIds),
);

const selectAllCurrentLocationDevicesValues = createSelector(
  selectAllCurrentLocationDevices,
  devices => {
    const translatedDevicesValues = compose(
      mapValues('value'),
      keyBy('_id'),
      filter('value')
    )(devices);

    const translatedDevicesValuesReducer = (acc, values, id) => {
      if(!_.isObject(values)) {
        values = {value: values};
      }

      const filteredValues = _.omitBy(values, (value, key) => key.includes('Unit'));
      const metadataValues = _.reduce(filteredValues, (acc, val) => {
        if (val?.metadata) {
          acc = val.metadata;
        }
        return acc;
      }, {});

      const flattenedObjectValuesReducer = (prev, curr, key) => {
        if (_.isObject(curr)) {
          prev = {
            ...prev,
            ..._.mapKeys(curr, (val, index) => `${key}_${index}`),
          }
        }
        return prev;
      };

      const flattenedObjectValues = _.reduce(filteredValues, flattenedObjectValuesReducer, {});
      const combinedValues = {...filteredValues, ...metadataValues, ...flattenedObjectValues};
      const formattedValues = _.map(combinedValues, (value, key) => {

        if (!_.isObject(value)) {
          return _.assign({value, type: key, name: key, unit: ''}, knownMeasurements[key])
        }
      });
      acc[id] = _.compact(formattedValues);
      return acc;
    };

    return _.reduce(translatedDevicesValues, translatedDevicesValuesReducer, {});
  }
);

// extract the blueprint ids
const selectAllCurrentLocationBlueprintIds = createSelector(
  selectPaginatedBlueprints,
  blueprints => _.map(blueprints, '_id'),
);

const createSelectorCurrentLocationBlueprintFromId = () => createSelector(
  (state, props) => props.blueprintId,
  selectAllCurrentLocationBlueprints,
  (blueprintId, blueprints) => _.find(blueprints, {_id: blueprintId}),
);

const selectAllFilteredLocationDevices = createSelector(
  selectAllCurrentLocationBlueprints,
  (state, props) => props.blueprintId,
  state => state.views.locationMenu.blueprintDeviceFilter,
  (blueprints, blueprintId, filter) => {
    const devices = _.find(blueprints, {_id: blueprintId}).items;
    return _.filter(devices, device => _.includes(_.toLower(device.name), _.toLower(filter)))
  }
);

const createSelectorBlueprintImageChoice = () => createSelector(
  createSelectorCurrentLocationBlueprintFromId(),
  selectChoosableBlueprintImages,
  (blueprint, imageChoices) => {
    const blueprintImage = _.get(blueprint, 'image') || '';
    const imageChoice = _.find(imageChoices, choice => (
      blueprintImage.indexOf(choice.value) > -1
    ));
    return imageChoice;
  }
);

const createSelectorBlueprintNavLink = () => createSelector(
  createSelectorCurrentLocationBlueprintFromId(),
  selectCurrentLocation,
  (blueprint, location) => {
    const locationName = _.get(location, 'name', null);
    const blueprintName = _.get(blueprint, 'name', null);
    const url = createBlueprintUrl(locationName, blueprintName);
    return url;
  },
);

const createSelectorBlueprintAddableDevices = () => createSelector(
  createSelectorCurrentLocationBlueprintFromId(),
  selectAllDevices,
  selectCurrentLocation,
  (blueprint, allDevices, location) => {
    if (!location) {
      return [];
    }
    const items = _.get(blueprint, 'items', []);
    const currentDeviceIds = _.map(items, 'deviceId');
    const selectables = _.omit(allDevices, currentDeviceIds);
    const selectOptions = _.map(selectables, device => ({
      value: device._id,
      label: device.name || device._id,
    }));
    return selectOptions;
  },
);

const createSelectorBlueprintAddableDeviceSelection = () => createSelector(
  (state, props) => props.blueprintId,
  selectAllBlueprintDeviceAdders,
  createSelectorBlueprintAddableDevices(),
  (blueprintId, blueprintDeviceAdders) => {
    const deviceSelection = blueprintDeviceAdders[blueprintId] || null;
    if (!deviceSelection) {
      return null;
    }
    return deviceSelection;
  },
);

// derive the location which SHOULD be the current location, given the
// current url (url takes priority over currentLocationId in useEffect())
const selectUrlLocationName = (state, props) => props.match.params.locationName;
const selectUrlLocation = createSelector(
  selectUrlLocationName,
  selectAllLocations,
  (locationName, locations) => {
    const currentLocation = _.find(locations, location => (
      location.name === locationName
    ));
    return currentLocation || null;
  },
);
const selectUrlBlueprintName = (state, props) => props.match.params.blueprintName;
const selectUrlBlueprint = createSelector(
  selectUrlBlueprintName,
  selectAllCurrentLocationBlueprints,
  (blueprintName, blueprints) => {
    if (blueprintName) {
      return _.find(blueprints, {name: blueprintName}) || null;
    }
    return _.find(blueprints, blueprint => !blueprint.name) || null;
  }
);

// derive the subscriptionsIds
const selectAllSubscriptions = state => state.subscriptions;
const selectAllSubscriptionIds = createSelector(
  selectAllSubscriptions,
  subscriptions => _.keys(subscriptions),
);
// evaluate which new subscriptions are needed
const selectAllFreshSubscriptions = createSelector(
  selectAllSubscriptionIds,
  selectAllCurrentLocationDeviceIds,
  (subscriptionIds, deviceIds) => _.difference(deviceIds, subscriptionIds),
);
// evaluate which subscriptions need to be removed
const selectAllDeadSubscriptions = createSelector(
  selectAllSubscriptionIds,
  selectAllCurrentLocationDeviceIds,
  (subscriptionIds, deviceIds) => _.difference(subscriptionIds, deviceIds),
);

export {
  selectCurrentLocationId,
  selectCurrentDeviceId,
  selectCurrentLocation,
  selectAllCurrentLocationDevices,
  selectAllCurrentLocationDevicesValues,
  selectCurrentLocationBlueprintCreator,
  selectCurrentLocationBlueprintEditor,
  selectCurrentLocationDeviceEditor,
  selectCurrentBlueprint,
  selectCurrentBlueprintImageUrl,
  selectCurrentBlueprintImageSize,
  selectCurrentBlueprintPlacedDeviceItems,
  selectCurrentBlueprintUnplacedDeviceItems,
  selectCurrentBlueprintIsPlacingNewDeviceItems,
  selectAllCurrentLocationBlueprints,
  selectAllCurrentLocationBlueprintIds,
  createSelectorCurrentLocationBlueprintFromId,
  createSelectorBlueprintImageChoice,
  createSelectorBlueprintAddableDevices,
  createSelectorBlueprintAddableDeviceSelection,
  createSelectorBlueprintNavLink,
  selectUrlLocationName,
  selectUrlLocation,
  selectUrlBlueprintName,
  selectUrlBlueprint,
  selectAllFreshSubscriptions,
  selectAllDeadSubscriptions,
  selectNextIndex,
  selectPrevIndex,
  selectAllFilteredLocationDevices,
};
