// location-blueprint.selectors.js

import {createSelector} from 'reselect';

const selectCurrentBlueprintId = state => state.views.common.currentBlueprintId;
const selectAllBlueprintGeometries = state => state.views.locationBlueprint.blueprintGeometries;

const selectCurrentBlueprintGeometry = createSelector(
  selectCurrentBlueprintId,
  selectAllBlueprintGeometries,
  (blueprintId, geometries) => {
    const geometry = geometries[blueprintId];
    return geometry || {scale: 1, x: 0, y: 0};
  },
  // (blueprintId, geometries) => (geometries[blueprintId] || {scale: 1, x: 0, y: 0}),
);

export {
  selectCurrentBlueprintGeometry,
};
