import createReducer from '../../utils/create-reducer';

const USER_SET = 'USER_SET';
const RESET_FORM = 'RESET_FORM';

const userValidators = {
  username: val => !!val.length && val.length < 20,
  password: val => val.length >= 4 && val.length < 30,
};

const validateUser = user => {
  const validatorKeys = Object.keys(user);

  return validatorKeys.reduce((acc, curr) => {
    const validate = userValidators[curr];
    const value = user[curr];
    return {...acc, [curr]: validate(value)};
  }, {});
};

const actions = {
  setUser: user => ({type: USER_SET, user}),
  resetForm: () => ({type: RESET_FORM}),
};

const defaultState = {
  user: {
    username: '',
    password: '',
  },
  isValid: {
    username: false,
    password: false,
  },
};

const handlers = {
  [USER_SET]: (state, {user}) => {
    const isValid = validateUser(user);
    return ({...state, user, isValid});
  },
  [RESET_FORM]: (state) => {
    const {user} = defaultState;
    const {isValid} = defaultState;
    return ({...state, user, isValid});
  }
};

const reduce = createReducer(defaultState, handlers);

export {
  actions,
  reduce,
}
