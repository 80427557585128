// locations.styled.js

// Modules
import styled from 'styled-components';

// Local components
import {colors, NAVBAR_HEIGHT} from '../views.constants';

const MapWrapper = styled.div`
  height: calc(100vh - ${NAVBAR_HEIGHT});
  width: 100%;
  opacity: 1;
  border-top: 1px solid ${colors.LIGHT_GREEN};
`;

const LocationsContainerLayer = styled.div`
  z-index: 999;
  position: absolute;
  top: ${NAVBAR_HEIGHT};
  left: 0;
`;

const StyledLocationInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.9em;
`;

const StyledLocationSubInfo = styled.div`
  font-size: 0.9em;
  color: ${colors.GREY};
`;

export {
  MapWrapper,
  LocationsContainerLayer,
  StyledLocationInfo,
  StyledLocationSubInfo,
};
