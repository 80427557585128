// location-menu.selectors.js

// Modules
import _ from 'lodash';
import {createSelector} from 'reselect';

const selectUrlPath = (state, props) => props.history.location.pathname;

const createSelectorUrlPath = createSelector(
  selectUrlPath,
  urlPath => _.last(_.split(urlPath, '/'))
);

export {
  createSelectorUrlPath,
};
