// selectors.js

// This file is closely dependent upon the state structure
// of the reducer in locations.redux. However, it uses the total
// global state structure

import {createSelector} from 'reselect';
import _ from 'lodash';
import {selectFilteredLocations} from '../locations.selectors';

const selectEdits = state => state.views.locationsMenu.edits;
const selectPage = state => state.views.locationsMenu.page;
const selectPageSize = state => state.views.locationsMenu.pageSize;

const selectPaginatedLocations = createSelector(
  selectFilteredLocations,
  selectPage,
  selectPageSize,
  (locations, page, pageSize) => {
    const sortedLocations = _.sortBy(locations, 'name');
    const startIndex = page * pageSize;
    const endIndex = (page + 1) * pageSize;
    return _.slice(sortedLocations, startIndex, endIndex);
  },
);

const selectTotalNumLocations = createSelector(
  selectFilteredLocations,
  locations => (_.size(locations)),
);

export {
  selectPage,
  selectPageSize,
  selectEdits,
  selectTotalNumLocations,
  selectPaginatedLocations,
};
