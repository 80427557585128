// devices.js

import request from './request';

const DEVICES = '/iotnodes';

const fetch = () => request({
  method: 'get',
  url: DEVICES,
});

const get = deviceId => request({
  method: 'get',
  url: `${DEVICES}/${deviceId}`,
});

const getStatistics = (deviceId, measurement, start, end, distance) => request({
  method: 'get',
  url: `${DEVICES}/${deviceId}/stats`,
  params: {measurement, start, end, distance},
});

const getStatisticsFields = deviceId => request({
  method: 'get',
  url: `${DEVICES}/${deviceId}/stats/fields`,
});

export {
  fetch,
  get,
  getStatistics,
  getStatisticsFields,
};
