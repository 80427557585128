// device-placement-layer.redux.js

import _ from 'lodash';
// import delay from 'delay'

import {
  DEVICE_ITEM_SIMPLE_EDIT_PROPS,
  editDeviceItemDetails,
} from '../../../state/location-editor.utility';
import {actions as locationsActions} from '../../../state/locations.redux';

const actionTypes = {
  DEVICE_PLACEMENT_BEGIN_PROCESS: 'DEVICE_PLACEMENT_BEGIN_PROCESS',
  DEVICE_PLACEMENT_PROCESS_ENDING: 'DEVICE_PLACEMENT_PROCESS_ENDING',
};

//
// actions
//
const actions = {
  endDeviceItemPlacementProcess: deviceItemId => ({
    type: actionTypes.DEVICE_PLACEMENT_PROCESS_ENDING,
    payload: {deviceItemId},
  }),
  saveDeviceItemPlacement: (location, deviceItemId, details) => dispatch => {
    // integrate the new item details to the location
    const edits = _.pick(details, DEVICE_ITEM_SIMPLE_EDIT_PROPS); // for documentary purposes
    const modifiedLocation = editDeviceItemDetails(location, deviceItemId, edits);
    // fire and forget the location update (practicality)
    dispatch(locationsActions.updateLocation(modifiedLocation));
  },
};

const defaultState = {
  devicePlacementInProcess: {},
};

const reduce = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.DEVICE_PLACEMENT_BEGIN_PROCESS: {
      const {deviceItemId, edits} = action.payload;
      return {
        ...state,
        devicePlacementInProcess: {
          ...state.devicePlacementInProcess,
          [deviceItemId]: {
            _id: deviceItemId,
            ...edits,
          },
        },
      };
    }
    case actionTypes.DEVICE_PLACEMENT_PROCESS_ENDING: {
      const {deviceItemId} = action.payload;
      return {
        ...state,
        devicePlacementInProcess: _.omit(
          state.devicePlacementInProcess,
          deviceItemId,
        ),
      };
    }
    default:
      return state;
  }
};

export {
  actions,
  reduce,
};
