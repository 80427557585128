// blueprint-create.redux.js

import {omit} from 'lodash/fp';
import {addLocationBlueprint} from '../../../state/location-editor.utility';
import {actions as locationsActions} from '../../../state/locations.redux';

const actionTypes = {
  BLUEPRINT_CREATOR_OPEN: 'BLUEPRINT_CREATOR_OPEN',
  BLUEPRINT_CREATOR_CLOSE: 'BLUEPRINT_CREATEOR_CLOSE',
  BLUEPRINT_CREATOR_UPDATE: 'BLUEPRINT_CREATOR_UPDATE',
};

const actions = {
 openBlueprintCreator: locationId => ({
    type: actionTypes.BLUEPRINT_CREATOR_OPEN,
    payload: {locationId},
  }),
  cancelBlueprintCreator: locationId => ({
    type: actionTypes.BLUEPRINT_CREATOR_CLOSE,
    payload: {locationId},
  }),
  saveBlueprintCreator: (location, blueprint) => dispatch => {
    const modifiedLocation = addLocationBlueprint(location, blueprint);
    dispatch(locationsActions.updateLocation(modifiedLocation));
    return dispatch({
      type: actionTypes.BLUEPRINT_CREATOR_CLOSE,
      payload: {locationId: location._id},
    });
  },
  updateBlueprintCreator: (locationId, updates) => ({
    type: actionTypes.BLUEPRINT_CREATOR_UPDATE,
    payload: {locationId, updates},
  }),
};

const defaultState = {
  blueprintCreators: {},
  showsBlueprintCreator: false,
};

const reduce = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.BLUEPRINT_CREATOR_OPEN: {
      const {locationId} = action.payload;
      return {
        ...state,
        blueprintCreators: {
          ...state.blueprintCreators,
          [locationId]: {},
        },
      };
    }

    case actionTypes.BLUEPRINT_CREATOR_CLOSE: {
      const {locationId} = action.payload;
      return {
        ...state,
        blueprintCreators: omit(locationId, state.blueprintCreators),
      };
    }

    case actionTypes.BLUEPRINT_CREATOR_UPDATE: {
      const {locationId, updates} = action.payload;
      return {
        ...state,
        blueprintCreators: {
          ...state.blueprintCreators,
          [locationId]: {
            ...state.blueprintCreators[locationId],
            ...updates,
          },
        },
      };
    }

    default:
      return state;
  }
};

export {
  actions,
  reduce,
}
