// devices.redux.js

// Modules
import _ from 'lodash';
import {colors} from '../views/views.constants';

// Local components
import {devices as devicesApi} from '../api';
import deviceStatus from '../utils/device-status';

const nodeStatus = (obj, devices) => {
  if (!obj) return {text: ['Unidentified node type'], status: 2, color: colors.YELLOW};

  if (_.get(obj, 'translatedFrom') && obj.nodeType !== 'simple-lora-node') {
    return {
      text: ['Translated node'],
      status: 0,
      color: colors.GREEN
    };
  }

  if (obj.expectedReportInterval) {
    const currentDate = Date.now();
    const reportedAtTime = new Date(obj.reportedAt).getTime();
    const hasNotReportedInTime = (currentDate - obj.expectedReportInterval) > reportedAtTime;
    if (hasNotReportedInTime) {
      return {
        text: ['Device did not report in time'],
        status: 1,
        color: colors.YELLOW
      }
    }
  }

  const fn = _.get(deviceStatus, obj.nodeType);
  return fn
    ? fn(obj, devices)
    : {text: ['Unidentified node type'], status: 2, color: colors.YELLOW};
};

const actionTypes = {
  DB_DEVICES_REPLACE_ALL: 'DB_DEVICES_REPLACE_ALL',
  DB_DEVICES_REMOVE_ONE: 'DB_DEVICES_REMOVE_ONE',
  DB_DEVICES_REPLACE_ONE: 'DB_DEVICES_REPLACE_ONE',
};

const actions = {
  replaceDevice: (newDevice, devices) => dispatch => {
    const device = {...newDevice, status: nodeStatus(newDevice, devices)};
    return dispatch({
      type: actionTypes.DB_DEVICES_REPLACE_ONE,
      payload: {device},
    })
  },
  fetchDevices: () => dispatch => (
    devicesApi.fetch()
      .then(allDevices => {
        const valuedDevices = _.filter(allDevices, d => d.translatedFrom || d.value);
        const devices = _.map(valuedDevices, device => {
          return {...device, status: nodeStatus(device, allDevices)};
        });
        return dispatch({
          type: actionTypes.DB_DEVICES_REPLACE_ALL,
          payload: {devices},
        })
      })
  )
};

const defaultState = {
  fetching: true,
};

const reduce = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.DB_DEVICES_REPLACE_ALL: {
      const {devices} = action.payload;
      return _.reduce(devices, (acc, device) => {
        acc[device._id] = device;
        return acc;
      }, {});
    }
    case actionTypes.DB_DEVICES_REPLACE_ONE: {
      const {device} = action.payload;
      return {...state, [device._id]: device};
    }
    case actionTypes.DB_DEVICES_REMOVE_ONE: {
      const {deviceId} = action.payload;
      return _.omit(state, deviceId);
    }
    default: {
      return state;
    }
  }
};

export {
  actions,
  reduce,
};
