// app.js
// This is where the state is connected to the view-heirarchy

import React from 'react';
import {Provider} from 'react-redux';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';

// store
import store from './state/store';

// local views
import Views from './views';

const App = () => (
  <Provider store={store}>
    <Views />
  </Provider>
);

export default App;
