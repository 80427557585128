// device-placement-layer.view/index.js

import React from 'react';
import {connect} from 'react-redux';

// local components
import {
  centerAndUnscaleScreenPosition,
} from '../location-blueprint.view/location-blueprint.utility';
import {
  DeviceItemsLayer,
  DevicePlacementIndicator,
  DevicePlacementInstruction,
  DeviceNextToBeAdded,
} from './device-placement-layer.styled';
import {ButtonGroup} from '../../views-buttons';

// actions & selectors
import {actions} from './device-placement-layer.redux';
import {
  selectCurrentBlueprintGeometry,
} from '../location-blueprint.view/location-blueprint.selectors';
import {
  selectCurrentLocation,
  selectCurrentBlueprintUnplacedDeviceItems,
  selectCurrentBlueprintIsPlacingNewDeviceItems,
} from '../location.selectors';

const DeviceItemsWrapper = props => (
  <DeviceItemsLayer
    isInDevicePlacementMode={props.isInDevicePlacementMode}
    isPlacingNewDeviceItems={props.isPlacingNewDeviceItems}
    onClick={evt => {
      const screenCoordinate = {
        x: evt.clientX,
        y: evt.clientY,
      };
      const coordinate = centerAndUnscaleScreenPosition(
        screenCoordinate,
        props.blueprintGeometry,
        props.backgroundDimensions,
      );
      const deviceItem = props.unplacedDeviceItems[0];
      props.saveDeviceItemPlacement(
        props.currentLocation,
        deviceItem._id,
        {left: coordinate.x, top: coordinate.y},
      );
    }}
    >
    <ButtonGroup>
      {!!props.unplacedDeviceItems.length && (
        <DevicePlacementIndicator
          numIndicated={props.unplacedDeviceItems.length}
        />
      )}
      {!!props.isInDevicePlacementMode && (
        <DevicePlacementInstruction
          isPlacingNewDeviceItems={props.isPlacingNewDeviceItems}
        />
      )}
      {!!props.isPlacingNewDeviceItems && (
        <DeviceNextToBeAdded
          deviceItem={props.unplacedDeviceItems[0]}
        />
      )}
    </ButtonGroup>
  </DeviceItemsLayer>
);

//
// state
//

const mapStateToProps = state => ({
  currentLocation: selectCurrentLocation(state),
  isInDevicePlacementMode: state.views.location.isInDevicePlacementMode,
  backgroundDimensions: state.views.locationBlueprint.backgroundDimensions,
  blueprintGeometry: selectCurrentBlueprintGeometry(state),
  unplacedDeviceItems: selectCurrentBlueprintUnplacedDeviceItems(state),
  isPlacingNewDeviceItems: selectCurrentBlueprintIsPlacingNewDeviceItems(state),
});

const mapDispatchToProps = dispatch => ({
  saveDeviceItemPlacement: (location, itemId, details) => {
    dispatch(actions.saveDeviceItemPlacement(location, itemId, details));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceItemsWrapper);
