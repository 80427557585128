// blueprint-edit.redux.js

import {omit, pick} from 'lodash/fp';
import {
  BLUEPRINT_SIMPLE_EDIT_PROPS,
  editBlueprintDetails
} from '../../../state/location-editor.utility';
import {actions as locationsActions} from '../../../state/locations.redux';

const actionTypes = {
  // creating a new blueprint
  BLUEPRINT_EDITS_BEGIN: 'BLUEPRINT_EDITS_BEGIN',
  BLUEPRINT_EDITS_CLOSE: 'BLUEPRINT_EDITS_CLOSE',
  BLUEPRINT_EDITS_END: 'BLUEPRINT_EDITS_END',
  BLUEPRINT_EDITS_UPDATE: 'BLUEPRINT_EDITS_UPDATE',
};

const actions = {
  beginBlueprintEdits: blueprint => ({
    type: actionTypes.BLUEPRINT_EDITS_BEGIN,
    payload: {blueprint},
  }),
  updateBlueprintEdits: (blueprintId, blueprintEdits) => ({
    type: actionTypes.BLUEPRINT_EDITS_UPDATE,
    payload: {blueprintId, blueprintEdits}
  }),
  cancelBlueprintEdits: blueprintId => ({
    type: actionTypes.BLUEPRINT_EDITS_END,
    payload: {blueprintId},
  }),
  saveBlueprintEdits: (location, blueprintId, updates) => dispatch => {
    const blueprintEdits = pick(BLUEPRINT_SIMPLE_EDIT_PROPS, updates);
    const modifiedLocation = editBlueprintDetails(location, blueprintId, blueprintEdits);
    dispatch(locationsActions.updateLocation(modifiedLocation));
    return dispatch({
      type: actionTypes.BLUEPRINT_EDITS_CLOSE,
      payload: {locationId: location._id},
    });
  },
};

const defaultState = {
  blueprintEdits: {},
};

const reduce = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.BLUEPRINT_EDITS_BEGIN: {
      const {blueprint} = action.payload;
      const blueprintEditProps = pick(BLUEPRINT_SIMPLE_EDIT_PROPS, blueprint);
      return {
        ...state,
        blueprintEdits: {
          ...state.blueprintEdits,
          [blueprint._id]: {
            ...state.blueprintEdits[blueprint._id],
            ...blueprintEditProps,
          },
        },
      };
    }
    case actionTypes.BLUEPRINT_EDITS_END: {
      const {blueprintId} = action.payload;
      return {
        ...state,
        blueprintEdits: omit(blueprintId, state.blueprintEdits),
      };
    }
    case actionTypes.BLUEPRINT_EDITS_UPDATE: {
      const {blueprintId, blueprintEdits} = action.payload;

      return {
        ...state,
        blueprintEdits: {
          ...state.blueprintEdits,
          [blueprintId]: {
            ...state.blueprintEdits[blueprintId],
            ...blueprintEdits,
          },
        },
      };
    }

    case actionTypes.BLUEPRINT_EDITS_CLOSE: {
      const {locationId} = action.payload;
      return {
        ...state,
        blueprintEdits: omit(locationId, state.blueprintEdits),
      };
    }

    default:
      return state;
  }
};

export {
  actions,
  reduce,
}
