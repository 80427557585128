// views.styled.js

// import _ from 'lodash';
import styled from 'styled-components';

import Modal from 'styled-react-modal';
import {Link} from 'react-router-dom';

import {colors} from './views.constants';

const FlexWrapper = styled.div`
  display: flex;
`;

const Wrapper = styled.div`
  font-family: Lato, sans-serif;
  background: ${colors.WHITE};
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  background: ${colors.WHITE};
  width: 100%;
  height: 100%;
`;

const StyledModalDialog = Modal.styled`
  position: absolute;
  width: 400px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${colors.WHITE};
  border-radius: 5px;
`;

const Button = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }
`;

const ToggleMenuButton = styled.div`
  z-index: 999999;
  margin: 12px;
  position: fixed;
  cursor: pointer;
  width: 60px;
  height: 60px;
  right: 0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.7em;
  color: ${colors.GREY_LIGHT};
  background: #b5a7a7;
  transition: background 0.2s, color 0.2s;

  &:hover {
    color: ${colors.WHITE};
    background: #b5a7a7;
    transition: background 0.2s, color 0.2s;
  }
`;

const HorizontalLine = styled.div`
  margin: 20px 10px 20px;
  width: 100%;
  height: 1px;
  background: ${({color}) => color || colors.GREY};
`;

const StyledTruncateTextWrapper = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export {
  FlexWrapper,
  Wrapper,
  ContentWrapper,
  ToggleMenuButton,
  Button,
  StyledLink,
  StyledModalDialog,
  HorizontalLine,
  StyledTruncateTextWrapper,
};
