import React from 'react';
import {withRouter} from 'react-router-dom';
import {toNumber, every} from 'lodash';
import  {compose} from 'lodash/fp';
import {connect} from 'react-redux';
import {Menu} from '../views-menu.styled';
import {Input, InputContent, InputRow, SubmitButton} from './create.styled';
import {INPUT_COORDS_MAX_LENGTH, INPUT_DESC_MAX_LENGTH, INPUT_NAME_MAX_LENGTH} from '../../constants';
import {FlexWrapper} from '../views.styled';
import {colors} from '../views.constants';
import {actions as viewActions} from './create.redux';

const CreationMenu = props => {

  return (
    <Menu isOpen={props.showMenu}>
      <InputContent>

        <p>Click on the map to get coordinates.</p>


        <InputRow>
          <label htmlFor="name">Name</label>
          <Input
            type="text"
            id="name"
            name="name"
            data-cy="new-location-name"
            maxLength={INPUT_NAME_MAX_LENGTH}
            placeholder="Name..."
            value={props.location.name}
            border={props.isValidLocation.name ? 'lightgreen' : 'red'}
            onChange={evt => {
              const name = evt.target.value;
              props.modifyLocationForm({...props.location, name});
            }}
          />
        </InputRow>

        <InputRow>
          <label htmlFor="description">Description</label>
          <Input
            type="text"
            id="description"
            name="description"
            data-cy="new-location-description"
            maxLength={INPUT_DESC_MAX_LENGTH}
            placeholder="Description..."
            value={props.location.description}
            border={props.isValidLocation.description ? 'lightgreen' : 'red'}
            onChange={evt => {
              const description = evt.target.value;
              props.modifyLocationForm({...props.location, description});
            }}
          />
        </InputRow>

        <InputRow>
          <label htmlFor="lat">Latitude (north-south)</label>
          <Input
            type="number"
            placeholder={'-90 to 90'}
            maxLength={INPUT_COORDS_MAX_LENGTH}
            id="lat"
            name="lat"
            data-cy="new-location-lat"
            value={props.location.lat}
            border={props.isValidLocation.lat ? 'lightgreen' : 'red'}
            onChange={evt => {
              const lat = toNumber(evt.target.value);
              props.modifyLocationForm({...props.location, lat});
            }}
          />
        </InputRow>

        <InputRow>
          <label htmlFor="lng">Longitude (east-west)</label>
          <Input
            type="number"
            placeholder={'-180 to 180'}
            maxLength={INPUT_COORDS_MAX_LENGTH}
            id="lng"
            name="lng"
            data-cy="new-location-lng"
            value={props.location.lng}
            border={props.isValidLocation.lng ? 'lightgreen' : 'red'}
            onChange={evt => {
              const lng = toNumber(evt.target.value);
              props.modifyLocationForm({...props.location, lng});
            }}
            data-cy="new-location-lng"
          />
        </InputRow>

        <InputRow>
          <FlexWrapper>

            <SubmitButton
              data-cy="new-location-submit-btn"
              disabled={!props.canSubmit}
              background={!props.canSubmit ? 'lightgrey' : colors.CREATE_LOCATION_BUTTON}
              backgroundHover={!props.canSubmit ? 'lightgrey' : colors.CREATE_LOCATION_BUTTON_HOVER}
              onClick={() => {
                if (!props.submitButtonDisabled) {
                  props.disableSubmitButton(true);
                  props.submitLocationForm(props.history, props.location);
                  setTimeout(() => props.disableSubmitButton(false), 2000);
                }
              }}>
              Submit
            </SubmitButton>

            <SubmitButton
              data-cy="new-location-cancel-btn"
              background={'grey'}
              backgroundHover={'darkgrey'}
              onClick={() => props.history.goBack()}>
              Cancel
            </SubmitButton>
          </FlexWrapper>
        </InputRow>

      </InputContent>

    </Menu>
  )
};


const mapStateToProps = state => ({
  location: state.views.create.location,
  isValidLocation: state.views.create.isValidLocation,
  canSubmit: every(state.views.create.isValidLocation),
  submitButtonDisabled: state.views.create.submitButtonDisabled,
  showMenu: state.views.common.showMenu,
});

const mapDispatchToProps = dispatch => ({
  disableSubmitButton: bool => dispatch(viewActions.disableSubmitButton(bool)),
  modifyLocationForm: location => dispatch(viewActions.modifyLocationForm(location)),
  submitLocationForm: (history, location) => (
    dispatch(viewActions.submitLocationForm(history, location))
  ),
});


export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(CreationMenu);
