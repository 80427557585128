// device-edit.redux.js

import {omit, pick} from 'lodash';
import {DEVICE_ITEM_SIMPLE_EDIT_PROPS, editDeviceItemDetails} from '../../../state/location-editor.utility';
import {actions as locationsActions} from '../../../state/locations.redux';

const actionTypes = {
  DEVICE_ITEM_EDITS_BEGIN: 'DEVICE_ITEM_EDITS_BEGIN',
  DEVICE_ITEM_EDITS_END: 'DEVICE_ITEM_EDITS_END',
  DEVICE_ITEM_EDITS_UPDATE: 'DEVICE_ITEM_EDITS_UPDATE',
};

const actions = {
  beginDeviceEdits: deviceItem => ({
    type: actionTypes.DEVICE_ITEM_EDITS_BEGIN,
    payload: {deviceItem},
  }),
  updateDeviceEdits: (deviceItemId, deviceEdits) => ({
    type: actionTypes.DEVICE_ITEM_EDITS_UPDATE,
    payload: {deviceItemId, deviceEdits}
  }),
  saveDeviceEdits: (location, deviceItemId, updates) => dispatch => {
    const deviceEdits = pick(updates, DEVICE_ITEM_SIMPLE_EDIT_PROPS);
    const modifiedLocation = editDeviceItemDetails(location, deviceItemId, deviceEdits);
    dispatch(locationsActions.updateLocation(modifiedLocation));
    return dispatch({
      type: actionTypes.DEVICE_ITEM_EDITS_END,
      payload: {deviceItemId},
    });
  },
  cancelDeviceEdits: deviceItemId => ({
    type: actionTypes.DEVICE_ITEM_EDITS_END,
    payload: {deviceItemId},
  }),
};

const defaultState = {
  deviceEdits: {},
};

const reduce = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.DEVICE_ITEM_EDITS_BEGIN: {
      const {deviceItem} = action.payload;
      const deviceEdits = pick(deviceItem, DEVICE_ITEM_SIMPLE_EDIT_PROPS);

      return {
        ...state,
        deviceEdits: {
          ...state.deviceEdits,
          [deviceItem._id]: {
            ...state.deviceEdits[deviceItem._id],
            ...deviceEdits,
          },
        },
      };
    }
    case actionTypes.DEVICE_ITEM_EDITS_END: {
      const {deviceItemId} = action.payload;
      return {
        ...state,
        deviceEdits: omit(state.deviceEdits, deviceItemId),
      };
    }
    case actionTypes.DEVICE_ITEM_EDITS_UPDATE: {
      const {deviceItemId, deviceEdits} = action.payload;
      return {
        ...state,
        deviceEdits: {
          ...state.deviceEdits,
          [deviceItemId]: {
            ...state.deviceEdits[deviceItemId],
            ...deviceEdits,
          },
        },
      };
    }

    default:
      return state;
  }
};

export {
  actions,
  reduce,
}
