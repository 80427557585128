// image-manager.utils.js

import _ from 'lodash';
import {allowedImageTypes, IMAGE_MAX_SIZE} from '../views.constants';

const extractNameFromImageUrl = image => {
  const fullImageName = _.last(_.split(image, '/'));
  const imageName = _.first(_.split(fullImageName, '.'));
  return imageName;
};

const extractFullNameFromImageUrl = image => {
  const fullImageName = _.last(_.split(image, '/'));
  return fullImageName;
};

const createReadableLabel = label => {
  const replacedLabel = _.replace(label, '_', ' ');
  const upperCaseLabel = _.upperCase(replacedLabel);
  return upperCaseLabel;
};

const determineFileErrorMessage = file => {
  if (file.size > IMAGE_MAX_SIZE) {
    return 'File size too large (MAX: 2MB)';
  }
  if (!_.includes(allowedImageTypes, file.type)) {
    return 'File type not supported';
  }
  return 'Unknown error';
};

export {
  extractNameFromImageUrl,
  extractFullNameFromImageUrl,
  createReadableLabel,
  determineFileErrorMessage,
};
