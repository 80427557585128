// chart.styled.js

// Modules
import React from 'react';
import styled, {css} from 'styled-components';
import {ListGroupItem} from 'reactstrap';

const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  grid-column: 1;
  grid-row: 2;
`;

const GridWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 330px;
  grid-template-rows: 20% 80%;
`;

const StyledListGroupItem = styled(ListGroupItem)`
  cursor: pointer;
  .icon {
    margin-right: 10px;
  }
  ${props => props.active && css`
    color: white !important;
    cursor: default !important;
  `}
`;

const CustomTooltipStyled = styled.div`
  background: #444;
  color: white;
  border-radius: 4px;
  padding: 5px 10px 5px 10px;
`;

const ToolTipValue = styled.div`
  margin: 0px;
`;

const ToolTipTime = styled.div`
  color: #bbb;
  margin: 0px;
  font-size: 14px;
`;

const ToolTipContent = props => {
  if (!props.active || props.payload[0].value === null) {
    return null;
  }
  let valueText = props.payload[0].payload.roundedValue;
  if (props.payload[0].unit) {
    valueText += ` ${props.payload[0].unit}`;
  }
  return (
    <CustomTooltipStyled>
      <ToolTipValue>
        {valueText}
      </ToolTipValue>
      <ToolTipTime>
        {`${props.payload[0].payload.formattedTime}`}
      </ToolTipTime>
    </CustomTooltipStyled>
  );
};

const CustomizedLabel = props => (
  <text
    x={75}
    y={15}
    fill="rgba(0, 136, 254, 1)"
    fontSize={18}
    textAnchor="middle"
    fontWeight="bold"
    >
    {props.text}
  </text>
);

const ChartHeader = styled.div`
  grid-column: 1;
  grid-row: 1;
  text-align: center;
  padding-top: 90px;
  p {
    color: gray;
    font-size: 14px;
    margin-bottom: 4px;
  }
`;

const NoDataContainerStyled = styled.div`
  width: 100%;
  padding-top: 300px;
  text-align: center;
  grid-column: 1;
  grid-row: 1;
`;

const NoDataContainerHeader = styled.div`
  color: gray;
`;

const NoDataContainer = props => (
  <NoDataContainerStyled>
    <NoDataContainerHeader>
      {props.message}
    </NoDataContainerHeader>
  </NoDataContainerStyled>
);

const SettingsMenuContainer = styled.div`
  min-height: 100vh;
  padding: 40px 30px 20px 30px;
  background: #eee;
  grid-column: 2;
  grid-row: 1 / 3;
`;

const SettingsMenuListGroupContainer = styled.div`
  overflow: auto;
  margin-top: 40px;
  margin-bottom: 50px;
`;

const SettingsMenuListGroupHeader = styled.h6`
  margin-left: 5px;
  font-weight: normal;
  color: #777;
  font-size: 14px;
`;

const CustomTimePeriodFromField = styled.div`
  width: 100%;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px 4px 0 0;
  display: grid;
  grid-template-columns: 50px 14px;
  grid-template-columns: 60px 1fr;
`;


const CustomTimePeriodFeedback = styled.div`
  width: 99%;
  padding-left: 3%;
  font-size: 12px;
  background: #e35d5d;
  color: white;
  grid-column: 1 / -1;
  grid-row: 2;
`;

const CustomTimePeriodToField = styled.div`
  width: 100%;
  background: white;
  border: 1px solid #ddd;
  border-top: 0px;
  display: grid;
  grid-template-columns: 50px 14px;
  grid-template-columns: 60px 1fr;
`;

const CustomTimePeriodFieldLabel = styled.div`
  line-height: 50px;
  text-align: right;
  grid-column: 1;
  grid-row: 1;
`;

const CustomTimePeriodInput = styled.input`
  border: none;
  margin: 0 15px;
  padding: 0 5px;
  height: 30px;
  margin-top: 10px;
  grid-column: 2;
  grid-row: 1;
`;

const CustomTimePeriodButton = styled.div`
  width: 100%;
  height: 50px;
  background: white;
  border: 1px solid #ddd;
  border-top: 0px;
  border-radius: 0 0 4px 4px;
  line-height: 50px;
  text-align: center;
  color: #999;
  ${({disabled}) => !disabled && `
    cursor: pointer;
    color: #157ffb;
    &:hover {
      background: #f8f9fa;
    }
  `}
`;

export {
  ChartContainer,
  GridWrapper,
  ToolTipContent,
  CustomizedLabel,
  NoDataContainer,
  SettingsMenuContainer,
  SettingsMenuListGroupContainer,
  SettingsMenuListGroupHeader,
  StyledListGroupItem,
  ChartHeader,
  CustomTimePeriodFromField,
  CustomTimePeriodFeedback,
  CustomTimePeriodToField,
  CustomTimePeriodFieldLabel,
  CustomTimePeriodInput,
  CustomTimePeriodButton,
};
