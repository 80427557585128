import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {compose} from 'lodash/fp';
import {toast} from 'react-toastify';
import {user as userIcon} from 'react-icons-kit/fa/user';
import {lock as lockIcon} from 'react-icons-kit/fa/lock';
import cookie from 'js-cookie';
import {InputsWrapper, LoginContainer, LoginWrapper, YggioImage} from './login.styled';
import {Input, SubmitButton} from '../../components';
import {actions} from './login.redux';
import {user as auth} from '../../api';
import config from '../../config';
import {routes} from '../views.routes';
import YGGIO_LOGO from '../../assets/images/yggio/yggio-icon.svg';

const Login = (
  {
    setUser,
    resetForm,
    user,
    isValid,
    history,
  }
) => {

  const login = () => auth.login({
    username: user.username,
    password: user.password,
  })
    .then(({token}) => {
      cookie.set('token', token, {domain: config.domain});
      history.push(routes.LOCATIONS)
    })
    .catch(err => toast.error(`Error: ${err}`))
    .finally(() => resetForm());

  const renderYggioLogo = () => (
    <YggioImage src={YGGIO_LOGO} alt="Yggio"/>
  );

  const renderUsernameInput = () => (
    <Input
      type={'text'}
      icon={userIcon}
      valid={isValid.username}
      width={'400px'}
      disabled={false}
      onChange={({target: {value}}) => setUser({...user, username: value})}
      value={user.username}
      placeholder={'Username'}
    />
  );

  const renderPasswordInput = () => (
    <Input
      type={'password'}
      icon={lockIcon}
      valid={isValid.password}
      width={'400px'}
      disabled={false}
      onChange={({target: {value}}) => setUser({...user, password: value})}
      value={user.password}
      placeholder={'Password'}
    />
  );

  const renderSubmitButton = () => {
    const isDisabled = (!isValid.username || !isValid.password);

    return (
      <SubmitButton
        disabled={isDisabled}
        onClick={() => login()}
      />
    );
  };

  const renderLogin = () => (
    <LoginWrapper>
      <LoginContainer>
        {renderYggioLogo()}
        <InputsWrapper>
          {renderUsernameInput()}
          {renderPasswordInput()}
          {renderSubmitButton()}
        </InputsWrapper>
      </LoginContainer>
    </LoginWrapper>
  );

  return renderLogin();
};

const mapStateToProps = state => ({
  user: state.views.login.user,
  isValid: state.views.login.isValid,
});

const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(actions.setUser(user)),
  resetForm: () => dispatch(actions.resetForm()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(Login);
