// confirmation-dialog.view

import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledModalDialog,
} from '../views.styled';
import {
  DialogContainer,
  DialogHeader,
  DialogBody,
  DialogButton,
} from './confirmation-dialog.styled';

const ConfirmationDialog = props => (
  <StyledModalDialog
    isOpen={props.isOpen}
    onBackgroundClick={props.onCancel}
    onEscapeKeydown={props.onCancel}
  >
    <DialogContainer>
      <DialogHeader>{props.header}</DialogHeader>
      <DialogBody>
        <span>
          {props.message}
        </span>
        <span>
          <DialogButton onClick={props.onConfirm}>
            {'Yes'}
          </DialogButton>
          <DialogButton onClick={props.onCancel}>
            {'No'}
          </DialogButton>
        </span>
      </DialogBody>
    </DialogContainer>
  </StyledModalDialog>
);

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  header: PropTypes.string,
  message: PropTypes.string,
};

export default ConfirmationDialog;
