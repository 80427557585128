// image.view/index.js

// Modules
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';

// Local components
import {ImageManagerWrapper} from './image-manager.styled';
import {ContentWrapper} from '../views.styled';
import ImageManagerMenu from './image-manager-menu.view';
import ImageListSection from './image-manager-list.view';
import ImageManagerSection from './image-manager-section.view';
import ImageUploaderSection from './image-uploader-section.view';

import DeleteImageDialog from './image-manager-dialog.view';

// Actions and selectors
import {actions as imageActions} from '../../state/images.redux';
import {allowedImageNameSpaces} from '../../constants';
import {createSelectorUrlPath} from './image-manager.selectors';
import ImageManagerStart from './image-manager-start.view';

const ImageManager = props => {
  // Fetch ALL images
  useEffect(() => {
    _.each(allowedImageNameSpaces, nameSpace => props.fetchImages(nameSpace));
  }, []);

  return (
    <ContentWrapper>
      <ImageManagerWrapper>
        <DeleteImageDialog />
        {props.urlPath !== 'upload' && props.urlPath !== 'edit'
          ? <ImageManagerStart />
          : <ImageManagerMenu />
        }
        {props.urlPath === 'upload' && <ImageUploaderSection />}
        {props.urlPath === 'edit' &&
        <React.Fragment>
          <ImageManagerSection />
          <ImageListSection />
        </React.Fragment>
        }
      </ImageManagerWrapper>
    </ContentWrapper>
  );
};

const mapStateToProps = (state, props) => ({
  imageType: state.views.imageManager.imageType,
  urlPath: createSelectorUrlPath(state, props),
});

const mapDispatchToProps = dispatch => ({
  fetchImages: imageType => dispatch(imageActions.fetchImages(imageType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageManager);
