// device-edit.selectors.js

import {createSelector} from 'reselect';
import {allowedImageNameSpaces} from '../../../constants';
import {map, last, first, split, pipe} from 'lodash/fp';

const selectAllDeviceIcons = state => state.images[allowedImageNameSpaces.DEVICE_ICON];

const createDeviceIconsSelector = createSelector(
  selectAllDeviceIcons,
  deviceIcons => (
    map(icon => {
      const label = pipe(
        split('/'),
        last,
        split('.'),
        first,
      )(icon);
      
      return {label, value: icon};
    }, deviceIcons)
  )
);

export {
  createDeviceIconsSelector,
}