// combined-views-reducer.js
// This is basically a manifest of all views' reducers
// The aim is to keep this as flat as possible

import {combineReducers} from 'redux';

import {reduce as common} from './views.redux';
import {reduce as create} from './create.view/create.redux';
import {reduce as chart} from './chart.view/chart.redux';

import {reduce as imageManager} from './image-manager.view/image-manager.redux';

import {reduce as location} from './location.view/location.redux';
import {reduce as locationEdit} from './location.view/location-edit.view/location-edit.redux';
import {reduce as blueprintCreate} from './location.view/blueprint-create.view/blueprint-create.redux';
import {reduce as blueprintEditor} from './location.view/blueprint-edit.view/blueprint-edit.redux';
import {reduce as deviceEditor} from './location.view/device-edit.view/device-edit.redux';
import {reduce as locationMenu} from './location.view/location-menu.view/location-menu.redux';
import {reduce as locationBlueprint} from './location.view/location-blueprint.view/location-blueprint.redux';
import {reduce as devicePlacementLayer} from './location.view/device-placement-layer.view/device-placement-layer.redux';

import {reduce as locations} from './locations.view/locations.redux';
import {reduce as locationsMenu} from './locations.view/locations-menu.view/locations-menu.redux';
import {reduce as locationsMap} from './locations.view/locations-map.view/locations-map.redux';

import {reduce as login} from './login.view/login.redux';

export default combineReducers({
  common,
  create,
  chart,
  imageManager,
  location,
  locationEdit,
  locationMenu,
  blueprintCreate,
  deviceEditor,
  blueprintEditor,
  locationBlueprint,
  devicePlacementLayer,
  locations,
  locationsMenu,
  locationsMap,
  login,
});
