// blueprint-create.styled.js

import styled from 'styled-components';
import {colors, NAVBAR_HEIGHT} from '../../views.constants';

const StyledBlueprintCreateView = styled.div`
  position: relative;
  margin-top: ${NAVBAR_HEIGHT};
  width: 100%;
  height: 100%;
  padding: 50px;
  background: ${colors.GREY_LIGHT_ALT};
`;

const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40vh;
`;

const StyledBlueprintImg = styled.img`
  display: flex;
  max-width: 100%;
  max-height: 40vh;
`;

export {
  StyledBlueprintCreateView,
  StyledIconContainer,
  StyledBlueprintImg,
  StyledImgContainer,
}
