// blueprint-create.view/index.js

// Modules
import React from 'react';
import {connect} from 'react-redux';
import {get} from 'lodash';
import {toast} from 'react-toastify';

// local components
import {FlexWrapper, HorizontalLine} from '../../views.styled';
import {SelectInput, TextInput} from '../../../components/inputs';
import {UploadFileButton} from '../../views-menu-buttons.styled';
import {CancelationButton, SuccessButton} from '../../views-buttons';
import {
  StyledIconContainer,
  StyledBlueprintImg,
  StyledImgContainer,
} from './blueprint-create.styled';
import {StyledLocationView, StyledButtonsContainer} from '../location.styled';

// utils
import {allowedImageNameSpaces} from '../../../constants';
import {getBlueprintImageUrl} from '../../views.utils';
import config from '../../../config';

// state
import {actions as viewActions} from './blueprint-create.redux';
import {actions as locationActions} from '../location.redux';
import {actions as imageActions} from '../../../state/images.redux';
import {
  createBlueprintImagesSelector,
  selectCreateBlueprintDuplicateNameError,
} from './blueprint-create.selectors';
import {
  selectCurrentLocationId,
  selectCurrentLocation,
  selectCurrentLocationBlueprintCreator,
} from '../location.selectors';

const BlueprintCreateView = (
  {
    currentLocation,
    saveBlueprintCreator,
    updateBlueprintCreator,
    setView,
    cancelBlueprintCreator,
    fetchImages,
    uploadImage,
    images,
    currentLocationId,
    blueprintCreator,
    duplicateNameError,
  }
) => (
  <StyledLocationView>
    <h4>New blueprint</h4>
    <HorizontalLine />
    <FlexWrapper>
      <div>
        <TextInput
          label={'Name'}
          value={get(blueprintCreator, 'name', '')}
          onChange={({target: {value}}) => {
            const updates = {...blueprintCreator, name: value};
            updateBlueprintCreator(currentLocationId, updates);
          }}
        />
        <StyledIconContainer>
          <SelectInput
            label={'Blueprint'}
            value={get(blueprintCreator, 'image', '')}
            options={images}
            onFocus={() => fetchImages(allowedImageNameSpaces.BLUEPRINT)}
            onChange={({value}) => {
              const updates = {...blueprintCreator, image: value};
              updateBlueprintCreator(currentLocationId, updates);
            }}
          />
          <UploadFileButton
            isUploading={false}
            onChange={file => uploadImage(file, allowedImageNameSpaces.BLUEPRINT)}
          />
        </StyledIconContainer>
      </div>
      <StyledImgContainer>
        <StyledBlueprintImg src={getBlueprintImageUrl(get(blueprintCreator, 'image'))} />
      </StyledImgContainer>
    </FlexWrapper>
    <HorizontalLine />
    <StyledButtonsContainer>
      <SuccessButton
        onClick={() => {
          const blueprintName = get(blueprintCreator, 'name');
          const hasInvalidCharacters = !/^[a-zA-Z0-9åäöÅÄÖ _-]+$/.test(blueprintName);
          if (hasInvalidCharacters) {
            toast.error('Invalid characters in blueprint name.');
            return;
          }
          if (duplicateNameError) {
            toast.error('There already exists a blueprint with that name. Please choose a different name.');
            return;
          }
          toast.success(`Blueprint created: ${blueprintCreator.name}`, config.toaster);
          saveBlueprintCreator(currentLocation, blueprintCreator);
          setView(null);
        }}
      >
        Create blueprint
      </SuccessButton>
      <CancelationButton
        onClick={() => {
          cancelBlueprintCreator(currentLocationId);
          setView(null);
        }}
      >
        Cancel
      </CancelationButton>
    </StyledButtonsContainer>
  </StyledLocationView>
);

const mapStateToProps = state => ({
  images: createBlueprintImagesSelector(state),
  blueprintFormData: state.views.blueprintCreate.blueprintFormData,
  currentLocationId: selectCurrentLocationId(state),
  currentLocation: selectCurrentLocation(state),
  blueprintCreator: selectCurrentLocationBlueprintCreator(state),
  duplicateNameError: selectCreateBlueprintDuplicateNameError(state),
});

const mapDispatchToProps = dispatch => ({
  cancelBlueprintCreator: locationId => {
    dispatch(viewActions.cancelBlueprintCreator(locationId));
  },
  updateBlueprintCreator: (locationId, updates) => {
    dispatch(viewActions.updateBlueprintCreator(locationId, updates));
  },
  saveBlueprintCreator: (location, blueprint) => {
    dispatch(viewActions.saveBlueprintCreator(location, blueprint));
  },
  fetchImages: imageType => dispatch(imageActions.fetchImages(imageType)),
  uploadImage: image => dispatch(imageActions.uploadImage(image, allowedImageNameSpaces.BLUEPRINT)),
  setView: view => dispatch(locationActions.setView(view))
});

export default connect(mapStateToProps, mapDispatchToProps)(BlueprintCreateView);
