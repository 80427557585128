import React from 'react';
import {BaseButton} from './styled';
import {colors} from '../../views/views.constants';

const Button = ({value, ...rest}) => (
  <BaseButton {...rest} >
    {value}
  </BaseButton>
);

const SubmitButton = props => (
  <Button
    {...props}
    width={'200px'}
    color={colors.GREY_DARK}
    hoverColor={colors.BLACK}
    background={colors.GREY_LIGHT}
    hoverBackground={colors.GREY_LIGHT_ALT}
    value={'LOGIN'}
  />
);

export {
  SubmitButton,
}
