// session-checker.js

// parses the cookie, and decodes cookie.token
import _ from 'lodash';
import React, {useEffect} from 'react';
import jwt from 'jsonwebtoken';
import cookie from 'cookie';
import config from '../config';

const SessionChecker = props => {
  const getSession = () => {
    const parsedCookie = cookie.parse(document.cookie);
    if (!parsedCookie.token) {
      return null;
    }
    // decode the token
    let decodedToken;
    try {
      decodedToken = jwt.decode(parsedCookie.token);
    } catch (err) {
      return null;
    }
    const session = {
      userId: decodedToken._id,
      ..._.pick(decodedToken, ['iat', 'exp']),
    };
    // check the expiry date
    const now = Date.now() / 1000;
    if (now > session.exp) {
      return null;
    }
    return session;
  };
  useEffect(() => {
    // parse the cookie
    const session = getSession();
    if (!_.isEqual(session, props.session)) {
      props.setSession(session);
    }
    if (!session) {
      window.location.href = `${config.yggio.controlPanelUrl}/access/signin`;
    }
  });
  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
};

export default SessionChecker;
