// confirmation-dialog.styled.js

import styled from 'styled-components';

import {Button} from '../views.styled';
import {colors} from '../views.constants';

const DialogContainer = styled.div`
  position: absolute;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const DialogHeader = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
  padding: 10px;
  background: darkgreen;
  color: ${colors.GREY_LIGHT};
  width: 100%;
  height: 15%;
  top: 0;
  font-size: 1.2em;
  font-weight: bold;
`;

const DialogBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 80%;
`;

const DialogButton = styled(Button)`
  width: 100px;
  height: 30px;
  background: green;
  color: ${colors.GREY_LIGHT};
  margin: 20px;
  transition: background 0.2s;

  &:hover {
    background: rgba(36,84,168,0.77);
    transition: background 0.2s;
  }
`;

export {
  DialogContainer,
  DialogHeader,
  DialogBody,
  DialogButton,
};
