// api-utility.js

import cookie from 'cookie';
import config from '../config';

const getToken = () => {
  const parsed = cookie.parse(document.cookie);
  return parsed.token || '';
};

const getYggioUrl = () => config.yggio.url;

export {
  getToken,
  getYggioUrl,
};
