// locations-menu.redux.js

import _ from 'lodash';

import {actions as locationsActions} from '../../../state/locations.redux';
import {LOCATION_SIMPLE_EDIT_PROPS, editLocationDetails} from '../../../state/location-editor.utility';

const actionTypes = {
  LOCATIONS_SET_PAGE: 'LOCATIONS_SET_PAGE',
  LOCATION_EDITS_BEGIN: 'LOCATION_EDITS_BEGIN',
  LOCATION_EDITS_END: 'LOCATION_EDITS_END',
  LOCATION_EDITS_UPDATE: 'LOCATION_EDITS_UPDATE',
  LOCATIONS_SET_FILTER_TEXT: 'LOCATIONS_SET_FILTER_TEXT',
};

const cancelEditing = locationId => ({
  type: actionTypes.LOCATION_EDITS_END,
  payload: {locationId},
});

const actions = {
  setPage: page => ({
    type: actionTypes.LOCATIONS_SET_PAGE,
    payload: {page},
  }),
  beginEditing: location => ({
    type: actionTypes.LOCATION_EDITS_BEGIN,
    payload: {location},
  }),

  updateLocationEdits: (locationId, locationEdits) => ({
    type: actionTypes.LOCATION_EDITS_UPDATE,
    payload: {locationId, locationEdits},
  }),
  cancelEditing,
  saveLocationEdits: (location, edits) => dispatch => {
    const modified = editLocationDetails(location, edits);
    return dispatch(locationsActions.updateLocation(modified))
      .then(() => dispatch(cancelEditing(location._id)));
  },
  deleteLocation: locationsActions.deleteLocation,
  setLocationsFilterText: locationsFilterText => ({
    type: actionTypes.LOCATIONS_SET_FILTER_TEXT,
    payload: {locationsFilterText},
  }),
};

const defaultState = {
  edits: {},
  page: 0,
  pageSize: 10,
  locationsFilterText: '',
};

const reduce = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.LOCATIONS_SET_PAGE: {
      const {page} = action.payload;
      return {...state, page};
    }
    case actionTypes.LOCATION_EDITS_BEGIN: {
      const {location} = action.payload;
      const edits = {
        ...state.edits,
        [location._id]: _.pick(location, LOCATION_SIMPLE_EDIT_PROPS),
      };
      return {...state, edits};
    }
    case actionTypes.LOCATION_EDITS_END: {
      const {locationId} = action.payload;
      return {...state, edits: _.omit(state.edits, locationId)};
    }
    case actionTypes.LOCATION_EDITS_UPDATE: {
      const {locationId, locationEdits} = action.payload;
      const edits = {
        ...state.edits,
        [locationId]: {
          ...state.edits[locationId],
          ...locationEdits,
        }
      };
      return {...state, edits};
    }
    case actionTypes.LOCATIONS_SET_FILTER_TEXT: {
      const {locationsFilterText} = action.payload;
      return {...state, locationsFilterText};
    }
    default: {
      return state;
    }
  }
};

export {
  actionTypes,
  actions,
  reduce,
};
