// location.styled.js

// Modules
import styled from 'styled-components';

// local stuff
import {colors, NAVBAR_HEIGHT} from '../views.constants';

const LocationContainer = styled.div`
  position: relative;
  /* border-style: solid;
  border-width: 5px;
  border-color: red; */
  background: #000;
  width: calc(100% - 23vw);
  margin-left: 23vw;
  height: 100vh;
  overflow: hidden;

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin: 0;
  }

  /*
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  @media (min-width: 1025px) and (max-width: 1280px) {
      width: calc(100% - 26vw);
      margin-left: 26vw;
  }
`;

// All layers are contained in absolutely positioned layers
const LocationContainerLayer = styled.div`
  position: absolute;
  top: ${NAVBAR_HEIGHT};
  left: 0;
  width: 100%;
  height: 100%;
`;

const LocationNotFoundContainer = styled.div`
  height: 100vh;
  width: 100%;
  color: ${colors.GREY_LIGHT};
  background: darksalmon;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 300px;
`;

const StyledLocationView = styled.div`
  position: relative;
  margin-top: ${NAVBAR_HEIGHT};
  width: 100%;
  height: 100%;
  padding: 50px;
  background: ${colors.GREY_LIGHT_ALT};
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 60%;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40vh;
`;

export {
  LocationContainer,
  LocationContainerLayer,
  LocationNotFoundContainer,
  StyledLocationView,
  StyledButtonsContainer,
  StyledImgContainer,
  StyledIconContainer,
};
