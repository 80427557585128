// locations-map.view/index.js

// modules
import React, {useEffect, useRef} from 'react';
import {withRouter} from 'react-router-dom';
import L from 'leaflet';
import {connect} from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import {map, isEmpty, includes} from 'lodash/fp';
import {TileLayer} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';

// local components
import * as constants from '../../views.constants';
import LocationsMarker from './locations-marker.view';
import {
  MarkerClusterIcon,
  PlacementModeHeader,
  StyledMap,
  StyledContextMenu,
  StyledContextMenuButton
} from './locations-map.styled';

// actions & selectors
import {selectLocationsCentroid} from './locations-map.selectors';
import {actions as viewActions} from './locations-map.redux';
import {actions as createActions} from '../../create.view/create.redux';
import {actions as deviceActions} from '../../../state/devices.redux';
import {selectFilteredLocations} from '../locations.selectors';
import {colors} from '../../views.constants';
import {routes} from '../../views.routes';

const LocationsMap = props => {
  const mapRef = useRef({current: null});
  const clusterRef = useRef({current: null});
  const positions = map(data => [data.lat, data.lng], props.locations);

  useEffect(() => {
    props.fetchDevices();
  }, []);

  useEffect(() => {
    const mapInstance = mapRef.current.leafletElement;
    if (!isEmpty(positions) && mapInstance) {
      const initialZoom = mapInstance.getBoundsZoom(positions);
      props.setInitialZoom(initialZoom);
    }
  });

  useEffect(() => {
    const mapInstance = mapRef.current.leafletElement;
    if (mapInstance) {
      const center = mapInstance.getCenter();
      const viewport = {
        center: [center.lat, center.lng],
        zoom: props.initialZoom,
      };
      props.updateViewPort(viewport);
    }
  }, [props.initialZoom]);

  const createClusterIcon = cluster => {
    const markers = cluster.getAllChildMarkers();
    const statusColors = map(marker => marker.options.icon.options.color, markers);
    const color = () => {
      if (includes(colors.RED, statusColors)) return colors.RED;
      if (includes(colors.YELLOW, statusColors)) return colors.YELLOW;
      return colors.GREEN;
    };

    const html = ReactDOMServer.renderToString(
      <MarkerClusterIcon color={color()}>
        <b>{cluster.getChildCount()}</b>
      </MarkerClusterIcon>
    );

    return L.divIcon({
      html,
      iconSize: null
    });
  };

  return (
    <>
      <StyledMap
        ref={mapRef}
        isInPlacementMode={props.isInPlacementMode}
        zoomControl={false}
        center={props.viewPort ? props.viewPort.center : props.locationsCentroid}
        zoom={props.viewPort ? props.viewPort.zoom : constants.DEFAULT_MAP_ZOOM}
        minZoom={3}
        maxZoom={17}
        bounds={!isEmpty(positions) ? positions : null}
        maxBounds={[
          [-90, -180],
          [90, 180]
        ]}
        dragging
        onViewportChanged={viewPort => {
          const clusterInstance = clusterRef.current.leafletElement;
          if (clusterInstance) clusterInstance.refreshClusters();
          props.updateViewPort(viewPort);
        }}
        onContextMenu={(evt) => {
          const {latlng} = evt;
          const {clientY, clientX} = evt.originalEvent;
          props.setContextMenu({x: clientX, y: clientY, latlng});
        }}
        onClick={(e) => {
          if (!!props.contextMenu) {
            props.setContextMenu(null);
          }
        }}
      >

        <TileLayer
          noWrap
          attribution={constants.LEAFLET_ATTRIBUTION}
          url={constants.LEAFLET_URL}
        />
        {props.isInPlacementMode && <PlacementModeHeader>DRAG ITEM TO MOVE</PlacementModeHeader>}
        <MarkerClusterGroup
          ref={clusterRef}
          maxClusterRadius={50}
          iconCreateFunction={cluster => createClusterIcon(cluster)}
        >
          {map(location => (
            <LocationsMarker
              key={location._id}
              locationId={location._id}
            />
          ), props.locations)}
        </MarkerClusterGroup>
      </StyledMap>

      {props.contextMenu && (
        <StyledContextMenu
          top={props.contextMenu.y}
          left={props.contextMenu.x}
        >
          <StyledContextMenuButton
            onClick={() => {
              const {lat, lng} = props.contextMenu.latlng;
              props.modifyLocationForm({...props.newLocation, lat, lng})
              props.history.push(routes.CREATE);
              props.setContextMenu(null);
            }}
          >
            + Create location here
          </StyledContextMenuButton>
        </StyledContextMenu>
      )}
    </>
  )
}

// /////
// /// Redux
// /////

const mapStateToProps = state => ({
  newLocation: state.views.create.location,
  locations: selectFilteredLocations(state),
  locationsCentroid: selectLocationsCentroid(state),
  viewPort: state.views.locationsMap.viewPort,
  isInPlacementMode: state.views.locations.isInPlacementMode,
  initialZoom: state.views.locationsMap.initialZoom,
  contextMenu: state.views.locationsMap.contextMenu,
});

const mapDispatchToProps = dispatch => ({
  updateViewPort: viewPort => dispatch(viewActions.updateViewPort(viewPort)),
  fetchDevices: () => dispatch(deviceActions.fetchDevices()),
  setInitialZoom: zoom => dispatch(viewActions.setInitialZoom(zoom)),
  setContextMenu: contextMenu => dispatch(viewActions.setContextMenu(contextMenu)),
  modifyLocationForm: location => dispatch(createActions.modifyLocationForm(location)),
});

// /////
// /// Export
// /////

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationsMap));
