import styled from 'styled-components';
import {colors} from '../../views/views.constants';

const StyledInput = styled.input`
  display: flex;
  align-items: center;
  height: 35px;
  width: 100%;
  padding: 5px 5px 5px 50px;
  margin: 15px 0 15px;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom: 2px solid ${colors.GREY};
  border-left: none;
  border-right: none;
  border-top: none;
  outline: none;
  background: ${colors.GREY_LIGHT};
  color: ${colors.GREY_DARK};
  transition: all 0.3s;

  &:focus {
    background: ${colors.GREY_LIGHT_ALT};
    border-bottom: 2px solid ${colors.GREY_LIGHT};
    transition: all 0.3s;
  }
`;

const InputPrepender = styled.div`
  position: absolute;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  color: ${colors.GREY_DARK}
  height: 45px;
  width: 45px;
  margin: 20px 0 15px;
`;

const InputLabel = styled.span`
  position: absolute;
  font-size: ${({focused, hasValue}) => focused || hasValue ? '0.7em' : '0.8em'};
  margin-top: ${({focused, hasValue}) => focused || hasValue ? '-2px' : '25px'};
  margin-left: ${({focused, hasValue}) => focused || hasValue ? '10px' : '50px'};;
  color: ${colors.GREY_ALT};
  transition: all 0.3s;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin: 2px;
`;

const InputValidator = styled.span`
  position: absolute;
  color: ${colors.MEDIUM_GREEN};
  opacity: ${({valid}) => valid ? 1 : 0}
  margin-top: 20px;
  right: 5px;
  transition: all 0.3s;
`;

export {
  StyledInput,
  InputPrepender,
  InputLabel,
  InputWrapper,
  InputValidator,
}
