// location-buttons-layer.view.js

// Modules
import styled from 'styled-components';

import {LocationsContainerLayer} from '../locations.styled';

const LocationsButtonsLayer = styled(LocationsContainerLayer)`
  position: absolute;
  display: flex;
  pointer-events: none;
  left: 23vw;
  
  @media screen and (max-width: 1024px) {
    left: 20px;
  }
  
  /*
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  @media (min-width: 1025px) and (max-width: 1280px) {
      left: 26vw;
  }
`;

export {
  LocationsButtonsLayer,
};
