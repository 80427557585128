import styled from 'styled-components';
import {colors} from '../views.constants';

const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const LoginContainer = styled.div`
  border-radius: 5px;
  background: ${colors.RACING_GREEN};
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  width: 40%;
  height: 35%;

  @media screen and (max-width: 1024px) {
    width: 75%;
  }
`;

const InputsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const YggioImage = styled.img`
  width: 80px;
  height: 80px;
`;

export {
  LoginWrapper,
  LoginContainer,
  InputsWrapper,
  YggioImage,
}
