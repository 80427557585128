
// loction-blueprint-buttons.view.js

// Modules
import React from 'react';
import {connect} from 'react-redux';
import {lt, gt} from 'lodash/fp';

// Local components
import {
  LocationsButtonsLayer,
} from './locations-buttons-layer.styled';
import {
  ButtonGroup,
  EmplaceButton,
  ResetButton,
  ZoomInButton,
  ZoomOutButton,
} from '../../views-buttons';
import * as constants from '../../views.constants';

// Actions and selectors
import {actions as mapActions} from '../locations-map.view/locations-map.redux';
import {actions as viewActions} from '../locations.redux';
import {selectLocationsCentroid} from '../locations-map.view/locations-map.selectors';


const LocationsButtons = props => (
  <LocationsButtonsLayer>
    <ButtonGroup>
      <EmplaceButton
        onClick={() => props.setLocationPlacementMode(!props.isInPlacementMode)}
      />
      {!props.isInPlacementMode && (
        <ResetButton
          onClick={() => {
            props.updateViewPort({
              center: props.locationsCentroid,
              zoom: props.initialZoom,
            });
          }}
        />
      )}
      {!props.isInPlacementMode && (
        <ZoomInButton
          onClick={() => {
            if (lt(props.viewPort.zoom, 17)) {
              props.updateViewPort({center: props.viewPort.center, zoom: props.viewPort.zoom + 1});
            }
          }}
        />
      )}
      {!props.isInPlacementMode && (
        <ZoomOutButton
          onClick={() => {
            if (gt(props.viewPort.zoom, 2)) {
              props.updateViewPort({center: props.viewPort.center, zoom: props.viewPort.zoom - 1});
            }
          }}
        />
      )}
    </ButtonGroup>
  </LocationsButtonsLayer>
);

const mapStateToProps = state => ({
  initialZoom: state.views.locationsMap.initialZoom,
  viewPort: state.views.locationsMap.viewPort,
  locationsCentroid: selectLocationsCentroid(state),
  isInPlacementMode: state.views.locations.isInPlacementMode,
});

const mapDispatchToProps = dispatch => ({
  updateViewPort: viewPort => dispatch(mapActions.updateViewPort(viewPort)),
  toggleLocationsMenu: () => dispatch(viewActions.toggleLocationsMenu()),
  setLocationPlacementMode: mode => dispatch(viewActions.setLocationPlacementMode(mode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationsButtons);
