// image-manager-dialog.view.js

// Modules
import React from 'react';
import {connect} from 'react-redux';

// Local components
import ConfirmationDialog from '../confirmation-dialog.view';

// Actions
import {actions as viewActions} from './image-manager.redux';

const DeleteImageDialog = props => (
  <ConfirmationDialog
    isOpen={props.isOpen}
    onCancel={props.toggleDialog}
    onConfirm={() => {
      props.deleteImage(props.imageToManage, props.imageType);
      props.toggleDialog();
    }}
    header={props.imageName}
    message={'Are you sure you want to delete this image?'}
  />
);

const mapStateToProps = state => ({
  isOpen: state.views.imageManager.isDialogOpen,
  imageType: state.views.imageManager.imageType,
  imageName: state.views.imageManager.imageName,
  imageToManage: state.views.imageManager.imageToManage,
});

const mapDispatchToProps = dispatch => ({
  toggleDialog: () => dispatch(viewActions.toggleDialogState()),
  deleteImage: (image, nameSpace) => dispatch(viewActions.deleteImage(image, nameSpace)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteImageDialog);
