// navbar.styled.js

// modules
import styled from 'styled-components';
import React from 'react';
import cookie from 'js-cookie';
import config from '../../config';

// local
import {routes,} from '../views.routes';
import {
  colors,
  NAVBAR_HEIGHT,
  YGGIO_LOGO,
} from '../views.constants';
import {StyledLink} from '../views.styled';

const Bar = styled.div`
  z-index: 999;
  position: fixed;
  height: ${NAVBAR_HEIGHT};
  width: 100%;
  background: ${colors.RACING_GREEN};
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  position: absolute;
  padding-left: 30px;
  height: ${NAVBAR_HEIGHT};
  width: 100px;
  color: ${colors.WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;

  img {
    height: 30px;
    margin-right: 10px;
  }

  @media screen and (max-width: 1024px) {
    padding-left: 0;
    span {
      display: none;
    }
  }
`;

const NavTitle = () => (
  <Title>
    <img src={YGGIO_LOGO} alt="Yggio" /> <span>Yggio</span>
  </Title>
);

const Menu = styled.div`
  display: flex;
  align-items: center;
  height: ${NAVBAR_HEIGHT};
  width: 100%;
  margin-left: 150px;

  @media screen and (max-width: 1024px) {
    margin-left: 80px;
  }
`;

const LinkStyle = styled.div`
  display: flex;
  align-items: center;
  height: ${NAVBAR_HEIGHT};
  width: 100px;
  cursor: pointer;
  color: ${colors.GREY_ALT};
  transition: color 0.2s, background 0.2s;
  font-size: ${props => props.fontSize};

  @media screen and (max-width: 1024px) {
    display: none;
  }


  a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #EEE;
    width: 100%;
    height: 100%;
    transition: color 0.2s;
  }

  a:hover {
    color: #333;
    transition: color 0.2s;
  }

  &:hover {
    color: #333;
    background: ${colors.GREY_LIGHT};
    transition: color 0.2s, background 0.2s;
  }
`;

const TabletLinkStyle = styled.div`
  display: flex;
  align-items: center;
  height: ${NAVBAR_HEIGHT};
  width: 100px;
  font-size: 1.8em;
  cursor: pointer;
  transition: color 0.2s, background 0.2s;

  @media screen and (min-width: 1024px) {
    display: none;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #EEE;
    width: 100%;
    height: 100%;
    transition: color 0.2s, padding-left 0.2s;

  }

  a:hover {
    color: #333;
    transition: color 0.2s, padding-left 0.2s;
  }

  &:hover {
    color: #333;
    background: ${colors.GREY_LIGHT};
    transition: color 0.2s, background 0.2s;
  }
`;

const StyledToggleLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: ${NAVBAR_HEIGHT};
  width: 100%;
  font-size: 1.8em;
  color: #EEE;
  cursor: pointer;
  transition: color 0.2s, background 0.2s;
  margin-right: 10px;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

const StyledLogoutLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: ${NAVBAR_HEIGHT};
  width: 100%;
  font-size: 1.0em;
  color: #EEE;
  cursor: pointer;
  transition: color 0.2s, background 0.2s;
  margin-right: 10px;

  span {
    display: block;
  }

  div {
      display: none;
    }

  @media screen and (max-width: 1024px) {
    position: absolute;
    right: 40px;

    span {
      display: none;
    }

    div {
      font-size: 1.8em;
      display: block;
    }
  }
`;

const NavLink = ({link, name, icon, paddingLeft, fontSize}) => (
  <React.Fragment>

    <LinkStyle paddingLeft={paddingLeft} fontSize={fontSize}>
      <StyledLink to={link} color={colors.GREY_LIGHT}>
        <span>
          {name}
        </span>
      </StyledLink>
    </LinkStyle>

    <TabletLinkStyle>
      <StyledLink to={link} color={colors.GREY_LIGHT}>
        <i className={icon} />
      </StyledLink>
    </TabletLinkStyle>

  </React.Fragment>
);

const LocationsLink = () => (
  <NavLink
    link={routes.LOCATIONS}
    name={'Locations'}
    icon={'fas fa-map-marked-alt'}
  />
);

const ImageManagerLink = () => (
  <NavLink
    link={routes.IMAGE_MANAGER}
    name={'Images'}
    icon={'fas fa-images'}
  />
);

const ToggleLink = ({toggleMenu}) => (
  <StyledToggleLink
    onClick={() => toggleMenu()}
  >
    <i className="fas fa-bars" />
  </StyledToggleLink>
);

const LogoutLink = () => (
  <StyledLogoutLink onClick={() => {
    cookie.remove('token', {domain: '.local.yggio'});
    window.location.href = `${config.yggio.controlPanelUrl}/access/signin`;
  }}>
    <span>Logout</span>
    <div><i class="fas fa-sign-out-alt"></i></div>
  </StyledLogoutLink>
);

export {
  Bar,
  NavTitle,
  Menu,
  // nav links
  LocationsLink,
  ImageManagerLink,
  ToggleLink,
  LogoutLink,
};
