// location.view.js

// Modules
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {isEqual} from 'lodash';

// Local components
import {createBlueprintUrl} from '../views.routes';
import {
  LocationContainer,
  LocationNotFoundContainer,
} from './location.styled';
import LocationButtonsLayer from './location-buttons-layer.view';
import DeviceItemsLayer from './device-placement-layer.view';
import LocationMenu from './location-menu.view';
import LocationBlueprint from './location-blueprint.view';
import LocationEdit from './location-edit.view';
import BlueprintCreateView from './blueprint-create.view';
import BlueprintEditor from './blueprint-edit.view';
import DeviceEditor from './device-edit.view';

// utils
import {LOCATION_VIEWS} from '../views.constants';

// selectors and actions
import {
  // url location stuff
  selectCurrentLocation,
  selectUrlLocation,
  // url blueprint stuff
  selectCurrentBlueprint,
  selectUrlBlueprint,
  // subscription stuff
  selectAllFreshSubscriptions,
  selectAllDeadSubscriptions,
} from './location.selectors';
import {actions as commonActions} from '../views.redux';
import {actions as subscriptionActions} from '../../state/subscriptions.redux';
import {actions as imageActions} from '../../state/images.redux';
import {actions as locationActions} from './location.redux';

const LocationWrapper = props => {
  useEffect(() => {
    props.setView(null);
  }, []);

  // enable location-name url navigation
  useEffect(() => {
    // if !urlLocation then we just give up
    if (!props.urlLocation) {
      return;
    }
    // set the current location if necessary
    if (props.urlLocation !== props.currentLocation) {
      props.setCurrentLocationId(props.urlLocation._id);
    }
    // set the currentBlueprintId upon disagreement with url
    if (props.urlBlueprint && props.urlBlueprint !== props.currentBlueprint) {
      props.setCurrentBlueprintId(props.urlBlueprint._id);
    }
    // if urlBlueprint is missing, then either urlBlueprintName is missing or
    // invalid. if so, pick the a blueprint and renavigate
    if (!props.urlBlueprint) {
      const blueprint = props.currentBlueprint || props.urlLocation.defaultLayer;
      if (blueprint !== props.currentBlueprint) {
        props.setCurrentBlueprintId(blueprint._id);
      }
      const url = createBlueprintUrl(props.urlLocation.name, blueprint.name);
      props.history.push(url);
    }
  });

  // fetch all blueprints, this might be moved
  useEffect(() => {
    props.fetchBlueprintImages();
  }, []);

  useEffect(() => {
    // check if we need add some subscriptions
    if (props.freshSubscriptions.length) {
      // add some subscriptions
      props.addSubscriptions(props.freshSubscriptions);
    }
    // check if we need to remove some subscriptions
    if (props.deadSubscriptions.length) {
      // remove some subscriptions
      props.removeSubscriptions(props.deadSubscriptions);
    }
  });

  return props.urlLocation
    ? <Location
      currentBlueprint={props.currentBlueprint}
      currentView={props.currentView}
      history={props.history}
    />
    : <LocationNotFoundContainer
      name={props.urlLocationName}
    />;
};

const Location = props => {
  const renderComponent = () => {
    if (isEqual(props.currentView, LOCATION_VIEWS.blueprintEditor)) return <BlueprintEditor />;
    if (isEqual(props.currentView, LOCATION_VIEWS.blueprintCreator)) return <BlueprintCreateView/>;
    if (isEqual(props.currentView, LOCATION_VIEWS.locationEditor)) return <LocationEdit />;
    if (isEqual(props.currentView, LOCATION_VIEWS.deviceEditor)) return <DeviceEditor />;

    return (
      <>
        <LocationBlueprint />
        <DeviceItemsLayer />
        <LocationButtonsLayer />
      </>
    )
  };

  return (
    <>
      <LocationContainer>
        {renderComponent()}
      </LocationContainer>
      <LocationMenu history={props.history} />
    </>
  );
};

// history gets passed in from router
LocationWrapper.propTypes = {
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => ({
  // location info
  currentLocation: selectCurrentLocation(state),
  urlLocation: selectUrlLocation(state, props),
  // blueprint info
  currentBlueprint: selectCurrentBlueprint(state),
  urlBlueprint: selectUrlBlueprint(state, props),
  // subscriptions info
  freshSubscriptions: selectAllFreshSubscriptions(state, props),
  deadSubscriptions: selectAllDeadSubscriptions(state, props),
  currentView: state.views.location.currentView,
});

const mapDispatchToProps = dispatch => ({
  fetchBlueprintImages: () => dispatch(imageActions.fetchBlueprintImages()),
  // set location from url navigation. this is the only place
  // where setCurrentLocationId & setCurrentBlueprintId should be used
  setCurrentLocationId: locationId => dispatch(commonActions.setCurrentLocationId(locationId)),
  setCurrentBlueprintId: blueprintId => dispatch(commonActions.setCurrentBlueprintId(blueprintId)),
  // useEffect --> add/remove subscriptions
  addSubscriptions: deviceIds => dispatch(subscriptionActions.addSubscriptions(deviceIds)),
  removeSubscriptions: deviceIds => dispatch(subscriptionActions.removeSubscriptions(deviceIds)),
  setView: view => dispatch(locationActions.setView(view)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationWrapper);
