// location-item.js

import {pick, get, truncate, map} from 'lodash';
import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

// local components
import {createLocationUrl} from '../../views.routes';
import {
  MenuSectionItem,
  ItemField,
} from '../../views-menu.styled';
import {
  DeleteButton,
  MapPointerButton,
} from '../../views-menu-buttons.styled';
import {StyledLocationInfo, StyledLocationSubInfo} from '../locations.styled';
import {FlexWrapper} from '../../views.styled';
import {colors} from '../../views.constants';

// selectors and actions
import {
  createSelectorLocation,
  createSelectorTotalNumBlueprintsInLocation,
  createSelectorTotalNumDevicesInLocation, selectLocationDevices,
} from '../locations.selectors';
import {selectEdits} from './locations-menu.selectors';
import {actions as mapviewActions} from '../locations-map.view/locations-map.redux';
import {actions as viewsActions} from '../../views.redux';
import {actions as viewActions} from './locations-menu.redux';
import {selectLocationsMapCurrentZoom} from '../locations-map.view/locations-map.selectors';
import {actions as locationEditActions} from '../../location.view/location-edit.view/location-edit.redux';

const LocationItem = props => (
  <Link
    style={{textDecoration: 'none'}}
    to={createLocationUrl(props.currentLocation.name)}
  >
    <MenuSectionItem>

      <ItemField title={props.currentLocation.name.length > 18 ? props.currentLocation.name : null}>
        <StyledLocationInfo>
          {truncate(props.currentLocation.name, {length: 18})}
          <StyledLocationSubInfo>{get(props, 'totalNumBlueprints', '0')} blueprints
            - {props.totalNumDevices} devices</StyledLocationSubInfo>
        </StyledLocationInfo>
      </ItemField>

      <FlexWrapper>
        <MapPointerButton
          onClick={evt => {
            evt.preventDefault();
            props.setPopupLocationId(props.currentLocation._id);
            props.updateViewPort({
              center: pick(props.currentLocation, ['lat', 'lng']),
              zoom: props.zoom,
            });
          }}/>
        <DeleteButton onClick={() => props.setDialogState(props.currentLocation._id)}/>
      </FlexWrapper>

    </MenuSectionItem>
    {props.devices && map(props.devices, (device) => (
      <MenuSectionItem
        key={device._id}
        height={'20px'}
        background={colors.GREY_DARK}
      >
        {truncate(device.name)}
      </MenuSectionItem>
    ))}
  </Link>
);


// ///
//
// / Redux
//
// ///

// account for props passed in from above
LocationItem.propTypes = {
  locationId: PropTypes.string,
  isEditing: PropTypes.bool,
};

const makeMapStateToProps = () => {
  const selectLocation = createSelectorLocation();
  const selectTotalNumDevicesInLocation = createSelectorTotalNumDevicesInLocation();
  const selectTotalNumBlueprints = createSelectorTotalNumBlueprintsInLocation();
  const selectDevices = selectLocationDevices();
  return (state, props) => ({
    zoom: selectLocationsMapCurrentZoom(state),
    currentLocation: selectLocation(state, props),
    totalNumDevices: selectTotalNumDevicesInLocation(state, props),
    totalNumBlueprints: selectTotalNumBlueprints(state, props),
    edits: selectEdits(state),
    devices: selectDevices(state, props),
  });
};

const mapDispatchToProps = dispatch => ({
  setDialogState: locationId => dispatch(viewsActions.setDialogState(locationId)),
  deleteLocation: locationId => dispatch(viewActions.deleteLocation(locationId)),
  // external actions (common & mapview)
  updateViewPort: viewPort => dispatch(mapviewActions.updateViewPort(viewPort)),
  setPopupLocationId: locationId => dispatch(mapviewActions.setPopupLocationId(locationId)),
  toggleLocationEdit: bool => dispatch(locationEditActions.toggleLocationEdit(bool)),
});

// /////
//
// /// Export
//
// /////

export default withRouter(connect(makeMapStateToProps, mapDispatchToProps)(LocationItem));
