// blueprint-device-item.view.js

// Modules
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Collapse} from 'reactstrap';
import {map, isEmpty, truncate} from 'lodash/fp';

// Local components
import {
  MenuSectionLabel,
  MenuSectionItem,
  MenuSectionItem2,
  ItemField,
  MenuSectionDescription,
  MenuSubsectionLabel,
} from '../../views-menu.styled';
import {
  ChartButton,
  EditButton,
  CompressButton,
  ExpandButton,
} from '../../views-menu-buttons.styled';
import {LOCATION_VIEWS, colors} from '../../views.constants';
import {StyledTruncateTextWrapper} from '../../views.styled';

// Selectors & Actions
import {
  createSelectorSimpleDeviceItemEdits,
  createSelectorDeviceItemIsExpanded,
} from './location-menu.selectors';
import {actions as deviceEditorActions} from '../device-edit.view/device-edit.redux';
import {actions as locationActions} from '../location.redux';
import {actions as commonActions} from '../../views.redux';
import {actions as menuActions} from './location-menu.redux';
import {selectAllCurrentLocationDevicesValues} from '../location.selectors';
import {StyledDeviceValues} from '../location-blueprint.view/location-blueprint.styled';

const getName = props => {
  return props.deviceItem.name || '(no name)';
}

const BlueprintDeviceItem = props => (
  <>
    <MenuSectionItem height={50}>
      <StyledTruncateTextWrapper title={getName(props)}>
        <MenuSectionLabel label={getName(props)} />
      </StyledTruncateTextWrapper>
      <ItemField >
        <div
          onClick={evt => {
            const {deviceId} = props.deviceItem;
            props.history.push(`/charts/${deviceId}`);
            evt.stopPropagation();
          }}>
          <ChartButton />
        </div>
        <EditButton onClick={evt => {
            evt.stopPropagation();
            props.setView(LOCATION_VIEWS.deviceEditor);
            props.setCurrentDeviceId(props.deviceItem._id);
            props.beginDeviceEdits(props.deviceItem);
            props.toggleMenu();
          }} />
        {!props.isExpanded
          ? <ExpandButton onClick={() => props.expandDevice(props.deviceItem._id)} />
          : <CompressButton onClick={() => props.compressDevice(props.deviceItem._id)} />
        }

      </ItemField>

    </MenuSectionItem>
    <Collapse isOpen={!!props.isExpanded}>
      <StyledDeviceValues>
        <MenuSectionDescription
          background={colors.MEDIUM_GREEN}
          description={'Values'}
        />
        {isEmpty(props.currentLocationDevices[props.deviceItem.deviceId]) && (
          <MenuSectionItem height={'30'}>
            <MenuSubsectionLabel label={'(no values)'} />
            <ItemField />
          </MenuSectionItem>
        )}
        {map(val => (
          <MenuSectionItem2
            height={'30'}
            key={val.name + val.value}
          >
            <MenuSubsectionLabel label={truncate({length: 25}, val.name)} />
            <ItemField style={{fontSize: '12px'}}>
              {truncate({length: 20}, val.value)}{val.unit}
            </ItemField>
          </MenuSectionItem2>
        ), props.currentLocationDevices[props.deviceItem.deviceId])}
      </StyledDeviceValues>
    </Collapse>
  </>
);

// ///
// Redux
// ///

// just documenting the fact that propTypes should be sent in
BlueprintDeviceItem.propTypes = {
  deviceItem: PropTypes.object,
};

const makeMapStateToProps = () => {
  const selectSimpleDeviceItemEdits = createSelectorSimpleDeviceItemEdits();
  const selectDeviceItemIsExpanded = createSelectorDeviceItemIsExpanded();
  return (state, props) => ({
    deviceItemEdits: selectSimpleDeviceItemEdits(state, props),
    isExpanded: selectDeviceItemIsExpanded(state, props),
    currentLocationDevices: selectAllCurrentLocationDevicesValues(state),
  });
};

const mapDispatchToProps = dispatch => ({
  toggleMenu: () => dispatch(commonActions.toggleMenu()),
  beginDeviceEdits: deviceItem => dispatch(deviceEditorActions.beginDeviceEdits(deviceItem)),
  setView: view => dispatch(locationActions.setView(view)),
  setCurrentDeviceId: deviceId => dispatch(commonActions.setCurrentDeviceId(deviceId)),
  expandDevice: deviceId => {
    dispatch(menuActions.expandDevice(deviceId));
  },
  compressDevice: deviceId => {
    dispatch(menuActions.compressDevice(deviceId));
  }
});

export default withRouter(connect(makeMapStateToProps, mapDispatchToProps)(BlueprintDeviceItem));
