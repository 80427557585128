// image-manager.styled.js

// Modules
import styled from 'styled-components';
import Slider from 'react-slick';
import {Icon} from 'react-icons-kit';
import React from 'react';
import {Link} from 'react-router-dom';
import {chevronRight as nextIcon} from 'react-icons-kit/fa/chevronRight';
import {chevronLeft as prevIcon} from 'react-icons-kit/fa/chevronLeft';

// Local components
import {colors, NAVBAR_HEIGHT} from '../views.constants';
import {Button} from '../views.styled';

const ImageManagerWrapper = styled.div`
  display: grid;
  grid-template-columns: 30% 50% 20%;
  grid-template-rows: 22% 18% 60%;
  width: 100%;
  height: 100vh;

  @media screen and (max-width: 700px) {
    grid-template-rows: 30% 20% 50%;
  }

  @media screen and (max-width: 1024px) and (max-height: 700px) {
    grid-template-rows: 35% 20% 45%;
  }
`;

const StyledUploaderHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0 20px;
  min-width: 400px;

  p {
    margin: 0;
  }
`;

const StyledImageManagerStart = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 4;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 5em;
    font-weight: 1000;
  }
`;

const StyledStartWrapper = styled.div`
  display: flex;
`;

const StyledEntryBox = styled(Link)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px;
  border-radius: 5px;
  width: 250px;
  height: 250px;
  background: ${colors.LIGHT_GREEN};
  transition: background 0.2s, color 0.2s;
  color: ${colors.GREY_LIGHT};
  font-size: 2em;
  font-weight: 1000;

  &:hover {
    text-decoration: none;
    color: ${colors.WHITE};
    background: ${colors.DARK_GREEN};
    transition: background 0.2s, color 0.2s;
  }

  @media screen and (max-width: 1024px) {
    width: 120px;
    height: 120px;
    font-size: 1.5em !important;
  }
`;

const StyledImageManager = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #EEE;
  padding: 30px;
`;

const StyledImageUploader = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 4;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    margin-top: 10px;
  }
`;

const StyledDropzone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${props => props.color};
  width: 50%;
  height: 50%;
  border: 2px dashed ${colors.LIGHT_GREEN};
  background: ${props => props.background};
  transition: background 0.3s, color 0.3s;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    color: ${colors.GREY_DARK};
    transition: color 0.3s;
  }

  @media screen and (max-width: 900px) {
    width: 70%;
  }
`;

const StyledImageInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: 10px;
`;

const StyledImageListSection = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #EEE;

  h2 {
    margin: 10px 0 0;
    font-weight: 1000;
  }

  h6 {
    margin-top: 20px;
  }
`;

const StyledImageListItems = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.img`
  cursor: pointer;
  width: auto !important;
  height: 100px;
  max-width: 100px;
  margin: 5px;
  border: 2px dashed ${colors.GREY_DARK};

  &:hover {
    border: 2px solid ${colors.GREY_DARK};
  }

  @media screen and (max-width: 1024px) {
    height: 70px;
    font-size: 1.5em !important;
  }
`;

const StyledInput = styled.input`
  height: 50px;
  width: 40%;
  border: 2px solid ${colors.GREY_DARK};
  border-radius: 5px;
  background: ${colors.GREY_LIGHT};
  margin: 5px;
  padding: 5px;

  @media screen and (max-width: 1024px) {
    width: 90%;
  }
`;

const StyledButton = styled(Button)`
  min-width: 250px;
  width: 50%;
  height: 50px;
  font-weight: 1000;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 5px;
  border-radius: 5px;
`;

const StyledSubmitButton = styled(StyledButton)`
  color: ${colors.GREY_DARK};
  background: chartreuse;
  transition: background 0.2s, color 0.2s;

  &:hover {
    color: ${colors.BLACK}
    background: greenyellow;
    transition: background 0.2s, color 0.2s;
  }
`;

const StyledCancelButton = styled(StyledButton)`
  color: ${colors.GREY_DARK};
  background: indianred;
  transition: background 0.2s, color 0.2s;

  &:hover {
    color: ${colors.BLACK}
    background: #ff2828;
    transition: background 0.2s, color 0.2s;
  }
`;

const StyledUploaderButtonsWrapper = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const StyledSelectedImage = styled.img`
  max-width: 95%;
  max-height: 60%;
  margin: 5px;
`;

const StyledImageToManage = styled.img`
  min-height: 10%;
  min-width: 10%;
  max-height: 70%;
  max-width: 70%;
  margin: 10px;
`;

const StyledEditLabel = styled.label`
  font-weight: 1000;
  margin: 0;
`;

const StyledEditImageRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98%;
`;

const StyledImageMenuContainer = styled.div`
  z-index: 99;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledImageMenuSection = styled.div`
  margin-top: ${NAVBAR_HEIGHT};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;


const StyledImageMainMenuSection = styled(StyledImageMenuSection)`
  -webkit-box-shadow: 0 3px 10px 0 rgba(0,0,0,0.75);
  -moz-box-shadow: 0 3px 10px 0 rgba(0,0,0,0.75);
  box-shadow: 0 3px 10px 0 rgba(0,0,0,0.75);
`;

const StyledImageSubMenuSection = styled(StyledImageMenuSection)`
  margin-top: 20px;
  flex-direction: column;
`;

const StyledMainMenuButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 50px;
  color: ${props => props.color};
  background: ${props => props.background};
  transition: background 0.2s, color 0.2s;
  font-weight: 1000;
  margin: 10px;
  border-radius: 3px;


  &:hover {
    color: ${colors.WHITE};
    text-decoration: none;
    background: ${colors.RACING_GREEN};
    transition: background 0.2s, color 0.2s;
  }
`;

const StyledMenuButton = styled(Button)`
  width: 200px;
  height: 40px;
  color: ${props => props.color};
  background: ${props => props.background};
  transition: background 0.2s, color 0.2s;
  font-weight: 1000;
  margin: 10px;
  border-radius: 3px;

  &:hover {
    color: ${colors.WHITE};
    background: ${colors.DARK_GREEN};
    transition: background 0.2s, color 0.2s;
  }

  @media screen and (max-width: 600px) {
    width: calc(33% - 20px);
    height: 50px;
    font-size: 0.7em !important;
  }
`;

const StyledSlider = styled(Slider)`
  width: 75%;

  .slick-slide {
    display: flex;
    justify-content: center;
  }
  .slick-arrow {
    border-radius: 50%;
    background: black;
  }
`;

const StyledArrow = styled(Icon)`
  cursor: pointer;
  display: block;
  position: absolute;
  top: 25%;
  left: ${props => props.left};
  right: ${props => props.right};
  color: ${colors.LIGHT_GREEN};
  transition: color 0.2s;

  &:hover {
    color: ${colors.DARK_GREEN};
    transition: color 0.2s;
  }
`;

const SliderNextArrow = props => {
  const {onClick, style} = props;
  return (
    <StyledArrow
      style={{...style}}
      right={'-50px'}
      icon={nextIcon}
      size={50}
      onClick={onClick} />
  );
};

const SliderPrevArrow = props => {
  const {onClick, style} = props;
  return (
    <StyledArrow
      style={{...style}}
      left={'-50px'}
      icon={prevIcon}
      size={50}
      onClick={onClick} />
  );
};

export {
  StyledImageListSection,
  StyledUploaderHeader,
  StyledImageListItems,
  ImageManagerWrapper,
  StyledImageManagerStart,
  StyledStartWrapper,
  StyledEntryBox,
  StyledImageManager,
  StyledImageUploader,
  StyledDropzone,
  StyledImageInfo,
  StyledImage,
  StyledInput,
  StyledSubmitButton,
  StyledCancelButton,
  StyledUploaderButtonsWrapper,
  StyledSelectedImage,
  StyledImageToManage,
  StyledEditImageRow,
  StyledImageMenuContainer,
  StyledImageMenuSection,
  StyledImageSubMenuSection,
  StyledImageMainMenuSection,
  StyledMainMenuButton,
  StyledMenuButton,
  StyledEditLabel,
  StyledSlider,
  StyledArrow,
  SliderNextArrow,
  SliderPrevArrow,
};
