// chart.redux.js

import {timePeriodTypes} from './chart.constants';
import {
  measurementTypes,
  knownMeasurements,
} from '../views.constants';

const actionTypes = {
  CHART_SELECT_MEASUREMENT_TYPE: 'CHART_SELECT_MEASUREMENT_TYPE',
  CHART_SELECT_TIME_PERIOD: 'CHART_SELECT_TIME_PERIOD',
  CHART_SELECT_RESOLUTION: 'CHART_SELECT_RESOLUTION',
  CHART_SET_CUSTOM_TIME_PERIOD_FROM_TIME: 'CHART_SET_CUSTOM_TIME_PERIOD_FROM_TIME',
  CHART_SET_CUSTOM_TIME_PERIOD_TO_TIME: 'CHART_SET_CUSTOM_TIME_PERIOD_TO_TIME',
  CHART_SET_CUSTOM_TIME_PERIOD_APPLIED_FROM_TIME: 'CHART_SET_CUSTOM_TIME_PERIOD_APPLIED_FROM_TIME',
  CHART_SET_CUSTOM_TIME_PERIOD_APPLIED_TO_TIME: 'CHART_SET_CUSTOM_TIME_PERIOD_APPLIED_TO_TIME',
};

const actions = {
  selectMeasurement: measurement => ({
    type: actionTypes.CHART_SELECT_MEASUREMENT_TYPE,
    payload: {measurement}
  }),
  selectTimePeriod: timePeriodType => ({
    type: actionTypes.CHART_SELECT_TIME_PERIOD,
    payload: {timePeriodType}
  }),
  selectResolution: resolution => ({
    type: actionTypes.CHART_SELECT_RESOLUTION,
    payload: {resolution}
  }),
  setCustomTimePeriodFromTime: time => ({
    type: actionTypes.CHART_SET_CUSTOM_TIME_PERIOD_FROM_TIME,
    payload: {time}
  }),
  setCustomTimePeriodToTime: time => ({
    type: actionTypes.CHART_SET_CUSTOM_TIME_PERIOD_TO_TIME,
    payload: {time}
  }),
  setCustomTimePeriodAppliedFromTime: time => ({
    type: actionTypes.CHART_SET_CUSTOM_TIME_PERIOD_APPLIED_FROM_TIME,
    payload: {time}
  }),
  setCustomTimePeriodAppliedToTime: time => ({
    type: actionTypes.CHART_SET_CUSTOM_TIME_PERIOD_APPLIED_TO_TIME,
    payload: {time}
  }),
};

const defaultState = {
  selectedMeasurement: knownMeasurements[measurementTypes.TEMPERATURE],
  selectedTimePeriod: timePeriodTypes.DAY,
  selectedResolution: 'low',
  customTimePeriodFromTime: '',
  customTimePeriodToTime: '',
  customTimePeriodAppliedFromTime: '',
  customTimePeriodAppliedToTime: '',
};

const reduce = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.CHART_SELECT_MEASUREMENT_TYPE: {
      const {measurement} = action.payload;
      return {
        ...state,
        selectedMeasurement: measurement,
      };
    }
    case actionTypes.CHART_SELECT_TIME_PERIOD: {
      const {timePeriodType} = action.payload;
      return {
        ...state,
        selectedTimePeriod: timePeriodType,
      };
    }
    case actionTypes.CHART_SELECT_RESOLUTION: {
      const {resolution} = action.payload;
      return {
        ...state,
        selectedResolution: resolution,
      };
    }
    case actionTypes.CHART_SET_CUSTOM_TIME_PERIOD_FROM_TIME: {
      const {time} = action.payload;
      return {
        ...state,
        customTimePeriodFromTime: time,
      };
    }
    case actionTypes.CHART_SET_CUSTOM_TIME_PERIOD_TO_TIME: {
      const {time} = action.payload;
      return {
        ...state,
        customTimePeriodToTime: time,
      };
    }
    case actionTypes.CHART_SET_CUSTOM_TIME_PERIOD_APPLIED_FROM_TIME: {
      const {time} = action.payload;
      return {
        ...state,
        customTimePeriodAppliedFromTime: time,
      };
    }
    case actionTypes.CHART_SET_CUSTOM_TIME_PERIOD_APPLIED_TO_TIME: {
      const {time} = action.payload;
      return {
        ...state,
        customTimePeriodAppliedToTime: time,
      };
    }
    default: {
      return state;
    }
  }
};

export {
  actions,
  reduce,
};
