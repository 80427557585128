import styled from 'styled-components';
import {colors} from '../../views/views.constants';

const BaseButton = styled.button`
  cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
  height: 35px;
  margin: 15px 0 0;
  width: ${({width}) => width || '150px'};
  outline: none;
  opacity: ${({disabled}) => disabled ? '0.1' : '1'};
  border: 1px solid ${colors.GREY_LIGHT};
  border-radius: 1px;
  background: ${({background}) => background || colors.GREY_LIGHT};
  color: ${({color}) => color || colors.WHITE};
  transition: all 0.3s;

  &:focus {
    filter: brightness(140%);
    transition: all 0.3s;
  }

  &:hover {
    background: ${({disabled, background, hoverBackground}) => {
      if (disabled) {
        return background;
      }
      return hoverBackground || colors.GREY_LIGHT;
    }};
    color: ${({disabled, color, hoverColor}) => {
      if (disabled) {
        return color;
      }
      return hoverColor || colors.WHITE;
    }};
    transition: all 0.3s;
  }
`;

export {
  BaseButton,
}
