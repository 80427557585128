// create.redux.js

import {
  isString,
  isNumber,
  mapValues,
  lte,
} from 'lodash';
import {toast} from 'react-toastify';
import {actions as locationActions} from '../../state/locations.redux';
import {
  INPUT_NAME_MAX_LENGTH,
  INPUT_DESC_MAX_LENGTH,
} from '../../constants';

const actionTypes = {
  LOCATION_CREATOR_MODIFY: 'LOCATION_CREATOR_MODIFY',
  LOCATION_CREATOR_RESET: 'LOCATION_CREATOR_RESET',
  LOCATION_CREATOR_VIEWPORT: 'LOCATION_CREATE_VIEWPORT',
  LOCATION_CREATOR_DISABLE_SUBMIT_BUTTON: 'LOCATION_CREATOR_DISABLE_SUBMIT_BUTTON'
};

const resetForm = () => ({
  type: actionTypes.LOCATION_CREATOR_RESET,
});

const actions = {
  disableSubmitButton: submitButtonDisabled => ({
    type: actionTypes.LOCATION_CREATOR_DISABLE_SUBMIT_BUTTON,
    payload: {submitButtonDisabled},
  }),
  modifyLocationForm: location => ({
    type: actionTypes.LOCATION_CREATOR_MODIFY,
    payload: {location},
  }),
  submitLocationForm: (history, location) => dispatch => (
    dispatch(locationActions.createLocation(location))
      .then(() => {
        toast.success('Location created!');
        history.push('/'); // Redirect to locations list
        dispatch(resetForm());
      })
  ),

  updateViewPort: viewPort => ({
    type: actionTypes.LOCATION_CREATOR_VIEWPORT,
    payload: {viewPort},
  }),
};

const locationValidators = {
  name: val => {
    // Removes all accents and diacritics from string
    const newVal = val ? val.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : val;
    return (isString(val) && !!val.length && lte(val.length, INPUT_NAME_MAX_LENGTH) && /^[a-zA-Z0-9åäöÅÄÖ _-]+$/.test(newVal))
  },
  description: val => (isString(val) && !!val.length && lte(val.length, INPUT_DESC_MAX_LENGTH)),
  lng: val => (isNumber(val) && val >= -180 && val <= 180),
  lat: val => (isNumber(val) && val >= -90 && val <= 90),
};
const validateLocation = location => (
  mapValues(locationValidators, (validate, key) => validate(location[key]))
);

const defaultState = {
  location: {
    name: '',
    description: '',
    lng: '',
    lat: '',
  },
  isValidLocation: {
    name: false,
    description: false,
    lng: false,
    lat: false,
  },
  viewPort: null,
  submitButtonDisabled: false,
};

const reduce = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.LOCATION_CREATOR_DISABLE_SUBMIT_BUTTON: {
      const {submitButtonDisabled} = action.payload;
      return {...state, submitButtonDisabled}
    }

    case actionTypes.LOCATION_CREATOR_MODIFY: {
      const {location} = action.payload;
      const isValidLocation = validateLocation(location);
      return {...state, location, isValidLocation};
    }

    case actionTypes.LOCATION_CREATOR_RESET: {
      return defaultState;
    }

    case actionTypes.LOCATION_CREATOR_VIEWPORT: {
      const {viewPort} = action.payload;
      return {...state, viewPort};
    }

    default: {
      return state;
    }
  }
};

export {
  resetForm,
  defaultState,
  validateLocation,
  actionTypes,
  actions,
  reduce,
};
