// create.styled.js

// Modules
import styled from 'styled-components';
import {Map} from 'react-leaflet';

// External modules
import {colors, NAVBAR_HEIGHT} from '../views.constants';
import {Button} from '../views.styled';

const InputContent = styled.div`
  width: 100%;
  height: calc(100% - ${NAVBAR_HEIGHT});
  padding: 50px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${NAVBAR_HEIGHT};

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
  margin-top: 10px;
  width: 100%;
  label {
    font-weight: bold;
  }

  @media screen and (max-width: 1024px) {
    margin-top: 0;
  }
`;

const StyledMap = styled(Map)`
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

const MapRow = styled.div`
  width: calc(100% - 23vw);
  height: calc(100vh - ${NAVBAR_HEIGHT});
  background: #000;
  padding: 0;
  margin-left: 23vw;
  margin-top: ${NAVBAR_HEIGHT};
  & .creator-marker {
    background: pink;
    border-radius: 5px;
    -webkit-transition: background 0.2s;
    -moz-transition: background 0.2s;
    -ms-transition: background 0.2s;
    -o-transition: background 0.2s;
    transition: background 0.2s;
  }

  @media screen and (max-width: 1024px) {
    width: 100% !important;
    height: 100vh;
    margin: 0;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 2.5px;
  border: 1px solid ${props => props.border};
  border-left: 10px solid ${props => props.border};
  background: ${colors.WHITE};
  outline: none;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const SubmitButton = styled(Button)`
  border-radius: 5px;
  width: 100%;
  height: 50px;
  color: ${colors.GREY_LIGHT};
  margin: 10px;
  background: ${props => props.background};
  transition: background 0.2s;

  &:hover {
    background: ${props => props.backgroundHover};
    transition: background 0.2s;
  }
`;

export {
  InputContent,
  InputRow,
  Input,
  StyledMap,
  MapRow,
  SubmitButton,
};
