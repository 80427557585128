import {combineReducers} from 'redux';

import views from '../views/combined-views-reducer';
import {reduce as locations} from './locations.redux';
import {reduce as devices} from './devices.redux';
import {reduce as subscriptions} from './subscriptions.redux';
import {reduce as images} from './images.redux';
import {reduce as statistics} from './device-statistics.redux';
import {reduce as session} from './session.redux';

export default combineReducers({
  views,
  locations,
  devices,
  subscriptions,
  images,
  statistics,
  session,
});
