// image-manager.redux.js

// Modules
import _ from 'lodash';
import delay from 'delay';
import {toast} from 'react-toastify';

// Local
import {allowedImageNameSpaces} from '../../constants';
import {actions as imageActions} from '../../state/images.redux';
import config from '../../config';

const actionTypes = {
  IMAGE_DROPZONE_DRAG_ENTER: 'IMAGE_DROPZONE_DRAG_ENTER',
  IMAGE_DROPZONE_DRAG_LEAVE: 'IMAGE_DROPZONE_DRAG_LEAVE',
  IMAGE_TYPE_SELECT: 'IMAGE_TYPE_SELECT',
  IMAGE_SELECT: 'IMAGE_SELECT',
  IMAGE_MANAGE: 'IMAGE_MANAGE',
  IMAGE_SET_NAME: 'IMAGE_SET_NAME',
  IMAGE_RESET_NAME: 'IMAGE_RESET_NAME',
  IMAGE_EDIT_NAME: 'IMAGE_EDIT_NAME',
  IMAGE_SAVE_EDITED_NAME: 'IMAGE_SAVE_EDITED_NAME',
  IMAGE_SET_UPLOADING: 'IMAGE_SET_UPLOADING',
  IMAGE_DELETE: 'IMAGE_DELETE',
  IMAGE_DIALOG_STATE: 'IMAGE_DIALOG_STATE',
};

const setNewFileName = (imageFile, imageName) => {
  const extension = _.last(_.split(imageFile.type, '/'));
  return new File(
    [imageFile],
    `${imageName}.${extension}`,
    {type: imageFile.type}
  );
};

const resetImageName = () => ({
  type: actionTypes.IMAGE_RESET_NAME,
});

const actions = {
  setOnDragEnter: () => ({
    type: actionTypes.IMAGE_DROPZONE_DRAG_ENTER,
  }),
  setOnDragLeave: () => ({
    type: actionTypes.IMAGE_DROPZONE_DRAG_LEAVE,
  }),
  selectImageType: imageType => ({
    type: actionTypes.IMAGE_TYPE_SELECT,
    payload: {imageType},
  }),
  selectImage: (imageUrl, image) => ({
    type: actionTypes.IMAGE_SELECT,
    payload: {imageUrl, image},
  }),
  selectImageToManage: image => ({
    type: actionTypes.IMAGE_MANAGE,
    payload: {image},
  }),
  editImageName: imageName => ({
    type: actionTypes.IMAGE_EDIT_NAME,
    payload: {imageName},
  }),
  setImageName: imageName => ({
    type: actionTypes.IMAGE_SET_NAME,
    payload: {imageName},
  }),
  saveEditedImageName: (oldImageName, newImageName, nameSpace) => dispatch => (
    dispatch(imageActions.editImage(oldImageName, newImageName, nameSpace))
      .then(() => {
        toast.success(`Image name successfully changed!`, config.toaster);
        dispatch({type: actionTypes.IMAGE_SAVE_EDITED_NAME});
      })
  ),
  addNewImage: (imageFile, imageName, nameSpace) => async dispatch => {
    dispatch({
      type: actionTypes.IMAGE_SET_UPLOADING,
      payload: {isImageUploading: true},
    });

    const file = imageName !== ''
      ? setNewFileName(imageFile, imageName)
      : imageFile;

    await delay(500);

    // do the actual uploads
    return dispatch(imageActions.uploadImage(file, nameSpace))
      .then(image => {
        toast.success(`Image successfully uploaded!`, config.toaster);
        return image;
      })
      .catch(err => console.error(err))
      .finally(() => {
        dispatch(resetImageName());
        dispatch({
          type: actionTypes.IMAGE_SET_UPLOADING,
          payload: {isImageUploading: false},
        });
      });
  },
  deleteImage: (image, nameSpace) => dispatch => (
    dispatch(imageActions.deleteImage(image, nameSpace))
      .then(() => {
        toast.success(`Image successfully deleted!`, config.toaster);
        dispatch({
          type: actionTypes.IMAGE_DELETE,
        });
      })
  ),
  toggleDialogState: () => ({
    type: actionTypes.IMAGE_DIALOG_STATE,
  }),
  resetImageName: () => dispatch => dispatch(resetImageName()),
};

const defaultState = {
  dropZoneHover: false,
  imageType: allowedImageNameSpaces.BLUEPRINT,
  imageSelected: {
    url: null,
    file: null,
  },
  imageToManage: null,
  imageName: '',
  newImageName: '',
  isImageUploading: false,
  isDialogOpen: false,
};

const reduce = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.IMAGE_DROPZONE_DRAG_ENTER: {
      return {...state, dropZoneHover: true};
    }

    case actionTypes.IMAGE_DROPZONE_DRAG_LEAVE: {
      return {...state, dropZoneHover: false};
    }

    case actionTypes.IMAGE_TYPE_SELECT: {
      const {imageType} = action.payload;
      return {...state, imageType, imageToManage: null};
    }

    case actionTypes.IMAGE_SELECT: {
      const {imageUrl, image} = action.payload;
      return {...state, imageSelected: {url: imageUrl, image}};
    }

    case actionTypes.IMAGE_MANAGE: {
      const {image} = action.payload;
      return {...state, imageToManage: image};
    }

    case actionTypes.IMAGE_EDIT_NAME: {
      const {imageName} = action.payload;
      return {...state, imageName};
    }

    case actionTypes.IMAGE_SET_NAME: {
      const {imageName} = action.payload;
      return {...state, newImageName: imageName};
    }

    case actionTypes.IMAGE_SAVE_EDITED_NAME: {
      return {
        ...state,
        imageName: defaultState.imageName,
        imageToManage: null,
      };
    }

    case actionTypes.IMAGE_SET_UPLOADING: {
      const {isImageUploading} = action.payload;
      return {
        ...state,
        isImageUploading,
      };
    }

    case actionTypes.IMAGE_RESET_NAME: {
      return {...state, imageSelected: defaultState.imageSelected, newImageName: defaultState.newImageName};
    }

    case actionTypes.IMAGE_DELETE: {
      return {...state, imageToManage: null};
    }

    case actionTypes.IMAGE_DIALOG_STATE: {
      return {...state, isDialogOpen: !state.isDialogOpen};
    }

    default:
      return state;
  }
};

export {
  actionTypes,
  actions,
  reduce,
};
