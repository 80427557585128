// state/store.js

import {createStore, compose, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {createLogger} from 'redux-logger';
import rootReducer from './root-reducer';
import {eq} from 'lodash/fp';

import {socket} from '../api';
import {actions as subscriptionsActions} from './subscriptions.redux';
import {actions as deviceActions} from './devices.redux';
import {actions as locationsActions} from './locations.redux';

const middleware = [thunk];
if (eq(process.env.NODE_ENV, 'development')) {
  middleware.push(createLogger());
}

const composeEnhancer = (eq(process.env.NODE_ENV, 'development') &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(...middleware)),
);

// initialize the socket callbacks
socket.setSubscriptionListener((deviceId, state) => (
  store.dispatch(subscriptionsActions.updateSubscription(deviceId, state))
));
socket.setDeviceListener(device => {
  const devices = store.getState().devices;
  return store.dispatch(deviceActions.replaceDevice(device, devices));
});

export default store;
