// views-menu.styled.js

// Modules
import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import ReactSelect from 'react-select';
import {Collapse} from 'reactstrap';

// Local
import {colors, NAVBAR_HEIGHT} from './views.constants';

const SUBSECTION_PADDING = 8;

const SideBar = styled.div`
  z-index: 9999;
  position: fixed;
  width: 23vw;
  top: ${NAVBAR_HEIGHT};
  height: calc(100vh - ${NAVBAR_HEIGHT});
  transition: left 0.4s;

  @media screen and (max-width: 1024px) {
    z-index: 999;
    width: 100%;
    height: 100%;
    left: ${props => (props.isOpen ? '0' : '100vw')}
  }

  /*
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  @media (min-width: 1025px) and (max-width: 1280px) {
      width: 26vw;
  }
`;

const Menu = styled(SideBar)`
  overflow-y: auto;
  color: ${colors.WHITE};
  background: ${colors.GREY_DARK_ALT};
  padding: 10px 10px 100px;
`;

const MenuHeader = styled.div`
  display: flex;
  margin: 30px 0 5px 2px;
  width: 100%;
  color: ${colors.MENU_HEADER_TEXT};

  label {
    font-size: 1.1em;
    font-weight: 500;
  }
`;

const MenuSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 0px;
  width: 100%;
`;

const MenuSubsectionOuter = styled(Collapse)`
  width: 100%;
  background: ${colors.SUBSECTION_BACKGROUND};
  border-bottom: 1px solid ${colors.GREY_DARK};
  padding: ${SUBSECTION_PADDING}px;
`;

const MenuSubsectionInner = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 0 0 5px 5px;
  border: 1px solid ${colors.GREY_DARK};
`;

const MenuSubsection = props => (
  <MenuSubsectionOuter isOpen={props.isOpen}>
    <MenuSubsectionInner>
      {props.children}
    </MenuSubsectionInner>
  </MenuSubsectionOuter>
);

const MenuSubsectionHeader = styled.div`
  align-text: center;
  width: 100%;
  background: ${colors.SUBSECTION_HEADER_BACKGROUND};
  color: ${colors.SUBSECTION_HEADER_COLOR};
  font-size: 15px;
  font-weight: 1000;
  padding: 4px 6px;
`;

const ItemField = styled.div`
  display: flex;
  align-items: center;
  width: ${props => props.maxWidth && 100}%;
  font-weight: ${props => props.fontWeight || 500};
`;

const MenuSectionLabel = props => (
  <ItemField
    fontWeight={500}
    style={{
      fontSize: 14,
    }}
  >
    {props.label}
  </ItemField>
);

const MenuSubsectionLabel = props => (
  <ItemField
    fontWeight={800}
    style={{
      fontSize: 12,
    }}
  >
    {props.label}
  </ItemField>
);

const SectionItemTemplate = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: ${props => props.minHeight};
  height: ${props => props.height};
  padding: 15px;
  margin: ${props => props.margin || '5px 0 5px'}
  color: ${props => props.color}
  background: ${props => props.background};
  transition: background 0.2s;
  border-top: ${props => props.borderTop || 'none'};
  border-radius: ${props => props.borderRadius || 'none'};

  &:hover {
    transition: background 0.2s;
    background: ${props => props.hoverColor};
  }
`;

const MenuSectionItem = props => (
  <SectionItemTemplate
    color={props.color || colors.SECTION_ITEM_TEXT}
    background={props.background || (props.isCurrentBlueprint ? colors.SECTION_ITEM_HOVER_BG : colors.SECTION_ITEM_BACKGROUND)}
    hoverColor={props.hoverColor || colors.SECTION_ITEM_HOVER_BG}
    height={props.height || `${_.get(props, 'height', 70)}px`}
    onClick={props.onClick}
    borderRadius={'3px'}
    margin={'7px 0 0'}
  >
    {props.children}
  </SectionItemTemplate>
);

const MenuSectionItem2 = props => (
  <SectionItemTemplate
    color={props.color || colors.SECTION_ITEM_TEXT}
    //background={'#333'}
    background={colors.GREY_DARK}
    hoverColor={props.hoverColor || colors.SECTION_ITEM_HOVER_BG}
    height={`${_.get(props, 'height', 70)}px`}
    onClick={props.onClick}
    borderRadius={'3px'}
    margin={'7px 0 0'}
  >
    {props.children}
  </SectionItemTemplate>
);

const MenuSectionDescription = props => (
  <SectionItemTemplate
    color={props.color || colors.SECTION_DESCRIPTION_TEXT}
    background={props.background || colors.SECTION_ITEM_HOVER_BG}
    height={'25px'}
    margin={'0'}
    borderTop={`1px solid ${colors.GREY_ALT}`}
  >
    <ItemField fontWeight={500}>
      {props.description}
    </ItemField>
  </SectionItemTemplate>
);

const MenuSubsectionItem = props => (
  <SectionItemTemplate
    color={colors.SUBSECTION_ITEM_TEXT}
    background={colors.SECTION_ITEM_HOVER_BG}
    height={`${props.height || 55}px`}
    onClick={props.onClick}
    margin={'0'}
    borderTop={`1px solid ${colors.GREY_ALT}`}
  >
    {props.children}
  </SectionItemTemplate>
);

const Input = styled.input`
  outline: none;
  border: none;
  padding: 0 10px;
  margin-bottom: 2px;
  font-size: 14px;
  height: 35px;
  color: ${({color}) => color || colors.GREY_LIGHT};
  background: ${({background}) => background} !important;
  width: 100%;
`;

const MenuSubsectionInput = props => (
  <Input
    maxWidth={props.maxWidth}
    width={props.width}
    min={props.min}
    max={props.max}
    background={props.background}
    color={props.color}
    type={props.type || 'text'}
    value={props.value}
    onChange={props.onChange}
    placeholder={props.placeholder}
  />
);

const Select = styled(ReactSelect)`
    height: 40px;
    margin: ${({style}) => style?.margin || '0'}

    div {
      border: none;
      background: ${({background}) => background} !important;
    };

  /*
    ##Device = Desktops
    ##Screen = 1281px to higher resolution desktops
  */
  @media (min-width: 1481px) {
    width: ${props => props.width || '200'}px;
  }
  @media (min-width: 1281px) and (max-width: 1480px) {
    width: 150px;
  }

  /*
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  @media (min-width: 1025px) and (max-width: 1280px) {
    width: 100px;
  }

  @media (max-width: 1024px) {
    width: 200px;
  }

  @media (max-width: 500px) {
    width: 140px;
  }
`;

const MenuSubsectionSelect = props => (
  <Select
    {...props}
  />
);

export {
  Menu,
  MenuHeader,
  MenuSection,
  MenuSectionDescription,
  MenuSectionItem,
  MenuSectionItem2,
  MenuSectionLabel,
  MenuSubsection,
  MenuSubsectionHeader,
  MenuSubsectionItem,
  MenuSubsectionInput,
  MenuSubsectionSelect,
  MenuSubsectionLabel,
  ItemField,
};
