// devices.redux.js

// local components
import {devices as devicesApi} from '../api';

const actionTypes = {
  DB_DEVICE_GET_DEVICE: 'DB_DEVICE_GET_DEVICE',
  DB_DEVICES_GET_DEVICE_STATISTICS: 'DB_DEVICES_GET_DEVICE_STATISTICS',
  DB_DEVICES_GET_DEVICE_STATISTICS_FIELDS: 'DB_DEVICES_GET_DEVICE_STATISTICS_FIELDS',
};

const actions = {
  getDevice: deviceId => dispatch => (
    devicesApi.get(deviceId)
      .then(device => dispatch({
        type: actionTypes.DB_DEVICE_GET_DEVICE,
        payload: {device},
      }))
  ),
  fetchDeviceStatistics: (deviceId, measurement, start, end, distance) => dispatch => (
    devicesApi.getStatistics(deviceId, measurement, start, end, distance)
      .then(deviceStats => dispatch({
        type: actionTypes.DB_DEVICES_GET_DEVICE_STATISTICS,
        payload: {deviceId, deviceStats},
      }))
  ),
  fetchDeviceStatisticsFields: deviceId => dispatch => (
    devicesApi.getStatisticsFields(deviceId)
      .then(deviceStatsFields => dispatch({
        type: actionTypes.DB_DEVICES_GET_DEVICE_STATISTICS_FIELDS,
        payload: {deviceStatsFields},
      }))
  ),
};

const defaultState = {
  deviceStats: [],
  deviceStatsFields: [],
};

const reduce = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.DB_DEVICE_GET_DEVICE: {
      const {device} = action.payload;
      return {...state, device};
    }
    case actionTypes.DB_DEVICES_GET_DEVICE_STATISTICS: {
      const {deviceStats} = action.payload;
      return {...state, deviceStats};
    }
    case actionTypes.DB_DEVICES_GET_DEVICE_STATISTICS_FIELDS: {
      const {deviceStatsFields} = action.payload;
      return {...state, deviceStatsFields};
    }
    default: {
      return state;
    }
  }
};

export {
  actions,
  reduce,
};
