// locations-edit.view

// Modules
import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {get, toNumber} from 'lodash';
import {toast} from 'react-toastify';

// local and styled components
import {StyledLocationEditView, StyledButtonsContainer, StyledIconContainer} from './location-edit.styled';
import {UploadFileButton} from '../../views-menu-buttons.styled';
import {StyledIcon} from './location-edit.styled';
import {FlexWrapper, HorizontalLine} from '../../views.styled';
import {SelectInput, TextAreaInput, TextInput} from '../../../components/inputs';
import {CancelationButton, RemoveButton, SuccessButton} from '../../views-buttons';

// utils
import {allowedImageNameSpaces} from '../../../constants';
import config from '../../../config';
import {createLocationUrl} from '../../views.routes';
import {getLocationIconUrl} from '../../views.utils';

// State
import {selectCurrentLocation} from '../location.selectors';
import {actions as locationEditActions} from './location-edit.redux';
import {actions as viewsActions} from '../../views.redux';
import {actions as locationActions} from '../location.redux';
import {actions as imageActions} from '../../../state/images.redux';
import {createSelectorIcons} from '../../locations.view/locations.selectors';

const LocationEdit = (
  {
    location,
    edits,
    icons,
    updateLocationEdits,
    saveLocationEdits,
    fetchImages,
    uploadImage,
    beginLocationEdit,
    setView,
    history,
    setDialogState,
  }
) => {
  useEffect(() => {
    beginLocationEdit(location);
  }, []);

  return (
    <StyledLocationEditView>
      <h4>Edit location</h4>
      <HorizontalLine />
      <TextInput
        label={'Name'}
        value={get(edits[location._id], 'name', '')}
        onChange={({target: {value}}) => updateLocationEdits(location._id, {name: value})}
        maxLength={20}
      />
      <TextAreaInput
        label={'Description'}
        width={'600'}
        maxLength={200}
        value={get(edits[location._id], 'description', '')}
        onChange={({target: {value}}) => updateLocationEdits(location._id, {description: value})}
      />
      <StyledIconContainer>
        <SelectInput
          label={'Icon'}
          value={get(edits[location._id], 'icon', '')}
          options={icons}
          onFocus={() => {
            fetchImages(allowedImageNameSpaces.LOCATION_ICON);
          }}
          onChange={({value}) => updateLocationEdits(location._id, {icon: value})}
        />
        <StyledIcon
          src={getLocationIconUrl(get(edits[location._id], 'icon', ''))}
          alt="location icon"
        />
        <UploadFileButton
          isUploading={false}
          onChange={file => uploadImage(file, allowedImageNameSpaces.LOCATION_ICON)}
        />
      </StyledIconContainer>
      <div style={{display: 'flex'}}>
        <TextInput
          label={'Longitude'}
          width={'200'}
          type="number"
          min={'-180'}
          max={'180'}
          value={get(edits[location._id], 'lng', '')}
          onChange={({target: {value}}) => {
            const lng = toNumber(value);
            updateLocationEdits(location._id, {lng});
          }}
        />
        <TextInput
          label={'Latitude'}
          width={'200'}
          type="number"
          min={'-90'}
          max={'90'}
          value={get(edits[location._id], 'lat', '')}
          onChange={({target: {value}}) => {
            const lat = toNumber(value);
            updateLocationEdits(location._id, {lat});
          }}
        />
      </div>
      <HorizontalLine />
      <StyledButtonsContainer>
        <FlexWrapper>
          <SuccessButton
            onClick={() => {
              toast.success(`Location edited: ${location.name}`, config.toaster);
              saveLocationEdits(location, edits[location._id])
                .then(() => {
                  history.push(createLocationUrl(edits[location._id].name));
                  setView(null);
                });
            }}
          >
            Save changes
          </SuccessButton>
          <CancelationButton
            onClick={() => setView(null)}
          >
            Cancel
          </CancelationButton>
        </FlexWrapper>
        <RemoveButton onClick={() => {
          setDialogState(location._id);
        }} >
          Delete location
        </RemoveButton>
      </StyledButtonsContainer>
    </StyledLocationEditView>
  );
};

const selectIcons = createSelectorIcons();

const mapStateToProps = state => ({
  location: selectCurrentLocation(state),
  edits: state.views.locationEdit.edits,
  icons: selectIcons(state),
});

const mapDispatchToProps = dispatch => ({
  setDialogState: locationId => dispatch(viewsActions.setDialogState(locationId)),
  setView: view => dispatch(locationActions.setView(view)),
  updateLocationEdits: (locationId, edits) => (
    dispatch(locationEditActions.updateLocationEdits(locationId, edits))
  ),
  beginLocationEdit: location => dispatch(locationEditActions.beginEditing(location)),
  saveLocationEdits: (location, edits) => dispatch(locationEditActions.saveLocationEdits(location, edits)),
  fetchImages: imageType => dispatch(imageActions.fetchImages(imageType)),
  uploadImage: (image, nameSpace) => dispatch(viewsActions.uploadImage(image, nameSpace)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationEdit));
