// interactive-layer.js

// zoom and pan stuff

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import aggregate from 'promise-aggregate';

const layerStyle = {
  width: '100%',
  height: '100%',
  position: 'relative',
  userSelect: 'none',
};

class InteractiveLayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      panningPosition: null,
      listeners: {},
    };
    this.onMouseUp = (...args) => {
      if (this.props.onMouseUp) {
        this.props.onMouseUp(...args);
      }
    };
    const AGG_TUNER = 30;
    // set up the pan and zoom machinery
    const localPan = (...args) => this.props.onPan(...args);
    this.onPan = aggregate(localPan.bind(this), {
      minInterval: AGG_TUNER, // ms
      maxWait: AGG_TUNER, // ms
      aggInterval: AGG_TUNER / 5, // ms
      replaceArgs: (args, prevArgs) => {
        const diff = _.get(args, 0, {x: 0, y: 0, top: 0, left: 0});
        const prevDiff = _.get(prevArgs, 0, {x: 0, y: 0});
        return [{
          x: diff.x + prevDiff.x,
          y: diff.y + prevDiff.y,
          top: diff.top,
          left: diff.left,
        }];
      },
    });
    const localZoom = (...args) => this.props.onZoom(...args);
    this.onZoom = aggregate(localZoom.bind(this), {
      minInterval: AGG_TUNER, // ms
      maxWait: AGG_TUNER, // ms
      aggInterval: AGG_TUNER / 5, // ms
      replaceArgs: (args, prevArgs) => {
        const prev = _.get(prevArgs, 0, {zoom: 0});
        const curr = _.get(args, 0, {zoom: 0});
        const position = _.pick(curr, ['top', 'left']);
        const zoom = prev.zoom + curr.zoom;
        return [{...position, zoom}];
      },
    });
  }

  unregisterListeners() {
    _.each(this.state.listeners, (listener, key) => {
      document.removeEventListener(key, listener);
    });
  }

  componentWillUnmount() {
    this.unregisterListeners();
  }

  deriveEventPosition(evt) {
    const clientPosition = {
      left: _.get(evt, 'targetTouches[0].pageX', evt.clientX),
      top: _.get(evt, 'targetTouches[0].pageY', evt.clientY),
    };
    if (!this.props.boundingRect) {
      return clientPosition;
    }
    const translatedPosition = {
      left: clientPosition.left - this.props.boundingRect.left,
      top: clientPosition.top - this.props.boundingRect.top,
    };
    const centeredPosition = {
      left: translatedPosition.left - 0.5 * this.props.boundingRect.width,
      top: translatedPosition.top - 0.5 * this.props.boundingRect.height,
    };
    return centeredPosition;
  }

  handleMouseMove(evt) {
    if (this.state.panningPosition) {
      const prevPosition = this.state.panningPosition;
      const position = this.deriveEventPosition(evt);
      const diff = {
        x: position.left - prevPosition.left,
        y: position.top - prevPosition.top,
        ...position,
      };
      this.setState({panningPosition: position}, () => {
        if (diff.x || diff.y) {
          this.onPan(diff);
        }
      });
    }
  }

  handleMouseUp(evt) {
    this.unregisterListeners();
    this.setState({
      panningPosition: null,
      listeners: {},
    }, () => this.onMouseUp(evt));
  }

  handleMouseDown(evt) {
    const handleMouseMove = this.handleMouseMove.bind(this);
    const handleMouseUp = this.handleMouseUp.bind(this);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchmove', handleMouseMove);
    document.addEventListener('touchend', handleMouseUp);
    const position = this.deriveEventPosition(evt);
    this.setState({
      panningPosition: position,
      listeners: {
        mousemove: handleMouseMove,
        mouseup: handleMouseUp,
        touchmove: handleMouseMove,
        touchend: handleMouseUp,
      },
    });
  }

  handleWheel(evt) {
    const position = this.deriveEventPosition(evt);
    const zoom = evt.deltaY;
    this.onZoom({...position, zoom});
  }

  render() {
    return (
      <div
        onTouchStart={this.handleMouseDown.bind(this)}
        onMouseDown={this.handleMouseDown.bind(this)}
        onWheel={this.handleWheel.bind(this)}
        style={_.assign(
          {},
          layerStyle,
          {cursor: (this.state.panningPosition ? 'grabbing' : 'default')},
          this.props.style,
        )} >
        {this.props.children}
      </div>
    );
  }
}

InteractiveLayer.propsTypes = {
  style: PropTypes.obj, // propagate optional styling
  boundingRect: PropTypes.shape({
    left: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  onMouseUp: PropTypes.func.isRequired,
  onPan: PropTypes.func.isRequired,
  onZoom: PropTypes.func.isRequired,
};

export default InteractiveLayer;
