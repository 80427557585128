// device-edit/index.js

// Modules
import React from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {get, concat} from 'lodash';

// Local components
import {FlexWrapper, HorizontalLine} from '../../views.styled';
import {SelectInput, TextInput} from '../../../components/inputs';
import {UploadFileButton} from '../../views-menu-buttons.styled';
import {CancelationButton, RemoveButton, SuccessButton} from '../../views-buttons';
import {
  StyledButtonsContainer,
  StyledIconContainer,
  StyledLocationView,
} from '../location.styled';

// Utils
import {allowedImageNameSpaces} from '../../../constants';
import {getDeviceIconUrl} from '../../views.utils';
import config from '../../../config';

// State
import {
  selectCurrentLocation,
  selectCurrentLocationDeviceEditor,
  selectCurrentDeviceId,
} from '../location.selectors';
import {
  createDeviceIconsSelector,
} from './device-edit.selectors';
import {actions as viewActions} from './device-edit.redux';
import {actions as locationActions} from '../location.redux';
import {actions as imageActions} from '../../../state/images.redux';
import {actions as menuActions} from '../location-menu.view/location-menu.redux';

const DeviceEditor = (
  {
    currentLocation,
    fetchImages,
    updateDeviceEdits,
    deviceEditor,
    saveDeviceEdits,
    cancelDeviceEdits,
    uploadImage,
    icons,
    currentDeviceId,
    setView,
    deleteBlueprintDeviceItem,
  }
) => {
  // Create an array with default icon included
  const allIcons = concat(icons, {label: 'Default', value: ''});

  return (
    <StyledLocationView>
      <h4>Edit device</h4>
      <HorizontalLine />
      <FlexWrapper>
        <div>
          <StyledIconContainer>
            <SelectInput
              label={'Icon'}
              value={get(deviceEditor, 'locationItemControl.icon', '')}
              options={allIcons}
              onFocus={() => fetchImages(allowedImageNameSpaces.DEVICE_ICON)}
              onChange={({value}) => {
                const updates = {...deviceEditor, locationItemControl: {icon: value}};
                updateDeviceEdits(currentDeviceId, updates);
              }}
            />
            <img
              style={{width: '40px', height: '40px'}}
              src={getDeviceIconUrl(get(deviceEditor, 'locationItemControl.icon'))}
              alt="device icon"
            />
            <UploadFileButton
              isUploading={false}
              onChange={file => uploadImage(file)}
            />
          </StyledIconContainer>
        </div>
      </FlexWrapper>
      <HorizontalLine />
      <StyledButtonsContainer>
        <SuccessButton
          onClick={() => {
            toast.success(`Device edited: ${deviceEditor.name}`, config.toaster);
            saveDeviceEdits(currentLocation, currentDeviceId, deviceEditor);
            setView(null);
          }}
        >
          Save changes
        </SuccessButton>
        <CancelationButton
          onClick={() => {
            cancelDeviceEdits(currentDeviceId);
            setView(null);
          }}
        >
          Cancel
        </CancelationButton>
        <RemoveButton
          onClick={() => {
            deleteBlueprintDeviceItem(
              currentLocation,
              currentDeviceId
            );
            setView(null);
          }}>
          Delete
        </RemoveButton>
      </StyledButtonsContainer>
    </StyledLocationView>
  );
};
const selectDeviceEdits = selectCurrentLocationDeviceEditor();

const mapStateToProps = (state, props) => ({
  currentDeviceId: selectCurrentDeviceId(state),
  icons: createDeviceIconsSelector(state),
  currentLocation: selectCurrentLocation(state),
  deviceEditor: selectDeviceEdits(state, props),
  currentBlueprintId: state.views.common.currentBlueprintId,
});

const mapDispatchToProps = dispatch => ({
  cancelDeviceEdits: deviceId => {
    dispatch(viewActions.cancelDeviceEdits(deviceId));
  },
  updateDeviceEdits: (deviceItemId, deviceItemEdits) => {
    dispatch(viewActions.updateDeviceEdits(deviceItemId, deviceItemEdits));
  },
  saveDeviceEdits: (location, deviceItemId, updates) => {
    dispatch(viewActions.saveDeviceEdits(location, deviceItemId, updates));
  },
  setView: view => dispatch(locationActions.setView(view)),
  fetchImages: imageType => dispatch(imageActions.fetchImages(imageType)),
  uploadImage: image => dispatch(imageActions.uploadImage(image, allowedImageNameSpaces.DEVICE_ICON)),
  deleteBlueprintDeviceItem: (location, deviceItemId) => {
    dispatch(menuActions.deleteBlueprintDeviceItem(location, deviceItemId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceEditor);
