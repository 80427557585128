// views.constants.js
// A store for constants used across multiple views

import {thermometerHalf as thermometer} from 'react-icons-kit/fa/thermometerHalf';
import {signal} from 'react-icons-kit/fa/signal';
import {arrowUp} from 'react-icons-kit/fa/arrowUp';
import {info} from 'react-icons-kit/fa/info';
import {batteryThreeQuarters as battery} from 'react-icons-kit/fa/batteryThreeQuarters';
import {tint as raindrop} from 'react-icons-kit/fa/tint';
import {adjust} from 'react-icons-kit/fa/adjust';

// some default images
import YGGIO_LOGO from '../assets/images/yggio/yggio-icon.svg';
import DEFAULT_LOCATION_ICON from '../assets/images/defaults/location-icon.png';
import DEFAULT_BLUEPRINT_IMAGE from '../assets/images/defaults/blueprint.png';
import DEFAULT_DEVICE_ICON from '../assets/images/defaults/device-icon.png';

const LUND_COORDS = [55.70584, 13.19321];

// minScale = 1 / BLUEPRINT_MAX_SCALE
const BLUEPRINT_MAX_SCALE = 2 ** 10;
// i.e. nextScale = oldScale * 2 ** (deltaY / BLUEPRINT_SCALE_FACTOR)
const BLUEPRINT_ZOOM_SCALE_FACTOR = 3500;
// i.e. nextScale = oldScale * 2 ** BLUEPRINT_BUTTON_SCALE_FACTOR
const BLUEPRINT_BUTTON_SCALE_FACTOR = 1 / 4;

// New colors
/*
* british racing green: #004225
* lightgreen rgba(59,125,69,.6)
* medium green rgba(0,76,25,.84)
* */

const baseColors = {
  RACING_GREEN: '#004225',
  LIGHT_GREEN: '#3B7D45',
  LIGHT_GREEN_ALT: '#7fb18e',
  MEDIUM_GREEN: '#49AE68',
  DARK_GREEN: '#004C19',
  BLACK: '#111',
  GREY_DARK: '#333',
  GREY_DARK_ALT: '#4b4b4b',
  GREY: '#ccc',
  GREY_ALT: '#7b7b7b',
  GREY_LIGHT: '#eee',
  GREY_LIGHT_ALT: '#e5e5e5',
  WHITE: '#fbfbfb',
  TRANSPARENT: 'transparent',
  RED: '#d16d6e',
  YELLOW: '#f8ff54',
  GREEN: '#40ff8a',
};

const componentColors = {
  CREATE_LOCATION_BUTTON: '#90ee90',
  CREATE_LOCATION_BUTTON_HOVER: '#6dee68',
  MENU_HEADER_TEXT: '#eee',
  SECTION_HEADER_BACKGROUND: '#5d5d5d',
  SECTION_HEADER_BACKGROUND_HOVER: '#6b6b6b',
  SECTION_HEADER_TEXT: '#eee',
  SECTION_ITEM_BACKGROUND: '#5b5b5b',
  SECTION_ITEM_HOVER_BG: '#666',
  SECTION_ITEM_TEXT: '#eee',
  SECTION_DESCRIPTION_TEXT: '#e8f2f9',
  SECTION_DESCRIPTION_BACKGROUND: '#7d96ad',
  SUBSECTION_HEADER_BACKGROUND: '#ddd',
  SUBSECTION_HEADER_COLOR: '#2d2213',
  SUBSECTION_BACKGROUND: '#7d7d7d',
  SUBSECTION_ITEM_BACKGROUND: '#4b4b4b',
  SUBSECTION_ITEM_HOVER_BG: '#4b4b4b',
  SUBSECTION_ITEM_TEXT: '#111',
  // stuff related to specific
  DEVICE_PLACEMENT_COVER: 'rgba(0,0,0,0.15)',
  MARKER: '#62d16b',
  MARKER_HOVER: '#7ade82',
};

const colors = {...baseColors, ...componentColors};

const NAVBAR_HEIGHT = '45px';
const DEFAULT_MAP_ZOOM = 5;
const LEAFLET_ATTRIBUTION = `
  &copy;
  <a href="http://osm.org/copyright">
    OpenStreetMap
  </a>
  contributors
`;
const LEAFLET_URL = 'http://{s}.tile.osm.org/{z}/{x}/{y}.png';

const allowedImageTypes = ['image/jpeg', 'image/gif', 'image/png'];
const IMAGE_MAX_SIZE = 1024 * 1024 * 2;

const measurementTypes = {
  TEMPERATURE: 'temperature',
  TEMPERATURE_VALUE: 'temperature_value',
  AVERAGE_TEMPERATURE: 'averageTemperature',
  AVERAGE_TEMPERATURE_VALUE: 'averageTemperature_value',
  HUMIDITY: 'humidity',
  HUMIDITY_VALUE: 'humidity_value',
  BATTERY: 'battery',
  SNR: 'snr',
  RSSI: 'rssi',
  MOISTURE: 'moisture',
  LUMINANCE: 'luminance',
  GW_METADATA_RSSI: 'gwMetaData_rssi',
  GW_METADATA_LORASNR: 'gwMetaData_loRaSNR',
  GW_METADATA_PORT: 'gwMetaData_port',
  GW_METADATA_FRAME_COUNT: 'gwMetaData_frameCount',
};

const knownMeasurements = {
  [measurementTypes.TEMPERATURE]: {
    type: measurementTypes.TEMPERATURE,
    name: 'Temperature',
    unit: '°C',
    icon: thermometer,
  },
  [measurementTypes.TEMPERATURE_VALUE]: {
    type: measurementTypes.TEMPERATURE_VALUE,
    name: 'Temperature',
    unit: '°C',
    icon: thermometer,
  },
  [measurementTypes.AVERAGE_TEMPERATURE]: {
    type: measurementTypes.AVERAGE_TEMPERATURE,
    name: 'Average temperature',
    unit: '°C',
    icon: thermometer,
  },
  [measurementTypes.AVERAGE_TEMPERATURE_VALUE]: {
    type: measurementTypes.AVERAGE_TEMPERATURE_VALUE,
    name: 'Average temperature',
    unit: '°C',
    icon: thermometer,
  },
  [measurementTypes.HUMIDITY]: {
    type: measurementTypes.HUMIDITY,
    name: 'Humidity',
    unit: '%',
    icon: raindrop,
  },
  [measurementTypes.HUMIDITY_VALUE]: {
    type: measurementTypes.HUMIDITY_VALUE,
    name: 'Humidity',
    unit: '%',
    icon: raindrop,
  },
  [measurementTypes.BATTERY]: {
    type: measurementTypes.BATTERY,
    name: 'Battery',
    icon: battery,
  },
  [measurementTypes.SNR]: {
    type: measurementTypes.SNR,
    name: 'SNR',
    unit: 'dB',
    icon: signal,
  },
  [measurementTypes.RSSI]: {
    type: measurementTypes.RSSI,
    name: 'RSSI',
    icon: signal,
  },
  [measurementTypes.GW_METADATA_RSSI]: {
    type: measurementTypes.GW_METADATA_RSSI,
    name: 'Gateway RSSI',
    icon: signal,
    reversed: true,
  },
  [measurementTypes.GW_METADATA_LORASNR]: {
    type: measurementTypes.GW_METADATA_LORASNR,
    name: 'Gateway LoRa SNR',
    icon: signal,
  },
  [measurementTypes.GW_METADATA_PORT]: {
    type: measurementTypes.GW_METADATA_PORT,
    name: 'Port',
    icon: info,
  },
  [measurementTypes.GW_METADATA_FRAME_COUNT]: {
    type: measurementTypes.GW_METADATA_FRAME_COUNT,
    name: 'Frame count',
    icon: arrowUp,
  },
  [measurementTypes.MOISTURE]: {
    type: measurementTypes.MOISTURE,
    name: 'Moisture',
    unit: '%',
    icon: raindrop,
  },
  [measurementTypes.LUMINANCE]: {
    type: measurementTypes.LUMINANCE,
    name: 'Luminance',
    unit: 'lux',
    icon: adjust,
  },
};

const LOCATION_VIEWS = {
  blueprintEditor: 'blueprintEditor',
  locationEditor: 'locationEditor',
  deviceEditor: 'deviceEditor',
  blueprintCreator: 'blueprintCreator',
};

export {
  LUND_COORDS,
  BLUEPRINT_MAX_SCALE,
  BLUEPRINT_ZOOM_SCALE_FACTOR,
  BLUEPRINT_BUTTON_SCALE_FACTOR,

  NAVBAR_HEIGHT,
  YGGIO_LOGO,
  DEFAULT_LOCATION_ICON,
  DEFAULT_BLUEPRINT_IMAGE,
  DEFAULT_DEVICE_ICON,

  allowedImageTypes,
  IMAGE_MAX_SIZE,

  colors,
  DEFAULT_MAP_ZOOM,
  LEAFLET_ATTRIBUTION,
  LEAFLET_URL,

  measurementTypes,
  knownMeasurements,
  LOCATION_VIEWS,
};
