// locations.view.js

// Modules
import React from 'react';
import {MapWrapper} from './locations.styled';
import LocationsMap from './locations-map.view';
import LocationsMenu from './locations-menu.view';
import LocationsButtons from './locations-buttons-layer.view';

// actions & selectors

const Locations = () => (
  <MapWrapper>
    <LocationsButtons/>
    <LocationsMenu/>
    <LocationsMap/>
  </MapWrapper>
);


export default Locations;
