// device-placement-layer.view.js

// Modules
import React from 'react';
import styled from 'styled-components';

import {LocationContainerLayer} from '../location.styled';
import {colors} from '../../views.constants';

const DeviceItemsLayer = styled(LocationContainerLayer)`
  user-select: none;
  background-color: ${props => {
    if (props.isInDevicePlacementMode) {
      return colors.DEVICE_PLACEMENT_COVER;
    }
    return null;
  }};
  pointer-events: ${props => {
    if (props.isPlacingNewDeviceItems) {
      return 'auto';
    }
    return 'none';
  }};
`;

const DevicePlacementIndicatorStyled = styled.div`
  pointer-events: none;
  outline: none;
  width: ${props => {
    const numChars = props.numIndicated.toString().length;
    return `${(35 + (numChars - 1) * 8)}px`;
  }};
  height: 26px;
  margin-left: 30px;
  margin-top: 2px;
  margin-bottom: 10px;
  color: ${colors.WHITE};
  background: red;
  text-align: right;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px ${colors.WHITE};
  border-radius: 0 15px 15px 0;
`;

const DevicePlacementIndicator = props => (
  <DevicePlacementIndicatorStyled
    numIndicated={props.numIndicated || 0}
    >
    {props.numIndicated}
  </DevicePlacementIndicatorStyled>
);

const DevicePlacementInstructionStyled = styled.div`
  margin-left: 70px;
  font-size: 35px;
  height: 50px;
  color: #504141;
`;

const DevicePlacementInstruction = props => (
  <DevicePlacementInstructionStyled>
    {props.isPlacingNewDeviceItems ? 'click anywhere to add' : 'drag item to move'}
  </DevicePlacementInstructionStyled>
);

const DeviceNextToBeAddedStyled = styled.div`
  margin-left: 70px;
  font-size: 30px;
  fort-weight: bold;
  height: 50px;
  color: #504141;
`;

const DeviceNextToBeAdded = props => (
  <DeviceNextToBeAddedStyled>
    {props.deviceItem.name}
  </DeviceNextToBeAddedStyled>
);


export {
  DeviceItemsLayer,
  DevicePlacementIndicator,
  DevicePlacementInstruction,
  DeviceNextToBeAdded,
};
