// locations.selectors.js

import {
  compact,
  concat,
  filter,
  first,
  flatten,
  get,
  includes,
  isEmpty,
  last,
  map,
  size,
  split,
  toLower,
  uniqBy
} from 'lodash';
import {createSelector} from 'reselect';
import {allowedImageNameSpaces} from '../../constants';

const selectAllLocations = state => state.locations;
const selectAllDevices = state => state.devices;
const selectAllLocationIcons = state => state.images[allowedImageNameSpaces.LOCATION_ICON];
const selectLocationsFilterText = state => state.views.locationsMenu.locationsFilterText;

const selectCurrentLocationDevices = (location, devices, filterText) => {
  const items = concat(
    get(location, 'defaultLayer.items', []),
    map(location.layers, layer => layer.items),
  );
  const flattenedItems = flatten(items);
  const uniqueItems = uniqBy(flattenedItems, item => item.deviceId);

  const locationDevices = map(uniqueItems, item => {
    const device = devices[item.deviceId];
    const deviceName = get(device, 'name');
    return includes(toLower(deviceName), toLower(filterText)) ? device : null;
  });
  return compact(locationDevices);
};

const selectFilteredLocations = createSelector(
  selectAllLocations,
  selectAllDevices,
  selectLocationsFilterText,
  (locations, devices, filterText) => (
    filter(locations, location => {
      const locationDevices = selectCurrentLocationDevices(location, devices, filterText);
      const searchString = toLower(filterText);
      const locationName = toLower(location.name);
      const locationDesc = toLower(location.description);
      const filteredDevices = includes(locationName, searchString) || includes(locationDesc, searchString);
      return !isEmpty(locationDevices) || filteredDevices;
    })
  )
);
const createSelectorIcons = () => createSelector(
  selectAllLocationIcons,
  locationIcons => (
    map(locationIcons, icon => {
      const splitIcon = last(split(icon, '/'));
      const label = first(split(splitIcon, '.'));
      return {label, value: icon};
    })
  )
);

const createSelectorLocation = () => createSelector(
  (state, props) => props.locationId,
  selectAllLocations,
  (locationId, locations) => locations[locationId],
);

const createSelectorTotalNumDevicesInLocation = () => createSelector(
  (state, props) => props.locationId,
  selectAllLocations,
  (locationId, locations) => {
    const location = locations[locationId];
    const layers = concat(
      get(location, 'defaultLayer', []),
      get(location, 'layers', []),
    );
    const items = flatten(map(layers, 'items'));
    return items.length;
  },
);

const createSelectorTotalNumBlueprintsInLocation = () => createSelector(
  (state, props) => props.locationId,
  selectAllLocations,
  (locationId, locations) => {
    const location = locations[locationId];
    const layers = concat(
      get(location, 'defaultLayer', []),
      get(location, 'layers', []),
    );
    return size(layers);
  }
);

const selectLocationDevices = () => createSelector(
  (state, props) => props.locationId,
  selectAllLocations,
  selectAllDevices,
  state => state.views.locationsMenu.locationsFilterText,
  (locationId, locations, devices, filter) => {
    const location = locations[locationId];
    const locationDevices = selectCurrentLocationDevices(location, devices, filter);
    return filter ? compact(locationDevices) : null;
  }
);

export {
  createSelectorTotalNumDevicesInLocation,
  createSelectorLocation,
  createSelectorIcons,
  selectFilteredLocations,
  createSelectorTotalNumBlueprintsInLocation,
  selectLocationDevices,
};
