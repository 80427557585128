// views.buttons.js

// Modules
import React from 'react';
import styled from 'styled-components';
import {Icon} from 'react-icons-kit';
import _ from 'lodash';
import PropTypes from 'prop-types';

// Icons
import {save as saveIcon} from 'react-icons-kit/fa/save';
import {close as closeIcon} from 'react-icons-kit/fa/close';
import {trashO as deleteIcon} from 'react-icons-kit/fa/trashO';
import {ic_games as emplaceIcon} from 'react-icons-kit/md/ic_games';
import {undo as resetIcon} from 'react-icons-kit/fa/undo';
import {plus as zoomInIcon} from 'react-icons-kit/fa/plus';
import {minus as zoomOutIcon} from 'react-icons-kit/fa/minus';
import {ic_delete as trashIcon} from 'react-icons-kit/md/ic_delete';
import {chevronDown as nextArrow} from 'react-icons-kit/fa/chevronDown';
import {chevronUp as prevArrow} from 'react-icons-kit/fa/chevronUp';

// Constants
import {colors} from './views.constants';

// Styled components
const StyledButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-weight: 1000;
  align-items: center;
  margin: 5px;
  padding: 0;
  border-radius: 5px;
  border: none;
  width: ${props => props.width || 100}px;
  height: ${props => props.height || 50}px;
  color: ${props => props.color || colors.GREY_DARK};
  background: ${props => props.background || colors.DARK_GREEN};
  transition: color 0.2s, background 0.2s;

  &:hover {
    color: ${props => props.hoverColor || colors.BLACK};
    background: ${props => props.hoverBackground || colors.LIGHT_GREEN};
    transition: color 0.2s, background 0.2s;
  }
`;

const DEFAULT_ICON_SIZE = 15;

// React components
const Button = props => (
  <StyledButton
    {..._.omit(props, 'onClick')}
    onClick={evt => {
      const preventDefault = _.get(props, 'preventDefault', true);
      if (preventDefault) {
        evt.preventDefault();
      }
      if (props.onClick) {
        props.onClick(evt);
      }
    }}
  >
    {props.children}
  </StyledButton>
);

// just to have a manifest of which props apply
Button.propTypes = {
  preventDefault: PropTypes.bool,
  background: PropTypes.string,
  hoverColor: PropTypes.string,
  onClick: PropTypes.func,
};

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px;
`;

const SaveButton = props => (
  <Button
    background={'lightgreen'}
    hoverBackground={'#16ff22'}
    onClick={props.onClick}
    height={props.height}
    width={props.width}
  >
    <Icon size={26} icon={saveIcon} />&nbsp;SAVE
  </Button>
);

const CancelButton = props => (
  <Button
    background={'#fff34f'}
    hoverBackground={'yellow'}
    onClick={props.onClick}
    height={props.height}
    width={props.width}
  >
    <Icon size={26} icon={closeIcon} />&nbsp;CANCEL
  </Button>
);

const DeleteButton = props => (
  <Button
    background={'#ff3838'}
    hoverBackground={'red'}
    onClick={props.onClick}
    height={props.height}
    width={props.width}
  >
    <Icon size={26} icon={deleteIcon} />&nbsp;DELETE
  </Button>
);


const ButtonStyled = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  cursor: pointer;
  border: ${({borderSize, borderStyle, borderColor}) => {
    return `${borderSize || '1px'} ${borderStyle || 'solid'} ${borderColor || colors.GREY_LIGHT}`
  }};
  outline: none;
  width: ${({width}) => width || '30px'};
  height: ${({height}) => height || '30px'};
  z-index: 3;
  margin: ${({margin}) => margin || '0 0 10px'};
  border-radius: 5px;
  color: ${({color}) => color || colors.WHITE};
  background: ${({bg}) => bg || colors.LIGHT_GREEN};
  transition: border-radius 0.3s, background 0.2s;
  font-size: ${({fontSize}) => fontSize || 'inherit'};
  padding: ${({sidePadding}) => `0 ${sidePadding}` || '0'};

  &:focus {
    outline:0;
  }
  
  i {
    width: ${({width}) => width || '30px'};
    height: ${({height}) => height || '30px'};
  }

  &:hover {
    transition: border-radius 0.3s, background 0.2s;
    background: ${({bgHover}) => bgHover};
    border-radius: ${({deviceItemGrabbed}) => (deviceItemGrabbed ? '50px' : '5px')};
  }

  @media screen and (max-width: 1024px) {
    width: ${({width}) => width || '50px'};
    height: ${({height}) => height || '50px'};
  }
`;

const ButtonBase = props => (
  <ButtonStyled
    onClick={evt => {
      evt.stopPropagation();
      props.onClick(evt);
    }}
    color={props.color}
    bg={props.background}
    bgHover={props.backgroundHover}
    deviceItemGrabbed={props.deviceItemGrabbed}
    onMouseUp={props.onMouseUp}
    title={props.title}
    borderStyle={props.borderStyle}
    borderColor={props.borderColor}
    borderSize={props.borderSize}
    width={props.width}
    height={props.height}
    margin={props.margin}
    fontSize={props.fontSize}
    sidePadding={props.sidePadding}
  >
    {props.children}
  </ButtonStyled>
);

const EmplaceButton = props => (
  <ButtonBase onClick={props.onClick}>
    <Icon icon={emplaceIcon} size={DEFAULT_ICON_SIZE} />
  </ButtonBase>
);

const ResetButton = props => (
  <ButtonBase onClick={props.onClick}>
    <Icon icon={resetIcon} size={DEFAULT_ICON_SIZE} />
  </ButtonBase>
);

const ZoomInButton = props => (
  <ButtonBase onClick={props.onClick} margin={'10px 0 1px'}>
    <Icon icon={zoomInIcon} size={DEFAULT_ICON_SIZE} />
  </ButtonBase>
);
const ZoomOutButton = props => (
  <ButtonBase onClick={props.onClick}>
    <Icon icon={zoomOutIcon} size={DEFAULT_ICON_SIZE} />
  </ButtonBase>
);
const NextButton = props => {
  const disabledColor = props.disabled && {color: colors.LIGHT_GREEN_ALT};
  return (
    <ButtonBase
      onClick={props.onClick}
      {...disabledColor}
    >
      <Icon icon={nextArrow} size={DEFAULT_ICON_SIZE} />
    </ButtonBase>
  );
};

const PrevButton = props => {
  const disabledColor = props.disabled && {color: colors.LIGHT_GREEN_ALT};
  return (
    <ButtonBase
      onClick={props.onClick}
      margin={'10px 0 1px'}
      {...disabledColor}
    >
      <Icon icon={prevArrow} size={DEFAULT_ICON_SIZE} />
    </ButtonBase>
  );
};

const GreyButton = props => (
  <ButtonBase
    onClick={props.onClick}
    color={colors.GREY}
    background={colors.TRANSPARENT}
    backgroundHover={colors.GREY_DARK}
    borderStyle={'solid'}
    borderColor={colors.GREY_ALT}
    borderSize={'1px'}
    title={props.title}
    width={props.width || 'auto'}
    height={'30px'}
    sidePadding={'16px'}
    margin={props.margin}
    fontSize={'13px'}
  >
    {props.children}
  </ButtonBase>
);

const SuccessButton = props => (
  <ButtonBase
    margin={'0 10px 0'}
    onClick={props.onClick}
    color={colors.GREY_LIGHT}
    background={colors.MEDIUM_GREEN}
    backgroundHover={colors.LIGHT_GREEN}
    borderStyle={'solid'}
    borderColor={colors.GREY_ALT}
    borderSize={'0px'}
    title={props.title}
    width={'140px'}
    height={'35px'}
  >
    {props.children}
  </ButtonBase>
);

const CancelationButton = props => (
  <ButtonBase
    margin={'0 10px 0'}
    onClick={props.onClick}
    color={colors.GREY_DARK}
    background={colors.TRANSPARENT}
    backgroundHover={colors.GREY_LIGHT}
    borderStyle={'solid'}
    borderColor={colors.GREY_ALT}
    borderSize={'1px'}
    title={props.title}
    width={'140px'}
    height={'35px'}
  >
    {props.children}
  </ButtonBase>
);

const RemoveButton = props => (
  <ButtonBase
    onClick={props.onClick}
    color={colors.RED}
    background={colors.TRANSPARENT}
    backgroundHover={colors.WHITE}
    borderStyle={'solid'}
    borderColor={colors.RED}
    borderSize={'1px'}
    title={props.title}
    width={'140px'}
    height={'35px'}
  >
    {props.children}
  </ButtonBase>
);



export {
  ButtonGroup,
  SaveButton,
  CancelButton,
  DeleteButton,
  EmplaceButton,
  ResetButton,
  ZoomInButton,
  ZoomOutButton,
  NextButton,
  PrevButton,
  GreyButton,
  SuccessButton,
  CancelationButton,
  RemoveButton,
};
