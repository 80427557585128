// devices.redux.js

// modules
// import _ from 'lodash';

const actionTypes = {
  DB_SESSION_SET_SESSION: 'DB_SESSION_SET_SESSION',
};

const actions = {
  setSession: session => ({
    type: actionTypes.DB_SESSION_SET_SESSION,
    payload: {session},
  }),
};

const defaultState = null;

const reduce = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.DB_SESSION_SET_SESSION: {
      const {session} = action.payload;
      if (!session) {
        return defaultState;
      }
      return session;
    }
    default: {
      return state;
    }
  }
};

export {
  actions,
  reduce,
};
