// images.redux.js

import _ from 'lodash';
import {toast} from 'react-toastify';
import {locations as locationsApi} from '../api';
import {allowedImageNameSpaces} from '../constants';
import {assertIsValidFileName} from '../utils/validate-file';
import config from '../config';

const actionTypes = {
  DB_IMAGES_REPLACE_ALL: 'DB_IMAGES_REPLACE_ALL',
  DB_IMAGES_REPLACE_ONE: 'DB_IMAGES_REPLACE_ONE',
  DB_IMAGES_EDIT_ONE: 'DB_IMAGES_EDIT_ONE',
  DB_IMAGES_REMOVE_ONE: 'DB_IMAGES_REMOVE_ONE',
};

const actions = {
  fetchBlueprintImages: () => dispatch => (
    locationsApi.getBlueprintImages()
      .then(images => {
        dispatch({
          type: actionTypes.DB_IMAGES_REPLACE_ALL,
          payload: {images, nameSpace: allowedImageNameSpaces.BLUEPRINT},
        });
      })
  ),
  uploadBlueprintImage: imageFile => dispatch => (
    locationsApi.uploadBlueprintImage(imageFile)
      .then(image => {
        dispatch({
          type: actionTypes.DB_IMAGES_REPLACE_ONE,
          payload: {image, nameSpace: allowedImageNameSpaces.BLUEPRINT},
        });
        return image;
      })
  ),
  editBlueprintImage: (oldImageName, newImageName) => dispatch => (
    locationsApi.editBlueprintImage(oldImageName, newImageName)
      .then(() => {
        dispatch({
          type: actionTypes.DB_IMAGES_EDIT_ONE,
          payload: {oldImageName, newImageName, nameSpace: allowedImageNameSpaces.BLUEPRINT},
        });
      })
  ),
  deleteBlueprintImage: image => dispatch => (
    locationsApi.deleteBlueprintImage(image)
      .then(() => {
        dispatch({
          type: actionTypes.DB_IMAGES_REMOVE_ONE,
          payload: {image, nameSpace: allowedImageNameSpaces.BLUEPRINT},
        });
      })
  ),

  fetchImages: nameSpace => dispatch => (
    locationsApi.getImages(nameSpace)
      .then(images => {
        dispatch({
          type: actionTypes.DB_IMAGES_REPLACE_ALL,
          payload: {images, nameSpace},
        });
      })
  ),
  uploadImage: (imageFile, nameSpace) => dispatch => {
    const isValidName = assertIsValidFileName(imageFile.name);
    if (!isValidName) {
      const invalidNameErrorMessage = 'Image upload failed: invalid file name';
      toast.error(invalidNameErrorMessage, config.toaster);
      return Promise.reject(invalidNameErrorMessage);
    }

    return locationsApi.uploadImage(imageFile, nameSpace)
      .then(image => {
        dispatch({
          type: actionTypes.DB_IMAGES_REPLACE_ONE,
          payload: {image, nameSpace},
        });
        return image;
      })
  },
  editImage: (oldImageName, newImageName, nameSpace) => dispatch => (
    locationsApi.editImage(oldImageName, newImageName, nameSpace)
      .then(() => {
        dispatch({
          type: actionTypes.DB_IMAGES_EDIT_ONE,
          payload: {oldImageName, newImageName, nameSpace},
        });
      })
  ),
  deleteImage: (image, nameSpace) => dispatch => (
    locationsApi.deleteImage(image, nameSpace)
      .then(() => {
        dispatch({
          type: actionTypes.DB_IMAGES_REMOVE_ONE,
          payload: {image, nameSpace},
        });
      })
  ),
};

const defaultState = {
  [allowedImageNameSpaces.BLUEPRINT]: [],
  [allowedImageNameSpaces.LOCATION_ICON]: [],
  [allowedImageNameSpaces.DEVICE_ICON]: [],
};

const reduce = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.DB_IMAGES_REPLACE_ALL: {
      const {images, nameSpace} = action.payload;
      return {...state, [nameSpace]: images};
    }
    case actionTypes.DB_IMAGES_REPLACE_ONE: {
      const {image, nameSpace} = action.payload;
      const images = _.uniq(_.concat(state[nameSpace], image));
      return {...state, [nameSpace]: images};
    }
    case actionTypes.DB_IMAGES_EDIT_ONE: {
      const {oldImageName, newImageName, nameSpace} = action.payload;
      const oldImageUrl = _.find(state[nameSpace], image => _.includes(image, oldImageName));
      const images = _.map(state[nameSpace], image => (
        image === oldImageUrl
          ? _.replace(image, oldImageName, newImageName)
          : image
      ));
      return {
        ...state,
        [nameSpace]: images,
      };
    }
    case actionTypes.DB_IMAGES_REMOVE_ONE: {
      const {image, nameSpace} = action.payload;
      const images = _.without(state[nameSpace], image);
      return {...state, [nameSpace]: images};
    }
    default:
      return state;
  }
};

export {
  actionTypes,
  actions,
  reduce,
};
