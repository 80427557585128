// locations.js

import request from './request';
import {allowedImageNameSpaces} from '../constants';

const LOCATIONS = '/locations';

const fetch = () => request({
  method: 'get',
  url: LOCATIONS
});

const get = locationId => request({
  method: 'get',
  url: `${LOCATIONS}/${locationId}`,
});

const update = updates => request({
  method: 'put',
  url: `${LOCATIONS}/${updates._id}`,
  data: updates
});

const create = location => request({
  method: 'post',
  url: `${LOCATIONS}`,
  data: location,
});

const remove = locationId => request({
  method: 'delete',
  url: `${LOCATIONS}/${locationId}`,
});

const uploadImage = (file, nameSpace) => {
  const files = new FormData();
  files.append('image', file, file.name);
  return request({
    method: 'post',
    url: `${LOCATIONS}/images/${nameSpace}`,
    data: files,
  });
};

const editImage = (oldImageName, newImageName, namespace) => (
  request({
    method: 'put',
    url: `${LOCATIONS}/images/${namespace}`,
    data: {oldImageName, newImageName},
  })
);

const uploadBlueprintImage = file => uploadImage(file, allowedImageNameSpaces.BLUEPRINT);

const editBlueprintImage = (oldImageName, newImageName) => (
  editImage(oldImageName, newImageName, allowedImageNameSpaces.BLUEPRINT)
);

const deleteImage = (image, nameSpace) => request({
  method: 'delete',
  url: `${LOCATIONS}/images/${nameSpace}`,
  data: {image},
});

const deleteBlueprintImage = fileName => deleteImage(fileName, allowedImageNameSpaces.BLUEPRINT);

const getImages = nameSpace => request({
  method: 'get',
  url: `${LOCATIONS}/images/${nameSpace}`,
});

const getBlueprintImages = () => getImages(allowedImageNameSpaces.BLUEPRINT);

export {
  fetch,
  get,
  update,
  create,
  remove,
  getImages,
  deleteImage,
  uploadImage,
  editImage,
  uploadBlueprintImage,
  editBlueprintImage,
  deleteBlueprintImage,
  getBlueprintImages,
};
