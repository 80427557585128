// navbar.selectors.js

import {createSelector} from 'reselect';
import _ from 'lodash';

// Actions
import {selectAllCurrentLocationBlueprints} from '../location.view/location.selectors';

const locationIdSelector = state => state.views.common.currentLocationId;
const locationsSelector = state => state.locations;

const blueprintIdSelector = state => state.views.common.currentBlueprintId;

// derive the current location specified by currentLocationId
const locationNameSelector = createSelector(
  locationIdSelector,
  locationsSelector,
  (locationId, locations) => _.get(locations, [locationId, 'name']),
);

const blueprintNameSelector = createSelector(
  blueprintIdSelector,
  selectAllCurrentLocationBlueprints,
  (blueprintId, blueprints) => {
    const blueprint = _.find(blueprints, bp => bp._id === blueprintId);
    return blueprint ? blueprint.name : null;
  },
);

export {
  locationNameSelector,
  blueprintNameSelector,
};
