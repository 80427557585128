// location-blueprint.view/blueprint.styled.js

import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import ResizeObserver from 'react-resize-observer';

// local stuff
import {InteractiveLayer} from '../../../components';
import {colors} from '../../views.constants';
import {LocationContainerLayer} from '../location.styled';
import {
  getScreenCoordinate,
} from './location-blueprint.utility';
import {getDeviceIconUrl} from '../../views.utils';
import {FlexWrapper} from '../../views.styled';

// BlueprintBackground has children with absolute positions
// This is the component that provides the coordinate system
// by which blueprint & devices are rendered
const BlueprintBackground = props => (
  <LocationContainerLayer>
    <ResizeObserver
      onResize={props.onResize}
      onPosition={props.onResize}
    />
    <InteractiveLayer
      boundingRect={props.boundingRect}
      onPan={props.onPan}
      onZoom={props.onZoom}
      onMouseUp={props.onMouseUp}
      style={{
        backgroundColor: colors.WHITE,
      }}
    >
      {props.children}
    </InteractiveLayer>
  </LocationContainerLayer>
);

const BlueprintSpriteStyled = styled.img.attrs(props => {
  // use attrs for better efficiency w/ many changes
  // warnings were going off about changing styled
  // properties too often, and suggested this as a solution
  const coordinate = {
    x: -props.width / 2,
    y: -props.height / 2,
  };
  const screenCoordinate = getScreenCoordinate(
    coordinate,
    props.geometry,
    props.parentDimensions,
  );
  const width = `${(props.width * props.geometry.scale)}px`;
  const height = `${(props.height * props.geometry.scale)}px`;
  const style = {
    left: `${screenCoordinate.x}px`,
    top: `${screenCoordinate.y}px`,
    width,
    height,
  };
  return {style};
})`
  position: absolute;
  pointer-events: none;
  user-select: none;
`;

// Export: BlueprintSprite
// A functional wrapper for BlueprintSpriteStyled and supplies
// a callback reporting image size
const BlueprintSprite = props => (
  <BlueprintSpriteStyled
    src={props.src}
    alt={props.alt}
    parentDimensions={props.parentDimensions}
    geometry={props.geometry}
    width={props.width || _.get(props.imageSize, 'width', 400)}
    height={props.height || _.get(props.imageSize, 'height', 300)}
    onLoad={evt => {
      const imageSize = {
        width: evt.target.naturalWidth,
        height: evt.target.naturalHeight,
      };
      props.onLoad(props.src, imageSize);
    }}
  />
);

// this guy is just a dumb wrapper... gotta go?
const DeviceItemSprite = props => {
  const width = 34;
  const height = 34;
  return (
    <DeviceItemWrapperStyled
      width={width}
      height={height}
      parentDimensions={props.parentDimensions}
      geometry={props.geometry}
      deviceItem={props.deviceItem}
    >
      {props.valueDisplayText && <ValueDisplay>{props.valueDisplayText}</ValueDisplay>}
      <DeviceItemSpriteStyled
        isMovable={props.isMovable}
        isGrabbed={props.isGrabbed}
        deviceItem={props.deviceItem}
        parentDimensions={props.parentDimensions}
        geometry={props.geometry}
        width={width}
        height={height}
        onClick={props.onClick}
        onMouseDown={props.onMouseDown}
        onTouchStart={props.onTouchStart}
        onMouseUp={props.onMouseUp}
        onMouseLeave={props.onMouseUp}
      >
        {props.children}
      </DeviceItemSpriteStyled>
    </DeviceItemWrapperStyled>
  )
};

const DeviceItemWrapperStyled = styled.div.attrs(props => {
  const coordinate = {
    x: props.deviceItem.left,
    y: props.deviceItem.top,
  };
  const screenCoordinate = getScreenCoordinate(
    coordinate,
    props.geometry,
    props.parentDimensions,
  );
  const style = {
    left: `${(screenCoordinate.x - 0.5 * props.width)}px`,
    top: `${(screenCoordinate.y - 0.5 * props.height)}px`,
  };
  return {style};
})`
  position: absolute;
  z-index: 1;
`;

const StyledDevicePopup = styled.div.attrs(props => {
  // use attrs for better efficiency w/ many changes
  // warnings were going off about changing styled
  // properties too often, and suggested this as a solution
  const width = 300;
  const coordinate = {
    x: props.deviceItem.left,
    y: props.deviceItem.top,
  };
  const screenCoordinate = getScreenCoordinate(
    coordinate,
    props.geometry,
    props.parentDimensions,
  );
  const style = {
    left: `${screenCoordinate.x + 7}px`,
    top: `${screenCoordinate.y + 15}px`,
    width: `${width}px`,
  };
  return {style};
})`
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: absolute;
  user-select: none;
  border: 1px solid lightgray;
  border-radius: 0 8px 8px 8px;
  background-color: ${colors.WHITE};
  padding: 20px;
  word-wrap: break-word;
  max-height: 400px;
  .checked_eye {
    color: ${colors.DARK_GREEN};
    cursor: pointer;
    padding-bottom: 3px;
  }
  .unchecked_eye {
    color: #999;
    cursor: pointer;
    padding-bottom: 3px;
  }
  .chartIcon {
    margin-right: 7px;
  }
  .notTranslated {
    color: #999;
  }
`;

const StyledDevicePopupDeviceList = styled.div`
  width: 100%;
  height: 200px;
  margin: 20px 0 20px 0;
  white-space: nowrap;
  overflow-y: auto;
`;

const StyledDevicePopupDeviceListItem = styled.div`
  display: flex;
  height: 35px;
  line-height: 35px;
  &:nth-child(odd) {
    background: ${colors.GREY_LIGHT};
  }
`;

const StyledDevicePopupDeviceItemName = styled.div`
  flex: 1 0 100px;
  padding-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledDevicePopupDeviceItemValue = styled.div`
  flex: 1 0 30px;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledDevicePopupDeviceItemIcon = styled.div`
  flex: 0 0 30px;
  text-align: right;
  padding-right: 5px;
`;

const DeviceItemSpriteStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  user-select: none;
  width: ${props => {
    if (props.deviceItem.size === 'small') {
      return '50px';
    }

    if (props.deviceItem.size === 'medium') {
      return '100px';
    }

    if (props.deviceItem.size === 'large') {
      return '200px';
    }

    return props.width + 'px';
  }};
  height: ${props => {
    if (props.deviceItem.size === 'small') {
      return '100px';
    }

    if (props.deviceItem.size === 'medium') {
      return '200px';
    }

    if (props.deviceItem.size === 'large') {
      return '400px';
    }

    return props.height + 'px';
  }};
  border: 2px solid ${colors.DARK_GREY};
  border-radius: ${(props => {
  if (props.deviceItem.type === 'silo') {
      return '5px';
    }

    return '50%';
  })};
  background-image: url(${props => {
    if (props.deviceItem.type === 'silo') {
      return null;
    }

    if (props.deviceItem.locationItemControl) {
      return getDeviceIconUrl(props.deviceItem.locationItemControl.icon);
    }
    return null;
  }});
  z-index: 2;
  background-color: ${({deviceItem}) => {
    if (deviceItem.type === 'silo') {
      return colors.GREY_LIGHT;
    }
    return deviceItem.status ? deviceItem.status.color : colors.MARKER
  }};
  background-size: ${props => {
    if (props.deviceItem.size === 'medium' || props.deviceItem.size === 'large') {
      return '40px';
    }

    return '70%';
  }};
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.2s;
  cursor: ${props => {
    if (props.isGrabbed) return 'grabbing';
    if (props.isMovable) return 'grab';
    return 'pointer';
  }};

  &:hover {
    filter: brightness(80%);
    transition: all 0.2s;
  }
`;

const DeviceFiller = styled.div`
  display: ${({deviceItem}) => deviceItem.type === 'silo' ? 'flex' : 'none'};
  background-color: ${props => {
    const level = _.find(props.values, val => val.type === 'level');
    if (level?.value < 0.15) {
      return colors.RED;
    }

  if (level?.value < 0.3) {
    return colors.YELLOW;
  }

    return colors.GREEN;
  }};
  position: absolute;
  bottom: 0;
  z-index: 999;
  height: ${(props) => {
    const level = _.find(props.values, val => val.type === 'level');
    if (level) {
      return (level.value * 100) + '%';
    }

    return '100%';
  }};
  width: 100%;
`;

const ValueDisplay = styled.div`
  position: absolute;
  top: 3px;
  left: 12px;
  height: 28px;
  line-height: 28px;
  padding: 0 10px 0 24px;
  z-index: 0;
  border-radius: 14px;
  background: #333;
  color: white;
  white-space: nowrap;
  pointer-events: none;
  font-size: 14px;
`;

const StyledCloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 2px;
  font-size: 1.1em;
  color: ${colors.DARK_GREY};

  &:hover {
    color: ${colors.DARK_GREEN};
  }
`;

const StyledDeviceValues = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledDeviceRemovalDropZone = styled.div`
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: 600;
  position: absolute;
  width: 100%;
  height: 25%;
  background: rgba(50, 50, 50, 0.5);
  bottom: 0;
  transition: background 0.2s;

  &:hover {
    transition: background 0.2s;
    background: ${({deviceItemGrabbed}) => (deviceItemGrabbed ? 'rgba(50, 50, 50, 0.8)' : 'rgba(50, 50, 50, 0.5)')};
  }
`;

const StyledSelectersWrapper = styled(FlexWrapper)`
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

export {
  BlueprintBackground,
  BlueprintSprite,
  DeviceItemSprite,
  StyledDevicePopup,
  StyledCloseButton,
  StyledDeviceValues,
  DeviceFiller,
  StyledDevicePopupDeviceList,
  StyledDevicePopupDeviceListItem,
  StyledDevicePopupDeviceItemName,
  StyledDevicePopupDeviceItemValue,
  StyledDevicePopupDeviceItemIcon,
  StyledDeviceRemovalDropZone,
  StyledSelectersWrapper,
};
