// views.selectors.js

import _ from 'lodash';
import {createSelector} from 'reselect';
import {allowedImageNameSpaces} from '../constants';

const selectAllBlueprintImages = state => state.images[allowedImageNameSpaces.BLUEPRINT];
const selectDialogState = state => state.views.common.dialogState;
const selectCurrentBlueprintId = state => state.views.common.currentBlueprintId;
const selectAllLocations = state => state.locations;

const selectLocationWithDialog = createSelector(
  selectAllLocations,
  selectDialogState,
  (locations, dialogLocationId) => locations[dialogLocationId] || null,
);

const selectBlueprintWithDialog = createSelector(
  selectCurrentBlueprintId,
  selectDialogState,
  (currentBlueprintId, dialogLocationId) => {
    if(dialogLocationId && currentBlueprintId === dialogLocationId) {
      return dialogLocationId;
    }
    return null;
  }
);

const selectChoosableBlueprintImages = createSelector(
  selectAllBlueprintImages,
  images => _.map(images, image => {
    const parts = image.split('-');
    parts.pop();
    const label = parts.join('-').split('/').pop();
    return {
      value: image,
      label,
    };
  }),
);

export {
  selectLocationWithDialog,
  selectBlueprintWithDialog,
  selectChoosableBlueprintImages,
};
