// locations-dialog.view.js

import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import ConfirmationDialog from './confirmation-dialog.view';
import {actions as viewActions} from './views.redux';
import {actions as locationsActions} from './locations.view/locations-menu.view/locations-menu.redux';
import {routes} from './views.routes';
import {selectLocationWithDialog} from './views.selectors';

const LocationsDialog = props => (
  <ConfirmationDialog
    isOpen={props.isOpen}
    onCancel={props.closeDialog}
    onConfirm={() => {
      props.deleteLocation(props.location._id);
      props.history.push(routes.LOCATIONS);
      props.closeDialog();
    }}
    header={props.location.name}
    message={'Are you sure you want to delete this location?'}
  />
);

const mapStateToProps = state => ({
  isOpen: !!selectLocationWithDialog(state),
  location: selectLocationWithDialog(state) || {},
});

const mapDispatchToProps = dispatch => ({
  closeDialog: () => dispatch(viewActions.setDialogState(null)),
  deleteLocation: locationId => dispatch(locationsActions.deleteLocation(locationId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationsDialog));
