// navbar.view.js

// modules
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// local components
import {
  Bar,
  Menu,
  NavTitle,
  // nav links
  LocationsLink,
  ImageManagerLink,
  ToggleLink,
  LogoutLink,
} from './navbar.styled';

// actions & selectors
import {blueprintNameSelector, locationNameSelector} from './navbar.selectors';
import {actions as viewActions} from '../views.redux';

const NavBar = (
  {
    toggleMenu,
  }
) => (
  <Bar>
    <NavTitle />
    <Menu>
      <LocationsLink />
      <ImageManagerLink />
      <ToggleLink toggleMenu={toggleMenu} />
    </Menu>
    <LogoutLink />
  </Bar>
);

const mapStateToProps = state => ({
  locationName: locationNameSelector(state),
  blueprintName: blueprintNameSelector(state),
});

const mapDispatchToProps = dispatch => ({
  toggleMenu: () => dispatch(viewActions.toggleMenu()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));
