// location-menu.view/index.js

// Modules
import _ from 'lodash';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Pagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/en_US';

// Local components
import LocationMenuHeader from './location-menu-header.view';
import LocationMenuBlueprintSection from './blueprint-section.view';
import {Menu, MenuSection} from '../../views-menu.styled';
import {GreyButton} from '../../views-buttons';
import {LOCATION_VIEWS} from '../../views.constants';

// actions & selectors
import {actions as menuViewActions} from './location-menu.redux';
import {actions as viewActions} from '../location.redux';
import {actions as viewsActions} from '../../views.redux';
import {actions as deviceActions} from '../../../state/devices.redux';
import {
  selectCurrentLocationId,
  selectAllCurrentLocationBlueprintIds,
} from '../location.selectors';
import {actions as blueprintCreatorActions} from '../blueprint-create.view/blueprint-create.redux';
import {selectTotalNumBlueprints} from './location-menu.selectors';

const LocationMenuWrapper = props => {
  // Fetch devices
  useEffect(() => {
    props.fetchDevices();
  }, []);

  return <LocationMenuView {...props} />;
};

const LocationMenuView = props => (
  <Menu isOpen={props.showMenu} >
    <LocationMenuHeader />
    <GreyButton
      onClick={() => {
        props.openBlueprintCreator(props.currentLocationId);
        props.setView(LOCATION_VIEWS.blueprintCreator);
        props.toggleMenu();
      }}
      margin={'20px 0 0 0'}
      data-cy="new-blueprint-btn"
    >
      + New blueprint
    </GreyButton>
    {_.map(props.blueprintIds, blueprintId => (
      <LocationMenuBlueprintSection
        key={blueprintId}
        history={props.history}
        blueprintId={blueprintId}
      />
    ))}
    <Pagination
      onChange={blueprintPage => props.setBlueprintPage(blueprintPage - 1)}
      current={props.blueprintPage + 1}
      pageSize={props.blueprintPageSize}
      hideOnSinglePage={false}
      total={props.totalNumBlueprints}
      style={{
        marginTop: 8,
        marginBottom: 4,
      }}
      locale={localeInfo}
    />
  </Menu>
);

// history gets passed in from above
LocationMenuWrapper.propTypes = {
  history: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  showMenu: state.views.common.showMenu,
  currentLocationId: selectCurrentLocationId(state),
  blueprintIds: selectAllCurrentLocationBlueprintIds(state),
  blueprintPage: state.views.locationMenu.blueprintPage,
  blueprintPageSize: state.views.locationMenu.blueprintPageSize,
  totalNumBlueprints: selectTotalNumBlueprints(state),
});

const mapDispatchToProps = dispatch => ({
  setBlueprintPage: page => dispatch(menuViewActions.setPage(page)),
  toggleLocationMenu: () => dispatch(viewActions.toggleLocationMenu()),
  fetchDevices: () => dispatch(deviceActions.fetchDevices()),
  openBlueprintCreator: locationId => {
    dispatch(blueprintCreatorActions.openBlueprintCreator(locationId));
  },
  toggleMenu: () => dispatch(viewsActions.toggleMenu()),
  setView: view => dispatch(viewActions.setView(view))
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationMenuWrapper);
