// location-menu.styled.js

import styled from 'styled-components';

// External components
import {Icon} from 'react-icons-kit';

// local stuff
import {colors} from '../../views.constants';
import {Button, FlexWrapper} from '../../views.styled';

const BlueprintContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BlueprintHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  h2 {
    margin: 0 15px 0 0;
  }
`;

const CreateBlueprintButton = styled(Button)`
  background: lightgreen;
  color: ${colors.GREY_DARK}
  margin: 5px;
  min-width: 100px;
  width: 97%;
  height: 50px;
  transition: background 0.2s;

  &:hover {
    background: #00d600;
    transition: background 0.2s;
  }
`;

const StyledIcon = styled(Icon)`
  margin: 8px;
`;

const StyledDevice = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  color: ${colors.WHITE};
`;

const LocationMenuFlexWrapper = styled(FlexWrapper)`
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
`;

const ColFlexWrapper = styled(FlexWrapper)`
  flex-direction: column;
  width: 100%;
`;

const DeviceSettingsContainer = styled(FlexWrapper)`
  flex-direction: column;
  height: 100px;
  width: 100%;
`;

export {
  BlueprintContainer,
  BlueprintHeader,
  CreateBlueprintButton,
  StyledIcon,
  StyledDevice,
  LocationMenuFlexWrapper,
  ColFlexWrapper,
  DeviceSettingsContainer,
};
