// image-uploader-section.view.js

// Modules
import React from 'react';
import {connect} from 'react-redux';
import Dropzone from 'react-dropzone';
import {toast} from 'react-toastify';
import {Icon} from 'react-icons-kit';
import {cloudUpload} from 'react-icons-kit/fa/cloudUpload';

// local
import {
  StyledCancelButton,
  StyledDropzone,
  StyledImageInfo,
  StyledImageUploader,
  StyledInput,
  StyledSelectedImage,
  StyledSubmitButton,
  StyledUploaderButtonsWrapper,
  StyledUploaderHeader,
} from './image-manager.styled';
import {Loader} from '../views-menu-buttons.styled';
import {determineFileErrorMessage} from './image-manager.utils';
import {allowedImageTypes, colors, IMAGE_MAX_SIZE} from '../views.constants';
import config from '../../config';

// Actions
import {actions as viewActions} from './image-manager.redux';

const ImageUploaderSection = props => (
  <StyledImageUploader>
    <RenderUploaderHeader imageType={props.imageType} />
    {props.imageSelected.url
      ? <RenderUploaderSection {...props} />
      : <RenderUploaderDropzone{...props} />}
  </StyledImageUploader>
);

const RenderUploaderHeader = () => (
  <StyledUploaderHeader>
    <p>* File types supported: JPEG, PNG and GIF.</p>
    <p>* Max file size: 2MB.</p>
  </StyledUploaderHeader>
);

const RenderUploaderDropzone = props => (
  !props.imageSelected.url &&
  <Dropzone
    accept={allowedImageTypes}
    maxSize={IMAGE_MAX_SIZE}
    onDragEnter={() => props.setOnDragEnter()}
    onDragLeave={() => props.setOnDragLeave()}
    onDropAccepted={() => props.setOnDragLeave()}
    onDropRejected={file => {
      toast.error(`Error selecting file: ${determineFileErrorMessage(file[0])}`, config.toaster);
      props.setOnDragLeave();
    }}
    onDrop={acceptedFile => {
      const file = acceptedFile[0];
      if (file) {
        const url = URL.createObjectURL(file);
        props.selectImage(url, file);
      }
    }}>
    {({getRootProps, getInputProps}) => (
      <StyledDropzone
        background={props.dropZoneHover && colors.GREY}
        color={props.dropZoneHover ? colors.WHITE : colors.GREY}
        {...getRootProps()}>
        <input {...getInputProps()} />
        <Icon
          icon={cloudUpload}
          size={80}
        />
        <p>Drag & drop your file here, or click to select file.</p>
      </StyledDropzone>
    )}
  </Dropzone>
);

const RenderUploaderSection = props => (
  <React.Fragment>
    {props.isImageUploading
      ? <RenderUploaderSectionLoader />
      : <RenderUploaderSectionSelectedImage {...props} />
    }
  </React.Fragment>
);

const RenderUploaderSectionLoader = () => (
  <React.Fragment>
    <b>UPLOADING</b>
    <Loader />
  </React.Fragment>
);

const RenderUploaderSectionSelectedImage = props => (
  <React.Fragment>
    <StyledSelectedImage
      src={props.imageSelected.url}
      alt={props.imageSelected.image.name}
      title={props.imageSelected.image.name}
    />
    <StyledImageInfo>
      <p>
        <b>
          Name:
        </b>
        &nbsp;{props.imageSelected.image.name}
      </p>
      <p>
        <b>
          Size:
        </b>
        &nbsp;{(props.imageSelected.image.size / 1024 / 1024).toFixed(2)}MB
      </p>
    </StyledImageInfo>
    <StyledInput
      type="text"
      placeholder={'Change name of image... (optional)'}
      onChange={evt => props.setImageName(evt.target.value)}
    />
    <RenderUploaderSectionButtons {...props} />
  </React.Fragment>
);

const RenderUploaderSectionButtons = props => (
  <StyledUploaderButtonsWrapper>
    <StyledSubmitButton
      onClick={() => props.addNewImage(
        props.imageSelected.image,
        props.newImageName,
        props.imageType
      )}
    >
      SUBMIT UPLOAD
    </StyledSubmitButton>
    <StyledCancelButton
      onClick={() => props.resetImageName()}
    >
      Cancel
    </StyledCancelButton>
  </StyledUploaderButtonsWrapper>
);

const mapStateToProps = state => ({
  dropZoneHover: state.views.imageManager.dropZoneHover,
  imageType: state.views.imageManager.imageType,
  newImageName: state.views.imageManager.newImageName,
  imageSelected: state.views.imageManager.imageSelected,
  isImageUploading: state.views.imageManager.isImageUploading,
});

const mapDispatchToProps = dispatch => ({
  setOnDragEnter: () => dispatch(viewActions.setOnDragEnter()),
  setOnDragLeave: () => dispatch(viewActions.setOnDragLeave()),
  resetImageName: () => dispatch(viewActions.resetImageName()),
  selectImage: (imageUrl, image) => dispatch(viewActions.selectImage(imageUrl, image)),
  setImageName: imageName => dispatch(viewActions.setImageName(imageName)),
  addNewImage: (imageFile, imageName, nameSpace) => (
    dispatch(viewActions.addNewImage(imageFile, imageName, nameSpace))
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploaderSection);
