// device-item-popup.view.js

// Modules
import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Icon} from 'react-icons-kit';
import {eye} from 'react-icons-kit/fa/eye'
import {barChart} from 'react-icons-kit/fa/barChart'
import {truncate, map, isEqual} from 'lodash';
import {Button} from 'reactstrap';

// Local
import {
  StyledCloseButton,
  StyledDevicePopup,
  StyledDevicePopupDeviceList,
  StyledDevicePopupDeviceListItem,
  StyledDevicePopupDeviceItemName,
  StyledDevicePopupDeviceItemValue,
  StyledDevicePopupDeviceItemIcon,
} from './location-blueprint.styled';

const getValueDisplayMeasurement = (measurement, valueDisplay) => (
  isEqual(measurement.type, valueDisplay) ? '' : measurement.type
);

const DevicePopup = props => (
  <StyledDevicePopup
    onWheel={evt => evt.stopPropagation()}
    {...props}
  >
    <h5 title={props.deviceItem.name}>{truncate(props.deviceItem.name, {length: 50})}</h5>
    {props.values ? (
      <div>
        <StyledDevicePopupDeviceList>
          {map(props.values, (measurement) => (
            <StyledDevicePopupDeviceListItem key={measurement.name}>
              <StyledDevicePopupDeviceItemName title={measurement.name}>
                {measurement.name}
              </StyledDevicePopupDeviceItemName>
              <StyledDevicePopupDeviceItemValue title={`${measurement.value} ${measurement.unit}`}>
                {`${measurement.value} ${measurement.unit}`}
              </StyledDevicePopupDeviceItemValue>
              <StyledDevicePopupDeviceItemIcon>
                <Icon
                  size={18}
                  icon={eye}
                  onClick={() => props.showValueDisplay(
                    props.currentLocation,
                    props.deviceItem._id,
                    getValueDisplayMeasurement(measurement, props.deviceItem.valueDisplay)
                  )}
                  {...(measurement.type === props.deviceItem.valueDisplay ? {className: 'checked_eye'} : {className: 'unchecked_eye'})}
                />
              </StyledDevicePopupDeviceItemIcon>
            </StyledDevicePopupDeviceListItem>
          ))}
        </StyledDevicePopupDeviceList>
        <Button color="primary" tag={Link} to={`/charts/${props.deviceItem.deviceId}`}>
          <Icon size={20} className="chartIcon" icon={barChart} />
          Chart
        </Button>{' '}
      </div>
    ) : (
        <p className="notTranslated">Not a translated iotnode - displaying current values and charts are not available</p>
      )}
    <StyledCloseButton onClick={() => props.closeDevicePopup()}>✕</StyledCloseButton>
  </StyledDevicePopup>
);

export default withRouter(DevicePopup);
