// blueprint-edit/index.js

// Modules
import React from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {get} from 'lodash';

// Local components
import {FlexWrapper, HorizontalLine} from '../../views.styled';
import {SelectInput, TextInput} from '../../../components/inputs';
import {UploadFileButton} from '../../views-menu-buttons.styled';
import {CancelationButton, RemoveButton, SuccessButton} from '../../views-buttons';
import {
  StyledBlueprintImg,
  StyledIconContainer,
  StyledImgContainer
} from '../blueprint-create.view/blueprint-create.styled';
import {StyledLocationView, StyledButtonsContainer} from '../location.styled';

// Utils
import {allowedImageNameSpaces} from '../../../constants';
import {getBlueprintImageUrl} from '../../views.utils';
import config from '../../../config';

// State
import {createBlueprintImagesSelector} from '../blueprint-create.view/blueprint-create.selectors';
import {
  selectCurrentLocation,
  selectCurrentLocationId,
  selectCurrentLocationBlueprintEditor,
} from '../location.selectors';
import {actions as viewsActions} from '../../views.redux';
import {actions as viewActions} from './blueprint-edit.redux';
import {actions as locationActions} from '../location.redux';
import {actions as imageActions} from '../../../state/images.redux';

const BlueprintEditor = (
  {
    currentLocation,
    saveBlueprintEditor,
    updateBlueprintEditor,
    setView,
    cancelBlueprintEditor,
    fetchImages,
    uploadImage,
    images,
    currentLocationId,
    currentBlueprintId,
    blueprintEditor,
    setDialogState,
  }
) => (
  <StyledLocationView>
    <h4>Edit blueprint</h4>
    <HorizontalLine />
    <FlexWrapper>
      <div>
        <TextInput
          label={'Name'}
          value={get(blueprintEditor, 'name', '')}
          onChange={({target: {value}}) => {
            const updates = {...blueprintEditor, name: value};
            updateBlueprintEditor(currentBlueprintId, updates);
          }}
          maxLength={20}
        />
        <StyledIconContainer>
          <SelectInput
            label={'Blueprint'}
            value={get(blueprintEditor, 'image', '')}
            options={images}
            onFocus={() => fetchImages(allowedImageNameSpaces.BLUEPRINT)}
            onChange={({value}) => {
              const updates = {...blueprintEditor, image: value};
              updateBlueprintEditor(currentBlueprintId, updates);
            }}
          />
          <UploadFileButton
            isUploading={false}
            onChange={file => uploadImage(file, allowedImageNameSpaces.BLUEPRINT)}
          />
        </StyledIconContainer>
      </div>
      <StyledImgContainer>
        <StyledBlueprintImg src={getBlueprintImageUrl(get(blueprintEditor, 'image'))} />
      </StyledImgContainer>
    </FlexWrapper>
    <HorizontalLine />
    <StyledButtonsContainer>
      <div style={{display: 'flex'}}>
        <SuccessButton
          onClick={() => {
            toast.success(`Blueprint edited: ${blueprintEditor.name}`, config.toaster);
            saveBlueprintEditor(currentLocation, currentBlueprintId, blueprintEditor);
            setView(null);
          }}
        >
          Save changes
        </SuccessButton>
        <CancelationButton
          onClick={() => {
            cancelBlueprintEditor(currentLocationId);
            setView(null);
          }}
        >
          Cancel
        </CancelationButton>
      </div>
      <RemoveButton onClick={() => {
        setDialogState(currentBlueprintId);
      }}>
          Delete blueprint
      </RemoveButton>
    </StyledButtonsContainer>
  </StyledLocationView>
);

const selectBlueprintEdits = selectCurrentLocationBlueprintEditor();

const mapStateToProps = state => ({
  images: createBlueprintImagesSelector(state),
  currentLocationId: selectCurrentLocationId(state),
  currentLocation: selectCurrentLocation(state),
  blueprintEditor: selectBlueprintEdits(state),
  currentBlueprintId: state.views.common.currentBlueprintId,
});

const mapDispatchToProps = dispatch => ({
  setDialogState: locationId => dispatch(viewsActions.setDialogState(locationId)),
  cancelBlueprintEditor: blueprintId => {
    dispatch(viewActions.cancelBlueprintEdits(blueprintId));
  },
  updateBlueprintEditor: (blueprintId, updates) => {
    dispatch(viewActions.updateBlueprintEdits(blueprintId, updates));
  },
  saveBlueprintEditor: (location, blueprintId, updates) => {
    dispatch(viewActions.saveBlueprintEdits(location, blueprintId, updates));
  },
  fetchImages: imageType => dispatch(imageActions.fetchImages(imageType)),
  uploadImage: image => dispatch(imageActions.uploadImage(image, allowedImageNameSpaces.BLUEPRINT)),
  setView: view => dispatch(locationActions.setView(view))
});

export default connect(mapStateToProps, mapDispatchToProps)(BlueprintEditor);
