// views.utility.js

import config from '../config';

import {
  DEFAULT_LOCATION_ICON,
  DEFAULT_BLUEPRINT_IMAGE,
  DEFAULT_DEVICE_ICON,
} from './views.constants';

// right now we're just keeping the images straight in the backend
const rootUrl = config.yggio.url;

const getFullImageUrl = imageUrl => (
  !imageUrl ? null : `${rootUrl}/${imageUrl}`
);

const getLocationIconUrl = iconUrl => (
  getFullImageUrl(iconUrl) || DEFAULT_LOCATION_ICON
);

const getBlueprintImageUrl = imageUrl => (
  getFullImageUrl(imageUrl) || DEFAULT_BLUEPRINT_IMAGE
);

const getDeviceIconUrl = iconUrl => (
  getFullImageUrl(iconUrl) || DEFAULT_DEVICE_ICON
);

export {
  getFullImageUrl,
  getLocationIconUrl,
  getBlueprintImageUrl,
  getDeviceIconUrl,
};
