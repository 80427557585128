import {split, size, eq} from 'lodash';
const regex = /^[-_A-Za-z0-9]+$/i;

const assertIsValidFileName = name => {
  const nameSplit = split(name, '.');
  // Dont support dots in the name, only one dot for the extension
  const amountOfExtensionsAllowed = eq(size(nameSplit), 2);
  const [nameWithoutExtension] = nameSplit;
  const nameAllowed = nameWithoutExtension.match(regex);

  return nameAllowed && amountOfExtensionsAllowed;
};

export {
  assertIsValidFileName,
}
