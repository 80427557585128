// location-title-section.view.js

// Modules
import {truncate, get} from 'lodash';
import React from 'react';
import {connect} from 'react-redux';

import {MenuHeader} from '../../views-menu.styled';
import {LOCATION_VIEWS} from '../../views.constants';
import {GreyButton} from '../../views-buttons';
import {LocationMenuFlexWrapper} from './location-menu.styled';

// actions and selectors
import {selectCurrentLocation} from '../location.selectors';
import {actions as viewsActions} from '../../views.redux';
import {actions as locationActions} from '../location.redux';

const LocationTitleSection = (
  {
    name,
    setView,
    toggleMenu,
  }
) => {
  const locationName = name ? name : '**unnamed**';
  return (
    <MenuHeader>
      <LocationMenuFlexWrapper>
        <label title={locationName.length > 20 ? name : null}>
          {truncate(locationName, {length: 20})}
        </label>
        <GreyButton onClick={() => {
          setView(LOCATION_VIEWS.locationEditor);
          toggleMenu();
        }}>
          Edit location
      </GreyButton>
      </LocationMenuFlexWrapper>
    </MenuHeader>
  )
};
const mapStateToProps = state => ({
  name: get(selectCurrentLocation(state), 'name'),
});

const mapDispatchToProps = dispatch => ({
  toggleMenu: () => dispatch(viewsActions.toggleMenu()),
  setView: view => dispatch(locationActions.setView(view))
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationTitleSection);
