// loction-blueprint-buttons.view.js

// Modules
import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// local components
import {BLUEPRINT_BUTTON_SCALE_FACTOR} from '../../views.constants';
import {
  BlueprintButtonsLayer,
} from './location-buttons-layer.styled';
import {
  ButtonGroup,
  EmplaceButton,
  ResetButton,
  ZoomInButton,
  ZoomOutButton,
  NextButton,
  PrevButton,
} from '../../views-buttons';

// selectors & actions
import {actions as menuActions} from '../location-menu.view/location-menu.redux';
import {actions as viewActions} from '../location.redux';
import {actions as blueprintActions} from '../location-blueprint.view/location-blueprint.redux';
import {selectCurrentBlueprintGeometry} from '../location-blueprint.view/location-blueprint.selectors';
import {
  selectCurrentLocation,
  selectNextIndex,
  selectPrevIndex,
  createSelectorBlueprintNavLink,
} from '../location.selectors';

const LocationBlueprintButtons = props => (
  <BlueprintButtonsLayer>
    <ButtonGroup>
      <EmplaceButton
        onClick={() => props.setDevicePlacementMode(!props.isInDevicePlacementMode)}
      />
      {!props.isInDevicePlacementMode && (
        <ResetButton
          onClick={() => {
            props.resetBlueprintGeometry(props.blueprintId);
          }}
        />
      )}
      {!props.isInDevicePlacementMode && (
        <ZoomInButton
          onClick={() => {
            const geometry = props.blueprintGeometry;
            const scale = geometry.scale * (2 ** BLUEPRINT_BUTTON_SCALE_FACTOR);
            props.setBlueprintGeometry(props.blueprintId, {...geometry, scale});
          }}
        />
      )}
      {!props.isInDevicePlacementMode && (
        <ZoomOutButton
          onClick={() => {
            const geometry = props.blueprintGeometry;
            const scale = geometry.scale * (2 ** -BLUEPRINT_BUTTON_SCALE_FACTOR);
            props.setBlueprintGeometry(props.blueprintId, {...geometry, scale});
          }}
        />
      )}
      {!props.isInDevicePlacementMode && (props.nextIndex || props.prevIndex) && (
        <>
          <PrevButton
            onClick={() => {
              if (props.prevIndex) {
                const newUrl = props.blueprintNavLink + (props.prevIndex.name || '');
                props.history.push(newUrl);
              }
            }}
            disabled={!props.prevIndex}
          />
          <NextButton
            onClick={() => {
              if (props.nextIndex) {
                const newUrl = props.blueprintNavLink + (props.nextIndex.name || '');
                props.history.push(newUrl);
              }
            }}
            disabled={!props.nextIndex}
          />
        </>
      )}
    </ButtonGroup>
  </BlueprintButtonsLayer>
);

const makeMapStateToProps = () => {
  const selectBlueprintNavLink = createSelectorBlueprintNavLink();
  return (state, props) => ({
    isInDevicePlacementMode: state.views.location.isInDevicePlacementMode,
    blueprintId: state.views.common.currentBlueprintId,
    blueprintGeometry: selectCurrentBlueprintGeometry(state),
    deviceItem: state.views.locationBlueprint.movingDeviceItem,
    currentLocation: selectCurrentLocation(state),
    nextIndex: selectNextIndex(state),
    prevIndex: selectPrevIndex(state),
    blueprintNavLink: selectBlueprintNavLink(state, props),
  })
};

const mapDispatchToProps = dispatch => ({
  toggleLocationMenu: () => dispatch(viewActions.toggleLocationMenu()),
  setDevicePlacementMode: isInDevicePlacementMode => {
    dispatch(viewActions.setDevicePlacementMode(isInDevicePlacementMode));
  },
  setBlueprintGeometry: (blueprintId, geometry) => {
    dispatch(blueprintActions.setBlueprintGeometry(blueprintId, geometry));
  },
  resetBlueprintGeometry: blueprintId => {
    dispatch(blueprintActions.resetBlueprintGeometry(blueprintId));
  },
  deleteBlueprintDeviceItem: (location, deviceItemId) => {
    dispatch(menuActions.deleteBlueprintDeviceItem(location, deviceItemId));
  },
});

export default withRouter(connect(makeMapStateToProps, mapDispatchToProps)(LocationBlueprintButtons));
