// image-manager-menu.view

// Modules
import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {withRouter} from 'react-router-dom';

// Local components
import {
  StyledImageMainMenuSection,
  StyledImageMenuContainer,
  StyledImageSubMenuSection,
  StyledMainMenuButton,
  StyledMenuButton,
} from './image-manager.styled';
import {allowedImageNameSpaces} from '../../constants';
import {createReadableLabel} from './image-manager.utils';
import {colors} from '../views.constants';
import {routes} from '../views.routes';

// Actions
import {actions as viewActions} from './image-manager.redux';
import {createSelectorUrlPath} from './image-manager.selectors';

const ImageManagerMenu = props => (
  <React.Fragment>
    <StyledImageMenuContainer>
      <RenderMainMenu {...props} />
      <RenderSubMenu
        imageType={props.imageType}
        selectImageType={props.selectImageType} />
    </StyledImageMenuContainer>
  </React.Fragment>
);

const RenderMainMenu = props => (
  <StyledImageMainMenuSection>
    <StyledMainMenuButton
      background={_.isEqual(props.urlPath, 'upload') ? colors.LIGHT_GREEN : colors.GREY_LIGHT}
      color={_.isEqual(props.urlPath, 'upload') ? colors.GREY_LIGHT : colors.GREY_DARK}
      to={routes.IMAGE_MANAGER_UPLOAD}>
      {'UPLOAD IMAGES'}
    </StyledMainMenuButton>
    <StyledMainMenuButton
      background={_.isEqual(props.urlPath, 'edit') ? colors.LIGHT_GREEN : colors.GREY_LIGHT}
      color={_.isEqual(props.urlPath, 'edit') ? colors.GREY_LIGHT : colors.GREY_DARK}
      to={routes.IMAGE_MANAGER_EDIT}>
      {'MANAGE IMAGES'}
    </StyledMainMenuButton>
  </StyledImageMainMenuSection>
);

const RenderSubMenu = ({selectImageType, imageType}) => (
  <StyledImageSubMenuSection>
    <div>
      {_.map(allowedImageNameSpaces, nameSpace => (
        <StyledMenuButton
          color={nameSpace === imageType ? colors.WHITE : colors.GREY_DARK}
          background={nameSpace === imageType ? colors.LIGHT_GREEN : colors.GREY_LIGHT}
          key={nameSpace}
          onClick={() => selectImageType(nameSpace)}>
          {createReadableLabel(nameSpace)}
        </StyledMenuButton>
      ))}
    </div>
  </StyledImageSubMenuSection>
);

const mapStateToProps = (state, props) => ({
  imageType: state.views.imageManager.imageType,
  urlPath: createSelectorUrlPath(state, props),
});

const mapDispatchToProps = dispatch => ({
  selectImageType: imageType => dispatch(viewActions.selectImageType(imageType)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ImageManagerMenu));
